import { Box, Button, Divider, Flex, Icon, useToast, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCaravanLine, RiUserAddLine } from 'react-icons/ri'
import { toastError } from '../../../../config/error/toastError'
import { useVehicleCategoriesCombox } from '../../../../hooks/useVehicleCategoriesCombox'
import { apiServer } from '../../../../services/api'
import { useCreateAttachmentToTrailer } from '../../../../services/endpoints/trailers/trailer-attachments/createTrailerAttachment'
import { useUpdateAttachmentToTrailer } from '../../../../services/endpoints/trailers/trailer-attachments/updateTrailerAttachment'
import { useAttachTrailer } from '../../../../services/endpoints/vehicles/attachTrailerToVehicle'
import { useDetachTrailer } from '../../../../services/endpoints/vehicles/detachTrailerFromVehicle'
import { VehicleResponseFormData } from '../../../../services/endpoints/vehicles/getVehicle'
import { ResponseApiType, ResponseOcrCrlv } from '../../../../services/types'
import { TrailerType } from '../../../../services/types/TrailerType'
import { capitalizeWord } from '../../../../services/utils/capitalizeWord'
import { alterColorsGenderDatamex } from '../../../../services/utils/vehicleColorsDatamex'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { setIsNewImage } from '../../../../store/slices/ocrCnhSlice'
import { setCrlvTrailerImg } from '../../../../store/slices/ocrCrlvSlice'
import {
  TrailerForm,
  TrailerFormValues,
  trailerInitialValues,
  TrailerSchema,
} from '../../../freights/pendencies-v2/forms/trailer'
import UploadCRLVModal from '../../../freights/pendencies-v2/modals/UploadCRLV.modal'
import { createCityInitialValue } from '../../../freights/pendencies-v2/utils'

type FormVechileProviderProps = {
  vehicle?: VehicleResponseFormData
  invalidateCache?: () => void
  initialValues?: TrailerType | undefined
  onForward?: () => void
  onBack?: () => void
}

const FormTrailerProvider = ({
  initialValues,
  onForward,
  vehicle,
  onBack,
  invalidateCache,
}: FormVechileProviderProps): JSX.Element => {
  useVehicleCategoriesCombox()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { crlvTrailerFile } = useAppSelector(state => state.ocrCrlvSlice)
  const [trailer, setTrailer] = React.useState<TrailerType>()

  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const { mutateAsync: createAttachmentTrailer } = useCreateAttachmentToTrailer({
    onSuccess: () => {
      showSuccessToast('Anexo do CRLV do reboque criado com sucesso!')
      dispatch(setIsNewImage(false))
    },
  })
  const { mutateAsync: updateAttachmentTrailer } = useUpdateAttachmentToTrailer({
    onSuccess: () => {
      showSuccessToast('Anexo do CRLV do reboque atualizado com sucesso!')
      dispatch(setIsNewImage(false))
    },
  })

  const methods = useForm<TrailerFormValues>({
    mode: 'onChange',
    defaultValues: trailerInitialValues,
    resolver: yupResolver(TrailerSchema),
  })

  const { reset } = methods

  const unlinkTrailerFromVehicleMutate = useDetachTrailer({
    onSuccess: () => {
      showSuccessToast('Trailer desvinculado!')
      invalidateCache?.()
      setTrailer(undefined)
      reset(trailerInitialValues)
    },
  })
  const linkTrailerToVehicleMutate = useAttachTrailer({
    onSuccess: () => {
      showSuccessToast('Trailer vinculado!')
      invalidateCache?.()
      setTrailer(undefined)
    },
  })

  const handleUnlinkTrailerFromVehicle = () => {
    if (trailer?.id && vehicle) {
      unlinkTrailerFromVehicleMutate.mutate({
        trailer_id: trailer.id,
        vehicle_id: vehicle.id,
      })
    }
  }

  const handleLinkTrailerToVehicle = async (trailer_id: string): Promise<void> => {
    if (!trailer_id || !vehicle?.id) return

    const [oldTrailer] = vehicle.trailers
    const oldTrailerId = oldTrailer?.id

    // Se o trailer atual já estiver vinculado, não faça nada
    if (oldTrailerId === trailer_id) return

    // Desvincula o trailer antigo se existir
    if (oldTrailerId) {
      await unlinkTrailerFromVehicleMutate.mutateAsync({
        vehicle_id: vehicle.id,
        trailer_id: oldTrailerId,
      })
    }

    // Vincula o novo trailer
    await linkTrailerToVehicleMutate.mutateAsync({
      vehicle_id: vehicle.id,
      trailer_id,
    })
  }

  const handleAttachmentUpload = async (trailer_id: string) => {
    if (crlvTrailerFile?.name) {
      const attachment = initialValues?.attachments?.find(item => item.type === 'crlv')
      if (attachment?.id) {
        await updateAttachmentTrailer({
          id: attachment.id,
          attachment_file: crlvTrailerFile,
          trailer_id,
        })
      } else {
        await createAttachmentTrailer({
          attachment_file: crlvTrailerFile,
          trailer_id,
          name: 'Crlv do veículo',
          type: 'crlv',
        })
      }
    }
  }

  const onSubmit: SubmitHandler<TrailerFormValues> = async data => {
    if (trailer?.id) {
      try {
        await apiServer.put<ResponseApiType<TrailerType>>(`/update-trailer/${trailer?.id}`, {
          ...data,
          brand: data.brand.toUpperCase(),
          chassi: data.chassi.toUpperCase(),
          license_plate: data.license_plate.toUpperCase(),
          antt: data.license_plate.toUpperCase(),
          city_id: data.city_id.value,
        })

        showSuccessToast('trailer atualizado com sucesso!')
        await handleLinkTrailerToVehicle(trailer?.id)
        await handleAttachmentUpload(trailer?.id)
        onForward?.()
        invalidateCache?.()
      } catch (error) {
        toastError({ toast, error })
      }

      return
    }

    try {
      const response = await apiServer.post<ResponseApiType<TrailerType>>(`/create-trailer`, {
        ...data,
        brand: data.brand.toUpperCase(),
        chassi: data.chassi.toUpperCase(),
        license_plate: data.license_plate.toUpperCase(),
        antt: data.license_plate.toUpperCase(),
        city_id: data.city_id.value,
      })
      const { data: newTrailer } = response.data

      showSuccessToast('trailer criado com sucesso!')
      await handleLinkTrailerToVehicle(newTrailer.id)
      await handleAttachmentUpload(newTrailer.id)
      onForward?.()
      invalidateCache?.()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  const handleGetValuesByCRLV = (CRLV: Partial<ResponseOcrCrlv>) => {
    if (CRLV) {
      reset({
        license_plate: CRLV.licensePlate,
        antt: CRLV.licensePlate,
        release_year: CRLV.manufactureYear,
        model_year: CRLV.modelYear,
        brand: CRLV.brandName,
        axes: CRLV.axes ? Number(CRLV.axes) : undefined,
        capacity_kg: CRLV.capacity ? Number(CRLV.capacity) * 1000 : undefined,
        chassi: CRLV.chassis,
        color: CRLV.predominantColor,
        model: CRLV.brandModelVersion,
        renavam: CRLV.renavam,
      })
    }
  }

  const handleCreateInitialValues = useCallback(
    (trailerValues: TrailerType) => {
      const { attachments, city } = trailerValues

      const attach = attachments?.find(item => item.type === 'crlv')
      if (attach) {
        dispatch(setCrlvTrailerImg(attach?.attachment_file_url || 'placeholder.png'))
      } else {
        dispatch(setCrlvTrailerImg(''))
      }

      reset({
        license_plate: trailerValues.license_plate,
        renavam: trailerValues.renavam,
        brand: trailerValues.brand,
        color: capitalizeWord(alterColorsGenderDatamex(trailerValues.color)) || '',
        model: trailerValues.model,
        model_year: trailerValues.model_year?.toString() || '',
        chassi: trailerValues.chassi,
        release_year: trailerValues.release_year?.toString() || '',
        antt: trailerValues.license_plate,
        license_uf: trailerValues.license_uf,
        vehicle_body_id: trailerValues?.vehicle_body?.id,
        axes: trailerValues?.axes || undefined,
        capacity_kg: trailerValues?.capacity_kg || undefined,
        capacity_m3: trailerValues?.capacity_m3 || undefined,
        capacity_tara: trailerValues?.capacity_tara || undefined,
        has_insurance: !!trailerValues?.has_insurance,
        has_tracker: !!trailerValues?.has_tracker,
        ...(!!city && {
          city_id: createCityInitialValue(city),
        }),
      })
    },
    [reset, dispatch],
  )

  useEffect(() => {
    if (initialValues) {
      setTrailer(initialValues)
      handleCreateInitialValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return (
    <VStack spacing={4} align="stretch">
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <UploadCRLVModal onSaveInformations={handleGetValuesByCRLV} />
        <Flex justifyContent="flex-end" pt="3">
          {trailer && (
            <Button
              size="sm"
              leftIcon={<RiCaravanLine />}
              colorScheme="red"
              isLoading={unlinkTrailerFromVehicleMutate.isLoading}
              onClick={handleUnlinkTrailerFromVehicle}
            >
              Desvincular reboque do veículo
            </Button>
          )}
        </Flex>
        <Box my={4}>
          <TrailerForm
            control={methods.control}
            setValue={methods.setValue}
            onVehicleFound={data => {
              if (data) {
                setTrailer(data)
                handleCreateInitialValues(data)
              }
            }}
          />
        </Box>
        <Divider my={4} />
        <Flex justifyContent="flex-end" gridGap="2">
          <Button onClick={() => onBack?.()} to="/" variant="ghost" colorScheme="red">
            Voltar
          </Button>
          <Button
            type="submit"
            colorScheme="orange"
            isLoading={methods.formState.isSubmitting}
            leftIcon={<Icon as={RiUserAddLine} />}
          >
            Cadastrar Trailer
          </Button>
        </Flex>
      </form>
    </VStack>
  )
}

export default FormTrailerProvider
