import { Button, Divider, Flex, Heading, Icon, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { RiArrowLeftLine } from 'react-icons/ri'
import RntrcForm from '../../../../../documents/rntrc-consultation/Forms/RntrcForm'

interface RntrcConsultationModalProps {
  isOpen: boolean
  onClose: () => void
  cpf?: string
  cnpj?: string
  rntrc?: string
  licensePlate?: string
}

export function RntrcConsultationModal({
  isOpen,
  onClose,
  cpf,
  cnpj,
  rntrc,
  licensePlate,
}: RntrcConsultationModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <Flex alignItems="baseline" m="6">
          <Button onClick={onClose} leftIcon={<Icon as={RiArrowLeftLine} fontSize="2xl" />}>
            Fechar
          </Button>
          <Heading size="lg" fontWeight="bold" ml="4">
            Consultar RNTRC
          </Heading>
        </Flex>

        <Divider my={3} />
        <Flex mx="8" my="4">
          <RntrcForm cpf={cpf} cnpj={cnpj} rntrc={rntrc} licensePlate={licensePlate} />
        </Flex>
      </ModalContent>
    </Modal>
  )
}
