import {
  Box,
  Button,
  Center,
  Flex,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Text,
  Heading,
} from '@chakra-ui/react'
import { handleFormatDate } from '../../../../../services/endpoints/datamex/getCtes'
import { useGetAllFreightContracts } from '../../../../../services/endpoints/freight-contracts/getAllFreightContracts'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import parseCurrency from '../../../../../services/utils/parseCurrency'
import { boxWidth, displayAdaptable } from '../../../../../services/utils/tableAdaptable'

interface ListFreightContractProps {
  freight: FormattedOneFreight
  bg: string
}

export function ListFreightContract({ freight, bg }: ListFreightContractProps): JSX.Element | null {
  const { data: freightContracts, isLoading } = useGetAllFreightContracts({
    freight_id: freight.id,
    unpaged: true,
  })

  return (
    <>
      <Box bg={bg} p="4" borderRadius="8" mt="4">
        <Flex justify="space-between" align="center">
          <Heading fontSize="md" fontWeight="medium">
            Contratos do Frete
          </Heading>
        </Flex>

        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            {freightContracts?.data && freightContracts?.data?.length > 0 ? (
              <Flex mt="4" w={boxWidth}>
                <Table size="sm" d={displayAdaptable} overflowX="auto">
                  <Thead>
                    <Tr>
                      <Th>NÚMERO</Th>
                      <Th>TALÃO</Th>
                      <Th>EMISSÃO</Th>
                      <Th>MOTORISTA</Th>
                      <Th>ADIANTAMENTO</Th>
                      <Th>SALDO</Th>
                      <Th>VALOR TOTAL</Th>
                      <Th>VALOR PEDÁGIO</Th>
                      <Th>VALOR LÍQUIDO</Th>
                      <Th>FRETE</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {freightContracts.data.map(freightContract => (
                      <Tr key={freightContract.id}>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          <Tooltip label="Visualizar CTe" hasArrow placement="auto">
                            <Box>
                              <Link
                                href={`/documents-freightContract-list/show/${freightContract.id}`}
                                target="_blank"
                              >
                                {freightContract.number}
                              </Link>
                            </Box>
                          </Tooltip>
                        </Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {freightContract.type.split(' - ')[1]}
                        </Td>
                        <Td fontSize="xs">{`${handleFormatDate(freightContract.emit_date)}`}</Td>
                        <Td fontSize="xs">{freightContract.motorist_name}</Td>
                        <Td fontSize="xs">{parseCurrency(freightContract.advance_value)}</Td>
                        <Td fontSize="xs">{parseCurrency(freightContract.balance)}</Td>
                        <Td>{parseCurrency(freightContract.total_value)}</Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {`${parseCurrency(freightContract.toll_value)}`}
                        </Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {`${parseCurrency(freightContract.freight_net_value)}`}
                        </Td>
                        <Td>
                          {freightContract.freight_id ? (
                            <Button
                              size="xs"
                              as={Link}
                              href={`/freights/show/${freightContract.freight_id}`}
                              colorScheme="blue"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Frete
                            </Button>
                          ) : undefined}
                        </Td>
                        <Td>{/* <ButtonsActions freightContract={freightContract} /> */}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            ) : (
              <Text textAlign="center">Nenhum resultado encontrado</Text>
            )}
          </>
        )}
      </Box>
    </>
  )
}
