import { useAppSelector } from '../../../../store'
import { SendToDatamex } from '../../pendencies/forms/send-EmiteAi-Datamex/SendEmiteAi-Datamex'
import { DrawerWrapper } from '../components/DrawerWrapper'

type AnttModalProps = {
  isOpen: boolean
  onClose: () => void
}

const DatamaxModalForm = ({ isOpen, onClose }: AnttModalProps): JSX.Element => {
  const { freight } = useAppSelector(state => state.freightPendenciesSlice)
  return (
    <>
      <DrawerWrapper
        isOpen={isOpen}
        onForceClose={onClose}
        onClose={onClose}
        title="Sicronizar com Datamex"
        onSaveLabel="Ok"
        hiddenSaveButton
      >
        {freight && <SendToDatamex freight={freight} />}
      </DrawerWrapper>
    </>
  )
}

export default DatamaxModalForm
