import { Box, Button, Divider, Flex, Grid, GridItem, Icon, useColorModeValue } from '@chakra-ui/react'
import { addMinutes, format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import { Autocomplete, AutocompleteMulti, AutocompleteMultiAsync } from '../../../../components/form'
import { AutocompleteAsync } from '../../../../components/form/AutocompleteAsync'
import { InputMask } from '../../../../components/form/InputMask'
import { InputNumberMask } from '../../../../components/form/InputNumberMask'
import { Switch } from '../../../../components/form/Switch'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { ReportToExcel } from '../../../../components/reports/ReportToExcel'
import { SkeletonLoadingTable } from '../../../../components/SkeletonLoadingTable'
import { useAuth } from '../../../../contexts/AuthContext'
import { Layout } from '../../../../layout'
import { Pagination } from '../../../../layout/Pagination'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import { getContactsAutocomplete } from '../../../../services/endpoints/clients/client-contacts/getContactsAutocomplete'
import { searchClientsByName } from '../../../../services/endpoints/clients/searchClients'
import {
  fetchAllFreights,
  FiltersFreightsParams,
  freightStatusInProgressOptions,
  ManyStatus,
} from '../../../../services/endpoints/freights/getFreights'
import { searchMotoristByName } from '../../../../services/endpoints/motorists/searchMotoristByName'
import { useGetFreightsTolls } from '../../../../services/endpoints/truckpad/getFreightsTolls'
import { getUserOperation } from '../../../../services/endpoints/users/getUserOperation'
import { getUserSales } from '../../../../services/endpoints/users/getUserSales'
import { calculateTripDuration } from '../../../../services/utils/calculateTripDuration'
import { TableContainerFreightsTollStatus } from './table'

const per_page = 15
interface FiltersFreights {
  motorist_id?: AutocompleteOption
  client_id?: AutocompleteOption
  creator_id?: AutocompleteOption
  seller_id?: AutocompleteOption
  client_contact_id?: AutocompleteOption[]
  origin_id?: AutocompleteOption
  destination_id?: AutocompleteOption
  is_monitoring?: boolean
  status?: AutocompleteOption[]
  many_status?: string[]
  freight_number?: number | string
  initial_date?: Date | string
  final_date?: Date | string
  has_monitoring_type?: 'app' | 'buonny' | 'whatsapp' | 'tracker_devices'
  place_vehicle?: string
  page?: number
  per_page?: number
}

export function FreightsTollStatus(): JSX.Element {
  const {
    getUserIdToListFreights,
    getSellerIdToListFreights,
    setUserIdToListFreights,
    setSellerIdToListFreights,
  } = useAuth()
  const bgFilters = useColorModeValue('gray.100', 'gray.800')
  const bgMain = useColorModeValue('white', 'gray.900')
  const [initialDate, setInitialDate] = useState<string>(() => {
    return format(new Date().setDate(new Date().getDate() - 30), "yyyy-MM-dd'T'00:00:00")
  })
  const [finalDate, setFinalDate] = useState<string>(() => {
    return format(new Date(), "yyyy-MM-dd'T'23:59:59")
  })
  const [filters, setFilters] = useState<FiltersFreightsParams>()
  const [isFilters, setIsFilters] = useState<boolean>()
  const [page, setPage] = useState(1)
  const { setValue, handleSubmit, formState } = useForm({})

  const {
    data: allFreights,
    isLoading: isLoadingAllFreights,
    isFetching: isFetchingAllFreights,
  } = useGetFreightsTolls({
    ...filters,
    many_status: filters?.status
      ? (filters.status as ManyStatus)
      : ['pending', 'in_origin', 'collecting', 'ready', 'on_road', 'in_destination', 'delivered', 'finished'],
    initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
    final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
    page,
    per_page,
  })

  const handleFilterFreightsInProgress: SubmitHandler<FiltersFreights> = async data => {
    const dataFilter = {
      initial_date: data.initial_date,
      final_date: data.final_date,
      freight_number: data.freight_number,
      status: data.status?.map(item => item.value),
      motorist_id: data.motorist_id?.value,
      has_monitoring_app: data.has_monitoring_type === 'app' ? true : undefined,
      has_monitoring_buonny: data.has_monitoring_type === 'buonny' ? true : undefined,
      has_monitoring_whatsapp: data.has_monitoring_type === 'whatsapp' ? true : undefined,
      has_monitoring_tracker_devices: data.has_monitoring_type === 'tracker_devices' ? true : undefined,
      place_vehicle: data.place_vehicle,
      origin_id: data.origin_id?.value,
      destination_id: data.destination_id?.value,
      client_contact_id: data.client_contact_id?.map(item => item.value),
      client_id: data.client_id?.value,
      seller_id: data.seller_id?.value,
      user_id: data.creator_id?.value,
    } as FiltersFreightsParams
    const {
      initial_date,
      final_date,
      freight_number,
      status,
      client_id,
      client_contact_id,
      motorist_id,
      has_monitoring_app,
      has_monitoring_buonny,
      has_monitoring_whatsapp,
      has_monitoring_tracker_devices,
      place_vehicle,
      origin_id,
      destination_id,
      ...rest
    } = dataFilter
    setFilters({
      initial_date,
      final_date,
      freight_number,
      motorist_id,
      status,
      client_id,
      client_contact_id,
      has_monitoring_app,
      has_monitoring_buonny,
      has_monitoring_whatsapp,
      has_monitoring_tracker_devices,
      place_vehicle,
      origin_id,
      destination_id,
      ...rest,
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      getUserIdToListFreights()
      getSellerIdToListFreights()
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFreights, filters])

  return (
    <Layout
      initialDate={initialDate}
      finalDate={finalDate}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      isFilters
      SidebarMobile
    >
      <Box bg={bgMain}>
        {isFilters && (
          <Box p={6} borderRadius={8} shadow="md" bg={bgFilters}>
            <form onSubmit={handleSubmit(handleFilterFreightsInProgress)}>
              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                {/* <DateRange title="Data de Coleta" /> */}

                <GridItem colSpan={[12, 6, 3]}>
                  <AutocompleteAsync
                    name="origin_id"
                    placeholder="Origem"
                    setValue={setValue}
                    loadOptions={searchCitiesByName}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <AutocompleteAsync
                    name="destination_id"
                    placeholder="Destino"
                    setValue={setValue}
                    loadOptions={searchCitiesByName}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Autocomplete
                    name="creator_id"
                    placeholder="Analista"
                    setValue={setValue}
                    onSelectOption={userSelected => {
                      if (userSelected.value) {
                        setUserIdToListFreights(userSelected)
                        getUserIdToListFreights()
                      } else {
                        setUserIdToListFreights()
                        getUserIdToListFreights()
                      }
                    }}
                    clearState={() => {
                      setUserIdToListFreights(undefined)
                      getUserIdToListFreights()
                    }}
                    loadOptions={getUserOperation}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Autocomplete
                    name="seller_id"
                    placeholder="Vendedor"
                    setValue={setValue}
                    onSelectOption={userSelected => {
                      if (userSelected.value) {
                        setSellerIdToListFreights(userSelected)
                        getSellerIdToListFreights()
                      } else {
                        setSellerIdToListFreights()
                        getSellerIdToListFreights()
                      }
                    }}
                    clearState={() => {
                      setSellerIdToListFreights(undefined)
                      getSellerIdToListFreights()
                    }}
                    loadOptions={getUserSales}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <InputNumberMask name="freight_number" placeholder="Número do frete" setValue={setValue} />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <AutocompleteAsync
                    name="client_id"
                    placeholder="Cliente"
                    setValue={setValue}
                    loadOptions={searchClientsByName}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 4]}>
                  <AutocompleteMulti
                    name="status"
                    placeholder="Status"
                    setValue={setValue}
                    options={[
                      { label: '', value: '' },
                      { label: 'Ativo', value: 'pending' },
                      ...freightStatusInProgressOptions,
                    ]}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 2]}>
                  <InputMask
                    name="place_vehicle"
                    mask="aaa9*99"
                    maskPlaceholder=""
                    placeholder="Placa"
                    setValue={setValue}
                    uppercaseAll
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 6]}>
                  <AutocompleteMultiAsync
                    name="client_contact_id"
                    placeholder="Solicitante"
                    setValue={setValue}
                    loadOptions={getContactsAutocomplete}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <AutocompleteAsync
                    name="motorist_id"
                    setValue={setValue}
                    placeholder="Motorista"
                    loadOptions={searchMotoristByName}
                  />
                </GridItem>
                <GridItem colSpan="auto">
                  <Flex gridGap="3" justifyContent="flex-end" alignItems="center">
                    <Button
                      type="submit"
                      size="md"
                      colorScheme="blue"
                      leftIcon={<Icon as={RiSearchLine} />}
                      isLoading={isFetchingAllFreights || formState.isSubmitting}
                    >
                      Filtrar
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </Box>
        )}
        {isFilters && <Divider my="4" />}

        <Flex cursor="pointer" my={4} ml={2} justify="center">
          <Switch
            name="filters"
            label="Mostrar/Ocultar filtros"
            setValue={setValue}
            onChange={e => {
              setIsFilters(e.target.checked)
              setFilters(undefined)
            }}
          />

          {allFreights && allFreights.data?.length > 0 && (
            <ReportToExcel
              isLoading={isLoadingAllFreights}
              documentTitle="Relatório de pedágio dos fretes"
              columnsTitle={[
                'Frete',
                'Cliente',
                'Coleta',
                'P. Entrega',
                'Origem',
                'Destino',
                'Status',
                'Pedágio',
                'Pedágio pago',
              ]}
              onGenerateReport={async () => {
                const report = await fetchAllFreights({
                  initial_date: initialDate,
                  final_date: finalDate,
                  unpaged: true,
                })
                return report.data.map(freight => {
                  let deliveryDate
                  let tripDuration
                  if (freight.distance) {
                    const date = new Date()
                    date.setHours(12)
                    tripDuration = calculateTripDuration(freight.distance, 60, date)
                    if (freight.collect_cargo_at) {
                      deliveryDate = new Date(freight.collect_cargo_at)
                      tripDuration = calculateTripDuration(freight.distance, 60, deliveryDate)
                      deliveryDate = addMinutes(deliveryDate, tripDuration.totalTripDurationInMinutes)
                    }
                  }

                  return [
                    freight?.freight_number,
                    freight?.client?.name,
                    freight?.collect_cargo_at_pt_br,
                    freight?.delivered_cargo_at
                      ? format(new Date(freight.delivered_cargo_at), 'dd/MM/yy')
                      : tripDuration?.relativeTripDuration,
                    freight.origin?.name,
                    freight.destination?.name,
                    freight.formatted_status,
                    freight.toll_ticket,
                  ] as string[]
                })
              }}
            />
          )}
        </Flex>

        {isLoadingAllFreights ? (
          <SkeletonLoadingTable />
        ) : (
          <>
            {allFreights && (
              <>
                <TableContainerFreightsTollStatus freights={allFreights.data} />
                <Box mb={8}>
                  <Pagination
                    currentPage={page}
                    totalCountOfRegisters={allFreights.total}
                    registersPerPage={per_page}
                    onPageChange={setPage}
                    p="6"
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Layout>
  )
}
