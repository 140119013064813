import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Link,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { startOfMonth } from 'date-fns'
import { format } from 'date-fns-tz'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiFilter2Line, RiLineChartLine, RiSearchLine, RiUploadLine } from 'react-icons/ri'
import { Ability } from '../../../components/ability'
import { ButtonsActions } from '../../../components/datamex/list-ctes/ButtonsActions'
import { ReportToExcel } from '../../../components/reports/ReportToExcel'
import Filter from '../../../components/UI/Filter'
import { PageHeader } from '../../../components/UI/PageHeader'
import { useAuth } from '../../../contexts/AuthContext'
import fixOverflow from '../../../hooks/fixOverflow'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import {
  CteResponseFormatted,
  FetchCteReportParams,
  fetchCtes,
  handleFormatDate,
  useGetCtes,
} from '../../../services/endpoints/datamex/getCtes'
import { boxWidth, displayAdaptable } from '../../../services/utils/tableAdaptable'
import { FiltersCtes } from '../FiltersCtes'
import { FetchCteReportParamsFormatted, filterCte } from '../list/ListCtes'
import { SetCheckedPaymentDateModal } from './SetCheckedPaymentDateModal'
import { toastError } from '../../../config/error/toastError'
import { fetchReceivedBillsReport } from '../../../services/endpoints/datamex/getReceivedBillsReport'

export function CteReport(): JSX.Element {
  const toast = useToast()
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    key?: string
    nct?: string
    serie?: string
    page?: string
    emitter?: string
    taker?: string
    operator?: string
    type?: string
    status?: string
    payment_status?: string
    emit_date_ini?: string
    emit_date_final?: string
  }>()
  fixOverflow()
  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })
  const initialDate = () => {
    const now = startOfMonth(new Date())
    return format(now, 'yyyy-MM-dd')
  }

  const finalDate = () => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  }

  const { onFilterBySellerLogged } = useAuth()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [filters, setFilters] = useState<FetchCteReportParams>({
    emit_date_ini: initialDate(),
    emit_date_final: finalDate(),
  })

  const bg = useColorModeValue('white', 'gray.800')

  const {
    data: ctes,
    isLoading,
    refetch,
    error: errorGetCtes,
    isError: isErrorGetCtes,
  } = useGetCtes({
    ...filters,
    page: filters.page || 1,
    seller: onFilterBySellerLogged()?.value,
  })

  useEffect(() => {
    if (isErrorGetCtes) {
      toastError({ toast, error: errorGetCtes })
    }
  }, [isErrorGetCtes, errorGetCtes, toast])

  const { setValue, handleSubmit, formState, getValues } = useForm({
    resolver: yupResolver(filterCte),
  })

  const { errors, isSubmitting } = formState

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  const handleFilter: SubmitHandler<FetchCteReportParamsFormatted> = data => {
    setFilters({
      key: data.key,
      emit_date_ini: data.emit_date_ini || data.initial_date,
      emit_date_final: data.emit_date_final || data.final_date,
      nct: data.nct,
      serie: data.serie,
      status: data.status,
      type: data.type,
      emitter: data.emitter,
      taker: data.taker,
      origin_name: data.origin_name?.label,
      destination_name: data.destination_name?.label,
      operator: data.operator,
      payment_status: data.payment_status,
      page: data.page || 1,
    })
    updateQueryParams({
      key: data.key || undefined,
      nct: data.nct || undefined,
      serie: data.serie?.toString() || undefined,
      emitter: data.emitter || undefined,
      taker: data.taker || undefined,
      operator: data.operator || undefined,
      type: data.type || undefined,
      status: data.status || undefined,
      payment_status: data.payment_status || undefined,
    })
  }

  useEffect(() => {
    const keyQuery = getParams('key')
    const nctQuery = getParams('nct')
    const serieQuery = getParams('serie')
    const emitterQuery = getParams('emitter')
    const takerQuery = getParams('taker')
    const operatorQuery = getParams('operator')
    const typeQuery = getParams('type')
    const statusQuery = getParams('status')
    const paymentStatusQuery = getParams('payment_status')
    const pageQuery = getParams('page')

    if (
      keyQuery ||
      nctQuery ||
      serieQuery ||
      emitterQuery ||
      takerQuery ||
      operatorQuery ||
      typeQuery ||
      statusQuery ||
      paymentStatusQuery ||
      pageQuery
    ) {
      setValue('key', keyQuery || '')
      setValue('nct', nctQuery || '')
      setValue('serie', serieQuery || '')

      setValue('emitter', emitterQuery || '')
      setValue('taker', takerQuery || '')
      setValue('operator', operatorQuery || '')

      setValue('type', typeQuery || '')
      setValue('status', statusQuery || '')
      setValue('payment_status', paymentStatusQuery || '')
      setValue('page', Number(pageQuery) || 1)

      handleSubmit(handleFilter)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <PageHeader
        title=" Relatório de vendas"
        icon={RiLineChartLine}
        rightContent={
          <>
            <Button
              leftIcon={<RiFilter2Line />}
              variant="outline"
              colorScheme="blue"
              onClick={onToggleFilter}
            >
              Filtros
            </Button>
          </>
        }
      />
      <Filter showFilter={onShowFilter}>
        <form onSubmit={handleSubmit(handleFilter)} noValidate>
          <FiltersCtes
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            finalDate={finalDate()}
            initialDate={initialDate()}
          />

          <Stack direction="row" mt={3} justifyContent="flex-end">
            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Stack>
        </form>
      </Filter>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8" maxW={boxWidth}>
        <HStack spacing={3} justifyContent="flex-end">
          {ctes && ctes.data && ctes.data.length > 0 && (
            <>
              <Ability module="spotHub" action="show-generate-complete-report-ctes">
                <ReportToExcel
                  documentTitle="Relatório completo"
                  isLoading={false}
                  columnsTitle={[
                    'FRETE',
                    'SOLICITANTE',
                    'TIPO CTE PURO',
                    'EMPRESA',
                    'OPERADOR',
                    'EMISSOR',
                    'CTE',
                    'SÉRIE',
                    'TIPO DE CTE',
                    'CTE COMPARTILHADO',
                    'MÊS',
                    'EMISSÃO',
                    'REMETENTE',
                    'DESTINATÁRIO',
                    'TOMADOR',
                    'CLIENTE TRATADO',
                    'ORI',
                    'DES',
                    'Municipal',
                    'd',
                    'Aliquota ICMS',
                    'Aliquota ISS',
                    'Aliquota PIS/COFINS Receita',
                    'VENDEDOR',
                    'Tipo de Cliente',
                    'VALOR NF (R$)',
                    'PESO (KG)',
                    'TIPO DE EQUIPAMENTO',
                    'CATEGORIA',
                    'MODALIDADE',
                    'ENQUADR.',
                    'VALOR DO FRETE (R$)',
                    'CONTRATO DE FRETE (R$)',
                    'DESPESAS OPERACIONAIS  (R$)',
                    'DESPESAS REEMBOLSÁVEIS',
                    'SEGURO (%)',
                    'VALOR SEGURO (R$)',
                    'DESPESA CPRB (R$)',
                    'DESPESA PAGAMENTO (R$)',
                    'GRIS E CUSTOS COM CAD (R$)',
                    'ICMS (R$)',
                    'ISS (R$)',
                    'PIS/COFINS (R$)',
                    'ICMS (considerar) (R$)',
                    'CONTRATO DE FRETE (crédito PIS/COFINS) (R$)',
                    'DESPESAS OPERACIONAIS (crédito PIS/COFINS) (R$)',
                    'PIS/COFINS (sem ICMS na BC) (R$)',
                    'VALOR SEGURO (crédito PIS/COFINS) (R$)',
                    'IMPOSTO TOTAL (R$)',
                    'Coeficiente ajuste',
                    'RECEITA LÍQUIDA (R$)',
                    'LUCRO BRUTO (R$)',
                    'LUCRO BRUTO SEM BOLSÃO',
                    'BOLSÃO SEGURO',
                    'DESPESA FINANCEIRA (R$)',
                    'COMISSÃO / BONUS (R$)',
                    'MARGEM DE CONTRIBUIÇÃO LIQUIDO',
                    'MARGEM DE CONTRIBUIÇÃO BRUTO',
                    'MARGEM CONTIBUIÇÃO LÍQUIDA (5)',
                    'OBSERVAÇÃO',
                    'custo pedágio',
                  ]}
                  onGenerateReport={async () => {
                    const { sales_report_complete } = await fetchReceivedBillsReport({
                      ...filters,
                      start_date: filters.emit_date_ini,
                      end_date: filters.emit_date_final,
                      unpaged: true,
                    })
                    return sales_report_complete
                  }}
                />
              </Ability>

              <Ability module="spotHub" action="show-generate-report-seller">
                <ReportToExcel
                  documentTitle="Rel. de vendas"
                  isLoading={false}
                  columnsTitle={[
                    'FRETE',
                    'CTE',
                    'EMPRESA',
                    'OPERADOR',
                    'EMISSOR',
                    'SÉRIE',
                    'TIPO CTE',
                    'TIPO SPOTHUB',
                    'STATUS',
                    'EMISSÃO',
                    'REMETENTE',
                    'DESTINATÁRIO',
                    'TOMADOR',
                    'TOMADOR (CNPJ)',
                    'SOLICITANTE',
                    'ORI',
                    'DES',
                    'parUF',
                    'Alíquota ICMS',
                    'Alíquota PIS/COFINS Receita',
                    'VENDEDOR',
                    'Tipo de Cliente',
                    'VALOR NF',
                    'PESO',
                    'TIPO EQUIPAMENTO',
                    'CARROCERIA',
                    'CATEGORIA',
                    'MODALIDADE',
                    'ENQUADR',
                    'VALOR FRETE (R$)',
                    'VALOR MOTORISTA (R$)',
                    'VALOR CIOT (R$)',
                    'OBSERVAÇÃO',
                    'DESPESAS',
                    'MDFe',
                    'CIOT',
                    'Chave',
                    'PREVISÃO PAGAMENTO',
                    'STATUS PAGAMENTO',
                    'DATA PAGAMENTO',
                  ]}
                  onGenerateReport={async () => {
                    const { sales_report } = await fetchReceivedBillsReport({
                      ...filters,
                      start_date: filters.emit_date_ini,
                      end_date: filters.emit_date_final,
                      unpaged: true,
                    })
                    return sales_report
                  }}
                />
              </Ability>

              <ReportToExcel
                documentTitle="Contas a receber"
                isLoading={false}
                columnsTitle={[
                  'NFE',
                  'CTE',
                  'CHAVE CTE',
                  'EMISSÃO CTE',
                  'COBRANÇA',
                  'VENCIMENTO',
                  'PREVISÃO PAGAMENTO',
                  'VALOR',
                  'TOMADOR',
                  'CLIENTE TRATADO',
                  'SOLICITANTE',
                  'REFERÊNCIA NO CTE',
                  'STATUS DO PAGAMENTO',
                  'DATA DO PAGAMENTO',
                  'Nº FRETE',
                  'EMPRESA',
                  'STATUS DO CTE',
                  'TIPO DE FRETE',
                  'REMETENTE',
                  'DESTINATARIO',
                  'CNPJ TOMADOR',
                  'ALÍQUOTA',
                  'MODALIDADE',
                  'SÉRIE',
                ]}
                onGenerateReport={async () => {
                  const { data } = await fetchCtes({
                    ...filters,
                    emit_date_ini: filters.emit_date_ini,
                    emit_date_final: filters.emit_date_final,
                    unpaged: true,
                  })
                  const test = data.map((cte: CteResponseFormatted) => {
                    return [
                      String([cte.nfe_number]),
                      String([cte.nct]),
                      String([cte.key]),
                      [cte.emit_date],
                      [cte.billing_date],
                      [cte.client_payment_date],
                      [cte.checked_payment_date ? new Date(`${cte.checked_payment_date}T00:00:00`) : ''],
                      Number([cte.installment_value_formatted]),
                      String([cte.taker]),
                      String([cte.taker_alias]),
                      String([cte.client_contact]),
                      String([cte.client_ref_number_formatted]),
                      String([cte.payment_status]),
                      String([cte.payment_date]),
                      String([cte.freight_ref]),
                      String([cte.emitter]),
                      String([cte.status]),
                      String([cte.freight.type]),
                      String([cte.sender]),
                      String([cte.receiver]),
                      String([cte.taker_cnpj]),
                      String([cte.icms]),
                      String([cte.sector]),
                      String([cte.serie]),
                    ]
                  })

                  return test
                }}
              />

              <Button leftIcon={<Icon as={RiUploadLine} />} onClick={onOpen}>
                Atualizar data de pagamento
              </Button>
            </>
          )}
        </HStack>

        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            {ctes?.data && ctes?.data?.length > 0 ? (
              <>
                <Table size="sm" mt="4" d={displayAdaptable} overflowX="auto">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">CTE</Th>
                      <Th fontSize="xs">FRETE</Th>
                      <Th fontSize="xs">EMPRESA</Th>
                      <Th fontSize="xs">EMISSOR</Th>
                      <Th fontSize="xs">OPERADOR</Th>
                      <Th fontSize="xs">SÉRIE</Th>
                      <Th fontSize="xs">TIPO CTE</Th>
                      <Th fontSize="xs">TIPO SPOTHUB</Th>
                      <Th fontSize="xs">STATUS</Th>
                      <Th fontSize="xs">EMISSÃO</Th>
                      <Th fontSize="xs">REMETENTE</Th>
                      <Th fontSize="xs">DESTINATÁRIO</Th>
                      <Th fontSize="xs">TOMADOR</Th>
                      <Th fontSize="xs">TOMADOR (CNPJ)</Th>
                      <Th fontSize="xs">SOLICITANTE</Th>
                      <Th fontSize="xs">ORI</Th>
                      <Th fontSize="xs">DES</Th>
                      <Th fontSize="xs">parUF</Th>
                      <Th fontSize="xs">Alíquota ICMS</Th>
                      <Th fontSize="xs">Alíquota PIS/COFINS Receita</Th>
                      <Th fontSize="xs">VENDEDOR</Th>
                      <Th fontSize="xs">Tipo de Cliente</Th>
                      <Th fontSize="xs">VALOR NF</Th>
                      <Th fontSize="xs">PESO</Th>
                      <Th fontSize="xs">TIPO EQUIPAMENTO</Th>
                      <Th fontSize="xs">CARROCERIA</Th>
                      <Th fontSize="xs">CATEGORIA</Th>
                      <Th fontSize="xs">MODALIDADE</Th>
                      <Th fontSize="xs">ENQUADR</Th>
                      <Th fontSize="xs">VALOR FRETE (R$)</Th>
                      <Th fontSize="xs">VALOR MOTORISTA (R$)</Th>
                      <Th fontSize="xs">VALOR CIOT (R$)</Th>
                      <Th fontSize="xs">OBSERVAÇÃO</Th>
                      <Th fontSize="xs">DESPESAS</Th>
                      <Th fontSize="xs">MDFe</Th>
                      <Th fontSize="xs">CIOT</Th>
                      <Th fontSize="xs">Chave</Th>
                      <Th fontSize="xs">PREVISÃO PAGAMENTO</Th>
                      <Th fontSize="xs">STATUS PAGAMENTO</Th>
                      <Th fontSize="xs">DATA PAGAMENTO</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {ctes.data.map(cte => {
                      return (
                        <Tr key={cte.id}>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            <Tooltip label="Visualizar CTe" hasArrow placement="auto">
                              <Box>
                                <Link href={`/documents-cte-list/show/${cte.id}`} target="_blank">
                                  {cte.nct}
                                </Link>
                              </Box>
                            </Tooltip>
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            <Tooltip label="Ver o frete referenciado" hasArrow placement="auto">
                              <Box>
                                {cte.freight_url && (
                                  <Link href={`${cte.freight_url}`} target="_blank">
                                    {cte.freight_ref}
                                  </Link>
                                )}
                              </Box>
                            </Tooltip>
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.emitter}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.operator}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.creator}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.serie}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.type}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.type_spothub}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.status}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {handleFormatDate(cte.emit_date)}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.sender}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.receiver}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.taker}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.taker_cnpj}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.client_contact}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.origin}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.destination}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.par_uf}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.aliquot_icms || 0}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.pis_confins}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.seller}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.client_type}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.cargo_value}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.cargo_weight}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.vehicle_category}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.vehicle_body}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.vehicle_weight}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.sector}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.owner_type}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.installment_value_formatted}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.motorist_value_formatted}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.gross_ciot_value}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.observation}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.expenses}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.mdfe}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.ciot}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.key}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.checked_payment_date_formatted}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.payment_status}
                          </Td>
                          <Td fontSize="xs" whiteSpace="nowrap">
                            {cte.payment_date_formatted}
                          </Td>
                          <Td fontSize="xs">
                            <ButtonsActions cte={cte} />
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </>
            ) : (
              <Text textAlign="center">Nenhum registro</Text>
            )}
          </>
        )}
        {ctes && ctes.total > 0 && (
          <Box mt={5}>
            <Pagination
              currentPage={filters.page || 1}
              totalCountOfRegisters={ctes.total}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </Box>
      <SetCheckedPaymentDateModal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          refetch()
        }}
      />
    </Layout>
  )
}
