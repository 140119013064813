import { Box, Button, Divider, Flex, Icon, useColorModeValue, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { RiUserAddLine } from 'react-icons/ri'
import { Link, useHistory } from 'react-router-dom'
import { Ability } from '../../../components/ability'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { PageHeader } from '../../../components/UI/PageHeader'
import { toastError } from '../../../config/error/toastError'
import { Layout } from '../../../layout'
import { ownerPayload, useCreateOwner } from '../../../services/endpoints/owners/createOwner'
import getOnlyNumbers from '../../../services/utils/getOnlyNumbers'
import {
  AnttForm,
  anttFormInitialValues,
  AnttFormValues,
  AnttOnwerSchema,
} from '../../freights/pendencies-v2/forms/antt'

interface FormOption {
  label: string
  value: string | number
}

export interface OwnerFormData {
  type?: 'pf' | 'pj'
  cnpj?: string
  email?: string
  cpf?: string
  name?: string
  rntrc?: string
  rntrc_type?: 'E' | 'C' | 'EQ' | 'T'
  phone?: string
  other_phone?: string
  zipcode?: string
  address?: string
  address_number?: string
  district?: string
  city_id?: AutocompleteOption
  ie?: string
  mother_name?: string
  rg?: string
  rg_uf?: string
  rg_dispatcher?: string
  birth?: Date
  rg_dispatch_date?: Date
  pis?: string
  bank?: FormOption
  account_type?: string
  agency?: string
  account?: string
}

export function CreateOwner(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const history = useHistory()
  const toast = useToast()

  const methods = useForm<AnttFormValues>({
    mode: 'onChange',
    defaultValues: anttFormInitialValues,
    resolver: yupResolver(AnttOnwerSchema),
  })

  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const createOwner = useCreateOwner({
    onSuccess: () => {
      showSuccessToast('cadastro do proprietário criado!')
    },
  })

  const onSubmit: SubmitHandler<AnttFormValues> = async data => {
    try {
      const payload = {
        type: data.type,
        name: data.name,
        ...(data.type === 'pf'
          ? {
              birth: data.birth,
              cpf: getOnlyNumbers(data.document),
              rg: data.rg_ie,
              pis: data.pis,
              rg_dispatch_date: data.rg_dispatch_date,
              rg_dispatcher: data.rg_dispatcher,
              rg_uf: data.rg_uf,
              father_name: data.father_name,
              mother_name: data.mother_name,
            }
          : {
              cnpj: getOnlyNumbers(data.document),
              ie: data.rg_ie,
            }),
        phone: getOnlyNumbers(data.phone),
        address: data.address,
        address_number: data.address_number,
        district: data.district,
        city_id: data?.city.value,
        zipcode: getOnlyNumbers(data.zipcode),
        rntrc: data.rntrc,
        rntrc_type: data.rntrc_type,
        account: data.account,
        account_type: data.account_type,
        agency: data.agency,
        bank: data.bank?.value,
      } as ownerPayload

      const {
        data: { id },
      } = await createOwner.mutateAsync({
        ...payload,
      })

      history.push(`/owners/show/${id}`)
    } catch (error) {
      toastError(error)
    }
  }

  return (
    <Layout>
      <PageHeader title="Cadastro de proprietário" icon={RiUserAddLine} />
      <Box bg={bg} p="4" borderRadius="8" shadow="md">
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate autoComplete="off">
          <FormProvider {...methods}>
            <AnttForm />
          </FormProvider>

          <Divider my="4" />
          <Flex justifyContent="flex-end" gridGap="2">
            <Button as={Link} to="/owners" variant="ghost" colorScheme="red">
              Cancelar
            </Button>

            <Ability module="people" action="create-owner">
              <Button
                type="submit"
                colorScheme="orange"
                isLoading={methods.formState.isSubmitting}
                leftIcon={<Icon as={RiUserAddLine} />}
              >
                Cadastrar proprietário
              </Button>
            </Ability>
          </Flex>
        </form>
      </Box>
    </Layout>
  )
}
