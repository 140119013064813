import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { RiSearchLine } from 'react-icons/ri'
import ReactInputMask from 'react-input-mask'

type UIInputProps = InputProps & {
  mask?: string
  maskPlaceholder?: string
  label?: string
  isError?: boolean
  errorMessage?: string
  helperText?: string
  isRequired?: boolean
  isLoading?: boolean
  isSearchable?: boolean
  onSearch?: (value: string) => void
}

export const UIInput = forwardRef<HTMLInputElement, UIInputProps>(
  (
    {
      helperText,
      isRequired,
      isError,
      errorMessage,
      mask,
      maskPlaceholder = '',
      label,
      isLoading,
      isSearchable = false,
      size,

      onSearch,
      ...props
    },
    ref,
  ) => {
    return (
      <FormControl isInvalid={isError} isRequired={isRequired} isDisabled={props.isDisabled}>
        {label && (
          <FormLabel fontSize="14" mb="1.5">
            {label}
          </FormLabel>
        )}
        <InputGroup size={size} fontSize="14">
          <Input
            as={mask ? ReactInputMask : 'input'}
            {...(mask && {
              mask,
              maskPlaceholder,
            })}
            px="2"
            {...props}
            ref={ref}
          />

          {isSearchable && (
            <InputRightElement w="fit-content" px="2">
              {isLoading && !onSearch && <Spinner size="xs" />}
              {onSearch && (
                <Button
                  size="xs"
                  variant="outline"
                  isLoading={isLoading}
                  onClick={() => onSearch?.(props.value as string)}
                >
                  <RiSearchLine />
                </Button>
              )}
            </InputRightElement>
          )}
        </InputGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    )
  },
)

UIInput.displayName = 'UIInput'
