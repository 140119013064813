/* eslint-disable react-hooks/rules-of-hooks */
import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { LatLngTuple, PathOptions } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useEffect, useRef, useState } from 'react'
import { RiFullscreenExitLine, RiFullscreenLine } from 'react-icons/ri'
import {
  LayersControl,
  MapContainer,
  MapContainerProps,
  Polyline,
  TileLayer as LeafletTileLayer,
  useMap,
  ZoomControl,
} from 'react-leaflet'
import { getGoogleSessionTokens } from './helpers'
import { LoadingMapSpinner } from './LoadingMapSpinner'

const mapboxKey = process.env.REACT_APP_MAPBOX_STYLE_KEY
const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

const tileLayerAttribution = '&copy; SpotX Logística'

type SourceType = 'google' | 'mapbox' | 'openstreetmap'

type MapPolyline = PathOptions & {
  positions: LatLngTuple[]
}
type MapProps = MapContainerProps & {
  source: SourceType
  polylines?: MapPolyline[]
  distance?: number
  children?: JSX.Element | JSX.Element[]
  bounds?: LatLngTuple[]
}

function TileLayer({ source, bounds }: { source: SourceType; bounds: LatLngTuple[] }): JSX.Element {
  const map = useMap()

  useEffect(() => {
    map.flyToBounds(bounds, { maxZoom: 11 })
  }, [map, bounds])

  if (source === 'openstreetmap') {
    return (
      <LeafletTileLayer
        attribution={tileLayerAttribution}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    )
  }

  const [isLoading, setIsLoading] = useState(true)
  const [tileLayers, setTileLayers] = useState({
    map: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    satellite: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  })

  useEffect(() => {
    async function loadTileLayers() {
      if (source === 'mapbox') {
        setTileLayers({
          map: `https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxKey}`,
          satellite: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxKey}`,
        })
      }
      if (source === 'google') {
        const googleSessionTokens = await getGoogleSessionTokens()
        setTileLayers({
          map: `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?session=${googleSessionTokens.roadmap}&key=${googleApiKey}`,
          satellite: `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?session=${googleSessionTokens.satellite}&key=${googleApiKey}`,
        })
      }
      setIsLoading(false)
    }
    loadTileLayers()
  }, [source])

  return isLoading ? (
    <LoadingMapSpinner />
  ) : (
    <LayersControl position="topleft">
      <LayersControl.BaseLayer name="Mapa" checked>
        <LeafletTileLayer attribution={tileLayerAttribution} url={tileLayers.map} />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Satélite">
        <LeafletTileLayer attribution={tileLayerAttribution} url={tileLayers.satellite} />
      </LayersControl.BaseLayer>
    </LayersControl>
  )
}

function FullScreenControl({
  isFullscreen,
  onClick,
}: {
  isFullscreen: boolean
  onClick: () => void
}): JSX.Element {
  return (
    <Flex
      position="absolute"
      zIndex={1000}
      top="10px"
      right="10px"
      bgColor="white"
      color="black"
      p="2"
      borderRadius="sm"
      cursor="pointer"
      onClick={onClick}
      pointerEvents="auto"
    >
      {isFullscreen ? (
        <Icon as={RiFullscreenExitLine} fontSize="20" />
      ) : (
        <Icon as={RiFullscreenLine} fontSize="20" />
      )}
    </Flex>
  )
}

export function Map({
  source,
  polylines,
  distance,
  children,
  bounds = [
    [-3.675295, -60.26574],
    [-23.687185, -40.284531],
  ],
  ...props
}: MapProps): JSX.Element {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const [mapBounds, setMapBounds] = useState<LatLngTuple[]>(bounds)

  useEffect(() => {
    if (polylines && polylines.length > 0) {
      const polyline = polylines.filter(item => item.positions.length > 0)[0]
      if (polyline.positions.length > 0) {
        const firstPoint = polyline.positions[0]
        const lastPoint = polyline.positions[polyline.positions.length - 1]
        setMapBounds([firstPoint, lastPoint])
      }
    }
  }, [polylines])

  return (
    <Flex w="100%" h="100%" ref={mapContainerRef}>
      <MapContainer
        zoomControl={false}
        style={{ width: '100%', height: '100%' }}
        bounds={bounds}
        attributionControl={false}
        doubleClickZoom={false}
        boundsOptions={{ maxZoom: 5 }}
        {...props}
      >
        {distance && (
          <Tooltip label="Distância total calculada entre o primeiro e o último ponto do trajeto">
            <Text
              position="absolute"
              bgColor="white"
              p="2"
              bottom="10px"
              left="10px"
              zIndex={1000}
              fontWeight="bold"
              fontSize="sm"
              pointerEvents="auto"
            >
              Distância Total: {Intl.NumberFormat('pt-BR').format(distance)} km
            </Text>
          </Tooltip>
        )}
        <TileLayer source={source} bounds={mapBounds} />
        <ZoomControl position="bottomright" />
        <FullScreenControl
          isFullscreen={isFullscreen}
          onClick={() => {
            if (mapContainerRef.current && mapContainerRef.current.requestFullscreen) {
              mapContainerRef.current.onfullscreenchange = () => setIsFullscreen(state => !state)
              if (!isFullscreen) {
                mapContainerRef.current.requestFullscreen()
              } else {
                document.exitFullscreen()
              }
            }
          }}
        />

        {polylines?.map(polyline => {
          const { positions, ...rest } = polyline
          const key = Math.random()
          return positions.length > 0 && <Polyline key={key} pathOptions={rest} positions={positions} />
        })}

        {children}
      </MapContainer>
    </Flex>
  )
}
