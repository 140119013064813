/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiPaperclip } from 'react-icons/fi'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'
import { RiEditLine } from 'react-icons/ri'
import { Link, useParams } from 'react-router-dom'
import { PreviewFiles } from '../../../../../components/DropzoneImageUpload/PreviewFiles'
import { toastError } from '../../../../../config/error/toastError'
import { Layout } from '../../../../../layout'
import { useGetAdvanceRequest } from '../../../../../services/endpoints/freights/getAdvanceRequest'
import { getStatus } from '../../../../../services/endpoints/freights/getFreights'
import {
  AdvanceRequestAttachmentTypeEnum,
  AdvanceRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'
import { advanceRequestStatusLegend } from '../../../../../services/utils/advanceRequestStatusLegend'
import { formatAccountType } from '../../../../../services/utils/formatAccountType'
import formatCPFAndCNPJ from '../../../../../services/utils/formatCPFAndCNPJ'
import { getAdvanceRequestColorByStatus } from '../../../../../services/utils/getAdvanceRequestColorByStatus'
import { HandleAdvanceRequestStatusModal } from '../modals/HandleAdvanceRequestStatusModal'
import { UpdateAdvanceRequestModal } from '../modals/UpdateAdvanceRequestModal'
import { UploadAdvanceRequestReceiptsModal } from '../modals/UploadAdvanceRequestReceiptsModal'

type RequestParams = {
  id: string
}

type AnalysisStatusType = 'approved' | 'rejected' | 'request_review' | 'finish'

type SplitAttachmentsType = {
  contractAttachments: string[]
  receiptAttachments: string[]
}

export function ShowAdvanceRequest(): JSX.Element {
  const [splitAttachments, setSplitAttachments] = useState<SplitAttachmentsType>()
  const toast = useToast()
  const { id } = useParams<RequestParams>()
  const bg = useColorModeValue('white', 'gray.800')
  const tabs = ['Contratos:', 'Recibos:']

  const [analysisStatus, setAnalysisStatus] = useState<AnalysisStatusType>('approved')

  const { data: advanceRequestData, isLoading, isError, error } = useGetAdvanceRequest({ id })

  const {
    isOpen: isOpenHandleAdvanceRequestStatusModal,
    onOpen: onOpenHandleAdvanceRequestStatusModal,
    onClose: onCloseHandleAdvanceRequestStatusModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUploadAdvanceRequestReceiptsModal,
    onOpen: onOpenUploadAdvanceRequestReceiptsModal,
    onClose: onCloseUploadAdvanceRequestReceiptsModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUpdateAdvanceRequestModal,
    onOpen: onOpenUpdateAdvanceRequestModal,
    onClose: onCloseUpdateAdvanceRequestModal,
  } = useDisclosure()

  function handleStatus(status: AnalysisStatusType): void {
    setAnalysisStatus(status)
    onOpenHandleAdvanceRequestStatusModal()
  }

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  useEffect(() => {
    if (advanceRequestData && advanceRequestData.attachments && advanceRequestData.attachments.length > 0) {
      const contractAttachments = advanceRequestData.attachments
        .filter(
          attachment =>
            attachment.type === AdvanceRequestAttachmentTypeEnum.CONTRACT &&
            typeof attachment.attachment_file_url === 'string' &&
            attachment.attachment_file_url.trim() !== '',
        )
        .map(attachment => attachment.attachment_file_url!) as string[]

      const receiptAttachments = advanceRequestData.attachments
        .filter(
          attachment =>
            ['receipt', 'advance_receipt', 'balance_receipt'].includes(attachment.type) &&
            typeof attachment.attachment_file_url === 'string' &&
            attachment.attachment_file_url.trim() !== '',
        )
        .map(attachment => attachment.attachment_file_url!) as string[]

      setSplitAttachments({ contractAttachments, receiptAttachments })
    }
  }, [advanceRequestData])

  return (
    <Layout>
      {!isLoading ? (
        advanceRequestData ? (
          <>
            <Flex mb={2} alignItems="center" justifyContent="space-between">
              <Heading fontSize="2xl" display="flex" alignItems="center">
                <Text ml={2}>Frete #{advanceRequestData?.freight?.freight_number} - </Text>

                <Tooltip
                  label={advanceRequestStatusLegend(advanceRequestData?.status)}
                  hasArrow
                  placement="top"
                  arrowSize={15}
                >
                  <Badge
                    fontSize="sm"
                    px="2"
                    py="2"
                    colorScheme={getAdvanceRequestColorByStatus(advanceRequestData.status)}
                    ml={2}
                  >
                    {advanceRequestData.formatted_status}
                  </Badge>
                </Tooltip>
              </Heading>

              {advanceRequestData.status === AdvanceRequestStatusEnum.AWAITING_ANALYSIS && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="green"
                    onClick={() => {
                      handleStatus('approved')
                    }}
                  >
                    <Icon fontSize={16} as={IoCheckmark} mr={1} /> Aprovar
                  </Button>

                  <Button
                    size="sm"
                    colorScheme="red"
                    ml={2}
                    onClick={() => {
                      handleStatus('rejected')
                    }}
                  >
                    <Icon fontSize={20} as={IoCloseOutline} mr={1} /> Reprovar
                  </Button>
                </Box>
              )}

              {advanceRequestData.status === AdvanceRequestStatusEnum.AWAITING_PAYMENT && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="yellow"
                    borderRadius="full"
                    onClick={onOpenUploadAdvanceRequestReceiptsModal}
                  >
                    <Icon fontSize={16} as={FiPaperclip} mr={1} /> Anexar comprovante
                  </Button>

                  <Button
                    size="sm"
                    colorScheme="cyan"
                    borderRadius="full"
                    onClick={() => {
                      handleStatus('request_review')
                    }}
                    ml={2}
                  >
                    <Icon fontSize={16} as={RiEditLine} mr={1} /> Reprovar
                  </Button>
                </Box>
              )}

              {advanceRequestData.status === AdvanceRequestStatusEnum.PAID && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={() => {
                      handleStatus('finish')
                    }}
                  >
                    <Icon fontSize={16} as={IoCheckmark} mr={1} /> Finalizar
                  </Button>
                </Box>
              )}

              {advanceRequestData.status === AdvanceRequestStatusEnum.REJECTED && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={() => {
                      onOpenUpdateAdvanceRequestModal()
                    }}
                  >
                    <Icon fontSize={16} as={RiEditLine} mr={1} /> Editar
                  </Button>
                </Box>
              )}
            </Flex>

            {advanceRequestData.reject_reason && (
              <Alert status="warning" variant="left-accent">
                <Box>
                  <AlertTitle mb={2}>Motivo de reprovação!</AlertTitle>
                  <AlertDescription>{advanceRequestData.reject_reason}</AlertDescription>
                </Box>
              </Alert>
            )}

            {advanceRequestData.observation && (
              <Alert status="info" variant="left-accent" mt={4}>
                <Box>
                  <AlertTitle mb={2}>Observação!</AlertTitle>
                  <AlertDescription>{advanceRequestData.observation}</AlertDescription>
                </Box>
              </Alert>
            )}

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações solicitação de adiantamento
                <Tooltip label="Responsável - Data de criação" hasArrow placement="top" arrowSize={10}>
                  <Badge
                    fontSize="sm"
                    px="2"
                    py="2"
                    colorScheme="gray"
                    ml={2}
                    display="flex"
                    alignItems="center"
                  >
                    {advanceRequestData.creator?.name} - {advanceRequestData.created_at}
                  </Badge>
                </Tooltip>
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Motivo:</Text>
                  <Text fontWeight="medium" ml="1">
                    {advanceRequestData?.reason}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações gerais do frete
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Número do frete:</Text>
                  <Tooltip label="Ir para página do frete" hasArrow>
                    <Text
                      as={Link}
                      to={`/freights/show/${advanceRequestData?.freight_id}`}
                      fontWeight="medium"
                      ml="1"
                    >
                      #{advanceRequestData?.freight?.freight_number}
                    </Text>
                  </Tooltip>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Status do frete:</Text>
                  <Badge px="2" py="1" colorScheme="gray" ml={2}>
                    {getStatus(advanceRequestData?.freight?.status)}
                  </Badge>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente:</Text>
                  <Text fontWeight="medium" ml="1">
                    {advanceRequestData?.freight?.client?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente CNPJ:</Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(advanceRequestData?.freight?.client?.cnpj)}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações de pagamento
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">E-Frete:</Text>
                  <Text fontWeight="medium" ml="1">
                    {advanceRequestData?.e_frete ? 'Sim' : 'Não'}
                  </Text>
                </WrapItem>

                {advanceRequestData.pix && (
                  <WrapItem>
                    <Text color="gray.400">Pix:</Text>
                    <Text fontWeight="medium" ml="1">
                      {advanceRequestData?.pix}
                    </Text>
                  </WrapItem>
                )}

                {advanceRequestData.account_type && (
                  <WrapItem>
                    <Text color="gray.400">Tipo de conta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {formatAccountType(advanceRequestData?.account_type)}
                    </Text>
                  </WrapItem>
                )}

                {advanceRequestData.bank && (
                  <WrapItem>
                    <Text color="gray.400">Banco:</Text>
                    <Text fontWeight="medium" ml="1">
                      {advanceRequestData?.bank}
                    </Text>
                  </WrapItem>
                )}

                {advanceRequestData.agency && (
                  <WrapItem>
                    <Text color="gray.400">Agencia:</Text>
                    <Text fontWeight="medium" ml="1">
                      {advanceRequestData?.agency}
                    </Text>
                  </WrapItem>
                )}

                {advanceRequestData.account && (
                  <WrapItem>
                    <Text color="gray.400">Conta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {advanceRequestData?.account}
                    </Text>
                  </WrapItem>
                )}

                {advanceRequestData.account_digit && (
                  <WrapItem>
                    <Text color="gray.400">Código banco:</Text>
                    <Text fontWeight="medium" ml="1">
                      {advanceRequestData?.account_digit}
                    </Text>
                  </WrapItem>
                )}
              </SimpleGrid>
            </Box>

            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Arquivos Vinculados
              </Heading>

              <Tabs colorScheme="orange" w="full">
                <TabList w="full" overflowX="auto" overflowY="hidden">
                  {tabs.map(tab => (
                    <Tab
                      fontSize="sm"
                      key={tab}
                      _active={{ outline: 'none' }}
                      _focus={{ outline: 'none' }}
                      _hover={{ outline: 'none', bg: 'none' }}
                      whiteSpace="nowrap"
                    >
                      {tab}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {tabs.map(tab => (
                    <TabPanel key={tab} p="0" mt="4">
                      {tab === 'Contratos:' && (
                        <>
                          {splitAttachments &&
                          splitAttachments.contractAttachments &&
                          splitAttachments.contractAttachments.length > 0 ? (
                            <PreviewFiles images={splitAttachments.contractAttachments} displayMode="list" />
                          ) : (
                            <Text>Nenhum contrato de frete encontrado</Text>
                          )}
                        </>
                      )}
                      {tab === 'Recibos:' && (
                        <>
                          {splitAttachments &&
                          splitAttachments.receiptAttachments &&
                          splitAttachments.receiptAttachments.length > 0 ? (
                            <PreviewFiles images={splitAttachments.receiptAttachments} displayMode="list" />
                          ) : (
                            <Text>Nenhum recibo encontrado</Text>
                          )}
                        </>
                      )}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </>
        ) : isError ? (
          <Text textAlign="center">Erro ao carregar a solicitação de adiantamento</Text>
        ) : (
          <Text textAlign="center">Nenhuma solicitação de adiantamento encontrada</Text>
        )
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Spinner mr={2} />
          <Text>Carregando dados</Text>
        </Flex>
      )}

      {isOpenHandleAdvanceRequestStatusModal && (
        <HandleAdvanceRequestStatusModal
          isOpen={isOpenHandleAdvanceRequestStatusModal}
          onClose={onCloseHandleAdvanceRequestStatusModal}
          analysisStatus={analysisStatus}
        />
      )}

      {isOpenUploadAdvanceRequestReceiptsModal && (
        <UploadAdvanceRequestReceiptsModal
          isOpen={isOpenUploadAdvanceRequestReceiptsModal}
          onClose={onCloseUploadAdvanceRequestReceiptsModal}
        />
      )}

      {isOpenUpdateAdvanceRequestModal && advanceRequestData && (
        <UpdateAdvanceRequestModal
          isOpen={isOpenUpdateAdvanceRequestModal}
          onClose={onCloseUpdateAdvanceRequestModal}
          initial_value={advanceRequestData}
        />
      )}
    </Layout>
  )
}
