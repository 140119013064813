import { Box, Grid, GridItem, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import useSearchAddress from '../../../../../hooks/useSearchAddress'
import useSearchMotorist, { useBlackListReturn } from '../../../../../hooks/useSearchMotorist'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'
import { FormattedMotorist } from '../../../../../services/endpoints/motorists/getMotorist'
import { ufOptions } from '../../../../../services/utils/ufOptions'
import { ShowInfosBlackListModal } from '../../../../black-list/modals/ShowInfosBlackListModal'
import { UIInput } from '../../components/UI/UIInput'
import { UISelect } from '../../components/UI/UISelect'
import { UISelectAsync } from '../../components/UI/UISelectAsync'
import { CNH_OPTIONS, PERSON_TYPES } from './options'
import { MotoristFormValues } from './type'

type MotoristFormProps = {
  onMotoristAlreadyFound?: (motorist: FormattedMotorist) => void
  disabled: boolean
}

export const MotoristForm = ({ onMotoristAlreadyFound, disabled }: MotoristFormProps): JSX.Element => {
  const [showInfosBlackListModal, setShowInfosBlackListModal] = useState<useBlackListReturn | null>(null)
  const { setValue, getValues, reset, control } = useFormContext<MotoristFormValues>()
  const toast = useToast()

  const { loadingAddress, searchAddress } = useSearchAddress(
    data => {
      reset({
        ...getValues(),
        address: data?.address || '',
        address_neighborhood: data?.district || '',
        address_city: {
          label: data?.city?.label || '',
          value: data?.city?.value || '',
        },
        address_state_uf: data.uf || '',
      })
      toast({
        title: 'Endereço encontrado',
        description: 'Endereço encontrado com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    () => {
      toast({
        title: 'Endereço não encontrado',
        description: 'Endereço não encontrado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  )

  const { loading, search } = useSearchMotorist({
    onSuccess: data => {
      if (data.motorist) {
        onMotoristAlreadyFound?.(data.motorist)
        toast({
          title: 'Motorista encontrado',
          description: 'Motorista encontrado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    onBlackList: data => {
      if (data) {
        setShowInfosBlackListModal(data)
        toast({
          title: 'Motorista na lista negra',
          description: 'Motorista encontrado na lista negra.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },
  })

  return (
    <Box pointerEvents={disabled ? 'none' : 'auto'} opacity={disabled ? 0.5 : 1}>
      {showInfosBlackListModal && (
        <ShowInfosBlackListModal
          isOpen={showInfosBlackListModal !== null}
          onClose={() => setShowInfosBlackListModal(null)}
          black_list={showInfosBlackListModal}
        />
      )}

      <Text fontSize="xs" opacity={0.5} mb="2">
        Informações do motorista:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="cpf"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="999.999.999-99"
                label="CPF"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
                isSearchable
                isLoading={loading}
                onSearch={() => search(value)}
                onBlur={() => {
                  if (value) {
                    search(value)
                  }
                }}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 6]}>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Nome Completo"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="birth"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Data de Nascimento"
                type="date"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="register_doc"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="RG"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 4]}>
          <Controller
            control={control}
            name="rg_dispatcher"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Órg. Ex RG"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 2]}>
          <Controller
            control={control}
            name="rg_uf"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                options={ufOptions}
                isRequired
                label="RG UF"
                onChange={onChange}
                value={value}
                isError={!!error?.message}
                errorMessage={error?.message}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="rg_dispatch_date"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Data de Emissão RG"
                type="date"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 8, 6]}>
          <Controller
            control={control}
            name="mother_name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Nome da Mãe"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 8, 6]}>
          <Controller
            control={control}
            name="father_name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Nome do Pai"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 4]}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="(99) 99999-9999"
                label="Telefone Principal"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 12, 8]}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Email"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
      </Grid>

      <Text fontSize="xs" opacity={0.5} mb="2" mt="4">
        CNH:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="driver_license"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="CNH"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 2]}>
          <Controller
            control={control}
            name="driver_license_category"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                options={CNH_OPTIONS}
                isRequired
                label="Categoria"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="driver_license_dispatch_date"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Data de Expedição"
                type="date"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="driver_license_first_dispatch_date"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Data da 1º CNH"
                type="date"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="driver_license_dispatcher"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Órgão Expedidor"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 2]}>
          <Controller
            control={control}
            name="driver_license_dispatcher_uf"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                options={ufOptions}
                isRequired
                label="UF"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="driver_license_validity"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Validade"
                type="date"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="driver_license_security_code"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Código de Segurança"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="driver_license_protocol_number"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Número do protocolo"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
      </Grid>

      <Text fontSize="xs" opacity={0.5} mb="2" mt="4">
        Informações de Endereço:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 2]}>
          <Controller
            control={control}
            name="address_zipcode"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <UIInput
                mask="99999-999"
                label="CEP"
                onChange={({ target }) => {
                  onChange(target.value)
                  searchAddress(target.value)
                }}
                value={value}
                isError={!!error?.message}
                errorMessage={error?.message}
                isLoading={loadingAddress}
                isSearchable
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 6]}>
          <Controller
            control={control}
            name="address"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Endereço"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 1]}>
          <Controller
            control={control}
            name="address_number"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Numero"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="address_neighborhood"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Bairro"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 6]}>
          <Controller
            control={control}
            name="address_city"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelectAsync
                label="Cidade"
                placeholder="Cidade"
                loadOptions={searchCitiesByName}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={option => {
                  setValue('address_state_uf', option?.uf || '')
                  onChange(option)
                }}
                value={value}
                isRequired
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 1]}>
          <Controller
            control={control}
            name="address_state_uf"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="UF"
                placeholder="UF"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
                isRequired
                isDisabled
              />
            )}
          />
        </GridItem>
      </Grid>

      <Text fontSize="xs" opacity={0.5} mb="2" mt="4">
        Informações de Contato:
      </Text>
      <Text>Pessoal</Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="name_contacts_ref_person_1"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Nome do contato 1"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="type_contacts_ref_person_1"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                label="Tipo de relação 1"
                options={PERSON_TYPES}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="phone_ref_person_1"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="(99) 99999-9999"
                label="Telefone pessoal 1"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="name_contacts_ref_person_2"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Nome do contato 2"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="type_contacts_ref_person_2"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                label="Tipo de relação 2"
                options={PERSON_TYPES}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="phone_ref_person_2"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="(99) 99999-9999"
                label="Telefone pessoal 2"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
      </Grid>
      <Text>Profissional:</Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="name_contacts_ref_professional_1"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Nome do contato 1"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="type_contacts_ref_professional_1"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                label="Tipo de relação 1"
                options={[{ label: 'Comercial', value: 'COMERCIAL' }]}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
                isDisabled
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="phone_ref_professional_1"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="(99) 99999-9999"
                label="Telefone profissional 1"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="name_contacts_ref_professional_2"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Nome do contato 2"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="type_contacts_ref_professional_2"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                label="Tipo de relação 1"
                options={[{ label: 'Comercial', value: 'COMERCIAL' }]}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
                isDisabled
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="phone_ref_professional_2"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="(99) 99999-9999"
                label="Telefone profissional 2"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export const mototistInitialValues = {
  cpf: '',
  name: '',
  birth: '',
  register_doc: '',
  rg_dispatch_date: '',
  rg_dispatcher: '',
  rg_uf: '',
  mother_name: '',
  father_name: '',
  email: '',
  phone: '',
  driver_license: '',
  driver_license_category: '',
  driver_license_dispatcher: '',
  driver_license_dispatch_date: '',
  driver_license_first_dispatch_date: '',
  driver_license_dispatcher_uf: '',
  driver_license_validity: '',
  driver_license_protocol_number: '',
  driver_license_security_code: '',
  address_zipcode: '',
  address: '',
  address_city: {
    label: '',
    value: '',
  },
  address_neighborhood: '',
  address_number: '',
  address_state_uf: '',
  name_contacts_ref_person_1: '',
  name_contacts_ref_person_2: '',
  name_contacts_ref_professional_1: '',
  name_contacts_ref_professional_2: '',
  phone_ref_person_1: '',
  phone_ref_person_2: '',
  phone_ref_professional_1: '',
  phone_ref_professional_2: '',
  type_contacts_ref_person_1: '',
  type_contacts_ref_person_2: '',
  type_contacts_ref_professional_1: '',
  type_contacts_ref_professional_2: '',
}
