import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  ColumnResizeMode,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TableControls, { tablecontrols, tableControlsProps } from './TableControls'
import TableHeader from './TableHeader'
import DataGridPagination from './TablePagination'
import TableRow from './TableRow'
import useTableColors from './useTableColors'

interface DataGridProps {
  data: any[]
  columns: ColumnDef<any>[]
  isLoading?: boolean
  isFetching?: boolean
  tableHeight?: string
  showPagination?: boolean
  renderLeftControls?: tableControlsProps['renderLeftControls']
  rowStyles?: (row: Row<any>) => {
    background?: string
    border?: string
    boxShadow?: string
  }
}
const DataGrid = ({
  data,
  columns,
  isLoading,
  isFetching,
  tableHeight,
  rowStyles = () => ({ background: '', border: '', boxShadow: '' }),
  renderLeftControls,
  showPagination = true,
}: DataGridProps): JSX.Element => {
  const { borderColor } = useTableColors()

  const dataMemo = useMemo(() => (data.length > 0 ? data : []), [data])
  const columnsMemo = useMemo(() => (columns.length > 0 ? columns : []), [columns])
  const [columnResizeMode] = useState<ColumnResizeMode>('onEnd')
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(
    window.localStorage.getItem('columnVisibility')
      ? JSON.parse(window.localStorage.getItem('columnVisibility') as string)
      : {},
  )

  const columnOrderOnStorage = () => {
    const columnOrder = window.localStorage.getItem('columnOrder')
    const datagridColumnOrder = columns.map(c => c.id as string)

    const hasNewColumnOde = datagridColumnOrder.some(column => !columnOrder?.includes(column))

    if (hasNewColumnOde) {
      return columns.map(c => c.id as string)
    }

    return JSON.parse(columnOrder as string)
  }

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(columnOrderOnStorage())
  const [columnSizing, setColumnSizing] = useState<Record<string, number>>(
    window.localStorage.getItem('columnSizing')
      ? JSON.parse(window.localStorage.getItem('columnSizing') as string)
      : {},
  )

  const [rowSelection, setRowSelection] = useState<string>(
    window.localStorage.getItem('rowSelection')
      ? JSON.parse(window.localStorage.getItem('rowSelection') as string)
      : '',
  )

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'freight_number',
      desc: true,
    },
  ])

  const [globalFilter, setGlobalFilter] = useState<string>('')
  const [tableControls, setTableControls] = useState<tablecontrols>({
    showFilters: true,
    showColumns: false,
    showGlobalFilter: false,
    showSorting: false,
    tvMode: false,
  })

  const handleTogleColumnsFilters = useCallback(() => {
    setTableControls(prev => ({ ...prev, showFilters: !prev.showFilters }))
  }, [])

  const handleRowToggle = useCallback((id: string) => {
    setRowSelection(prev => (prev ? (prev === id ? '' : id) : id))
  }, [])

  const table = useReactTable<any>({
    data: dataMemo,
    columns: columnsMemo,
    filterFns: {
      includesString: (row, columnId, value) => {
        const rowValue = String(row.getValue(columnId))
        return rowValue.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      },
      includesNumber: (row, columnId, value) => {
        const rowValue = Number(row.getValue(columnId))
        return rowValue === value
      },
    },
    globalFilterFn: 'auto',

    state: {
      columnFilters,
      columnVisibility,
      columnOrder,
      globalFilter,
      sorting,
      columnSizing,
      pagination: { pageIndex: 0, pageSize: 200 },
    },
    onSortingChange: prev => setSorting(prev),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableColumnFilters: tableControls.showFilters,
    columnResizeMode,
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    enableHiding: true,
  })

  const divRef = useRef<any>(null)

  const handleFullScreen = useCallback(() => {
    if (!divRef.current) return

    if (tableControls.tvMode) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if ((document as any).mozCancelFullScreen) {
        // Firefox
        ;(document as any).mozCancelFullScreen()
      } else if ((document as any).webkitExitFullscreen) {
        // Chrome, Safari and Opera
        ;(document as any).webkitExitFullscreen()
      } else if ((document as any).msExitFullscreen) {
        // IE/Edge
        ;(document as any).msExitFullscreen()
      }

      setTableControls(prev => ({ ...prev, tvMode: !prev.tvMode }))
      return
    }

    setTableControls(prev => ({ ...prev, tvMode: !prev.tvMode }))

    if (divRef.current.requestFullscreen) {
      divRef.current.requestFullscreen()
    } else if (divRef.current.mozRequestFullScreen) {
      // Firefox
      divRef.current.mozRequestFullScreen()
    } else if (divRef.current.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      divRef.current.webkitRequestFullscreen()
    } else if (divRef.current.msRequestFullscreen) {
      // IE/Edge
      divRef.current.msRequestFullscreen()
    }
  }, [tableControls])

  useEffect(() => {
    if (columnOrder && columnOrder.length > 0) {
      window.localStorage.setItem('columnOrder', JSON.stringify(columnOrder))
    }
    if (columnSizing && Object.keys(columnSizing).length > 0) {
      window.localStorage.setItem('columnSizing', JSON.stringify(columnSizing))
    }
    if (columnVisibility && Object.keys(columnVisibility).length > 0) {
      window.localStorage.setItem('columnVisibility', JSON.stringify(columnVisibility))
    }
    if (rowSelection && rowSelection.length > 0) {
      window.localStorage.setItem('rowSelection', JSON.stringify(rowSelection))
    }
  }, [columnSizing, columnOrder, columnVisibility, rowSelection])

  return (
    <Flex flexDirection="column" gridGap={2} ref={divRef}>
      <TableControls
        columnVisibility={columnVisibility}
        setColumnOrder={setColumnOrder}
        onGlobalFilterChange={setGlobalFilter}
        globalFilterValue={globalFilter}
        table={table}
        onTableToggleColumnsFilters={handleTogleColumnsFilters}
        tableControls={tableControls}
        isFetching={isFetching}
        renderLeftControls={renderLeftControls}
        onTvModeChange={handleFullScreen}
      />
      <Flex flexDirection="column" fontSize="smaller" flex={1}>
        <Box
          borderWidth="1px"
          borderTopRadius="lg"
          borderColor={borderColor}
          borderBottomWidth="0px"
          whiteSpace="nowrap"
          overflowX="auto"
          flex={1}
          {...(!tableControls.tvMode && tableHeight ? { maxH: tableHeight, minH: tableHeight } : {})}
        >
          {table.getHeaderGroups().map(headerGroup => (
            <TableHeader
              key={headerGroup.id}
              headerGroup={headerGroup}
              table={table}
              columnResizeMode={columnResizeMode}
            />
          ))}
          {isLoading && (
            <Flex p={4} justifyContent="center" flexDirection="column" alignItems="center" flex={1}>
              <Spinner size="sm" />
              <Text ml={2}>Carregando...</Text>
            </Flex>
          )}
          {data.length === 0 && !isLoading && (
            <Flex p={4} justifyContent="center" flexDirection="column" alignItems="center">
              <Text>Nenhum registro encontrado</Text>
            </Flex>
          )}

          {!isLoading &&
            table.getRowModel().rows.map((row, index) => {
              return (
                <TableRow
                  key={row.id}
                  row={row}
                  index={index}
                  rowSelection={row.original.id === rowSelection}
                  handleRowToggle={() => handleRowToggle(row.original.id)}
                  rowStyles={rowStyles(row)}
                />
              )
            })}
        </Box>
        {showPagination && <DataGridPagination table={table} />}
      </Flex>
    </Flex>
  )
}

export default DataGrid
