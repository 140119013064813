import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiArrowLeftLine, RiCheckboxCircleFill } from 'react-icons/ri'
import { TbRoute2 } from 'react-icons/tb'
import { Link, useParams } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { Layout } from '../../../layout'
import { useCheckAllPendencies } from '../../../services/endpoints/freights/checkPendencies'
import { useGetOneFreight } from '../../../services/endpoints/freights/getOneFreight'
import { useGetMotorist } from '../../../services/endpoints/motorists/getMotorist'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { addMoreOneParamToUrl } from '../../../services/utils/format-url/addMoreOneParamToUrl'
import { scrollToTop } from '../../../services/utils/scrollToTop'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  getPendencies,
  setAnttOwnerHasPendencies,
  setDriverLicenseHasPendencies,
  setFreight,
  setMotoristHasPendencies,
  setTrailerHasPendencies,
  setTrailerOwnerHasPendencies,
  setVehicleHasPendencies,
  setVehicleOwnerHasPendencies,
} from '../../../store/slices/freightPendenciesSlice'
import { TrailerCompleteForm } from '../../vehicles/form-vehicles/TrailerCompleteForm'
import { VehicleCompleteForm } from '../../vehicles/form-vehicles/VehicleCompleteForm'
import FreightPendenciesAlert from './alerts/FreightPendenciesAlert'
import { AnttOwnerForm } from './forms/antt/AnttOwnerForm'
import { DriverLicenseUploadForm } from './forms/DriverLicenseUploadForm'
import { MotoristForm } from './forms/MotoristForm'
import { TrailerOwnerForm } from './forms/Owners/TrailerOwnerForm'
import { VehicleOwnerForm } from './forms/Owners/VehicleOwnerForm'
import { SendToDatamex } from './forms/send-EmiteAi-Datamex/SendEmiteAi-Datamex'
import { SendToBuonny } from './forms/send-to-buonny/SendToBuonny'
import { colorTab, disabled } from './styles'

export interface Params {
  freight_id: string
}

export function FreightPendencies(): JSX.Element {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const {
    isLoading: isLoadingPendenciesRedux,
    has_pendencies,
    driverLicenseHasPendencies,
    motoristHasPendencies,
    vehicleOwnerHasPendencies,
    anttOwnerHasPendencies,
    trailerHasPendencies,
    trailerOwnerHasPendencies,
    vehicleHasPendencies,
    routePointsHasPendencies,
  } = useAppSelector(state => state.freightPendenciesSlice)

  const { motoristId } = useAppSelector(state => state.motoristSlice)
  const { vehicleId } = useAppSelector(state => state.vehicleSlice)

  const { freight_id } = useParams<Params>()
  const index = useQueryParams('tabIndex')
  let integration = useQueryParams('integration')
  if (!integration) {
    integration = 'buonny'
  }
  let motorist_id = useQueryParams('motorist_id')
  let vehicle_id = useQueryParams('vehicle_id')

  if (motoristId) motorist_id = motoristId

  const { data: freight, isLoading: isLoadingFreight } = useGetOneFreight({
    freight_id,
    relations: [
      'origin',
      'destination',
      'client',
      'vehicle',
      'trailer',
      'vehicle.owner',
      'vehicle.antt_owner',
      'trailer.owner',
      'motorist.driver_license',
    ],
  })

  const {
    data: motorist,
    isLoading: isLoadingMotorist,
    refetch: refetchMotorist,
  } = useGetMotorist(motorist_id)

  if (vehicleId) {
    vehicle_id = vehicleId
  } else if (motorist?.vehicles[0]?.id && motorist.vehicles.length === 1 && !vehicle_id) {
    vehicle_id = motorist.vehicles[0].id
    addMoreOneParamToUrl('vehicle_id', vehicle_id)
  }

  const { data: vehicle, isLoading: isLoadingVehicle } = useGetVehicle(vehicle_id)

  const {
    data: checkPendencies,
    isLoading: isLoadingPendencies,
    isFetching: isFetchingPendencies,
    isError: isErrorPendencies,
    error: errorPendencies,
  } = useCheckAllPendencies({
    freight_id,
    motorist_id,
    vehicle_id,
  })

  useEffect(() => {
    if (isErrorPendencies) {
      toastError({ toast, error: errorPendencies })
    }
  }, [isErrorPendencies, errorPendencies, toast])

  const [tabIndex, setTabIndex] = useState(() => {
    if (index) {
      return Number(index)
    }
    return 0
  })

  const encodeLocation = (location: any) => {
    const locationParams = {
      lat: location.lat,
      lng: location.lng,
      uf: location.state.uf,
      label: location.name,
      country: 'BRA',
      cityName: location.name,
    }
    return encodeURIComponent(JSON.stringify(locationParams))
  }

  const buildRoutePlannerUrl = () => {
    const originParams = encodeLocation(freight?.origin)
    const destinationParams = encodeLocation(freight?.destination)
    const oldUrl = localStorage.getItem('currentUrl')
    if (!oldUrl) return
    const url = new URL(oldUrl)
    const currentTabIndex = url.searchParams.get('tabIndex')
    if (currentTabIndex) {
      localStorage.removeItem('currentUrl')
      localStorage.setItem('currentUrl', window.location.href)
    }
    return (
      `/pricing/route-planner?freight_id=${freight?.id}` +
      `&freight_number=${freight?.freight_number}` +
      `&origin=${originParams}` +
      `&destination=${destinationParams}` +
      `&freight_status=${freight?.status}` +
      `&vehicle_plate=${vehicle?.license_plate || ''}` +
      `&isPendenciesPage=${true}`
    )
  }

  useEffect(() => {
    scrollToTop()
    if (tabIndex === 1) refetchMotorist()
    addMoreOneParamToUrl('tabIndex', tabIndex)
  }, [motorist_id, refetchMotorist, tabIndex])

  useEffect(() => {
    if (motorist) {
      dispatch(getPendencies(checkPendencies, freight, motorist))
    }
  }, [checkPendencies, dispatch, freight, motorist])

  useEffect(() => {
    if (vehicle && vehicle.trailers && vehicle.trailers.length === 0) {
      dispatch(setTrailerHasPendencies(true))
    }
  }, [dispatch, vehicle])
  useEffect(() => {
    if (freight) {
      dispatch(setFreight(freight))
    }
  }, [dispatch, freight])

  return (
    <Layout hasBackground>
      <Flex gridGap="4" alignItems="center" justifyContent="space-between">
        <Flex gridGap="2" alignItems="center">
          <Button
            as={Link}
            to={`/freights/show/${freight?.id}`}
            leftIcon={<Icon as={RiArrowLeftLine} fontSize="2xl" />}
          >
            Voltar para o frete
          </Button>
          <Text fontSize="lg" fontWeight="bold">
            Pendências do frete #
            {isLoadingPendencies || isLoadingFreight ? <Spinner ml={2} /> : freight?.freight_number}
          </Text>
        </Flex>
        <span>
          <Button
            onClick={() => {
              window.location.href = buildRoutePlannerUrl() ?? ''
            }}
            colorScheme="green"
            rightIcon={<Icon as={TbRoute2} />}
          >
            Roteirizador (Qualp)
          </Button>
          <Button
            ml={2}
            variant="outline"
            size="md"
            colorScheme="blue"
            onClick={() => {
              window.location.href = `/freights/show/${freight_id}/pendencies?motorist_id=${motorist_id}&vehicle_id=${vehicle_id}&integration=datamex`
            }}
          >
            Fluxo novo
          </Button>
        </span>
      </Flex>

      <Flex align="center" justify="center" direction="column">
        <Divider my="6" />

        {(isLoadingPendencies || isLoadingFreight) && !checkPendencies ? (
          <Flex align="center" justify="center">
            <Spinner size="lg" />
          </Flex>
        ) : freight ? (
          <Tabs
            index={index && tabIndex < 0 ? Number(index) : tabIndex}
            variant="enclosed-colored"
            isFitted
            colorScheme="orange"
            onChange={setTabIndex}
            maxW={969}
          >
            <TabList d={['table', 'table', 'flex']} overflowX="auto" overflowY="hidden" w="100%">
              <Tab color={colorTab(driverLicenseHasPendencies)} onClick={() => setTabIndex(0)}>
                {!driverLicenseHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                CNH
              </Tab>
              <Tab color={colorTab(motoristHasPendencies)} onClick={() => setTabIndex(1)}>
                {!motoristHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                Motorista
              </Tab>
              <Tab color={colorTab(vehicleHasPendencies)}>
                {!vehicleHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                Veículo
              </Tab>
              {vehicle?.vehicle_category?.has_trailer && vehicle.trailers.length > 0
                ? vehicle.trailers.map((_trailer, idx) => (
                    <Tab
                      color={colorTab(trailerHasPendencies)}
                      isDisabled={vehicleHasPendencies}
                      _disabled={disabled}
                      minW="max-content"
                    >
                      {!trailerHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                      Reboque {idx + 1}
                    </Tab>
                  ))
                : vehicle?.vehicle_category?.has_trailer && (
                    <Tab
                      color={colorTab(trailerHasPendencies)}
                      isDisabled={vehicleHasPendencies}
                      _disabled={disabled}
                      minW="max-content"
                    >
                      {!trailerHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                      Reboque
                    </Tab>
                  )}

              {vehicle?.vehicle_category?.has_trailer ? (
                <Tab
                  color={colorTab(vehicleOwnerHasPendencies)}
                  isDisabled={trailerHasPendencies}
                  _disabled={disabled}
                >
                  {!vehicleOwnerHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                  Proprietário Veículo
                </Tab>
              ) : (
                <Tab
                  color={colorTab(vehicleOwnerHasPendencies)}
                  isDisabled={vehicleHasPendencies}
                  _disabled={disabled}
                >
                  {!vehicleOwnerHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                  Proprietário Veículo
                </Tab>
              )}

              {vehicle?.vehicle_category?.has_trailer && vehicle.trailers.length > 0
                ? vehicle.trailers.map((_trailer, idx) => (
                    <Tab
                      color={colorTab(trailerOwnerHasPendencies)}
                      isDisabled={vehicleOwnerHasPendencies}
                      _disabled={disabled}
                    >
                      {!trailerOwnerHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                      Proprietário Reboque {idx + 1}
                    </Tab>
                  ))
                : vehicle?.vehicle_category?.has_trailer && (
                    <Tab
                      color={colorTab(trailerOwnerHasPendencies)}
                      isDisabled={vehicleOwnerHasPendencies}
                      _disabled={disabled}
                    >
                      {!trailerOwnerHasPendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                      Proprietário Reboque
                    </Tab>
                  )}

              {vehicle && (
                <Tab
                  color={colorTab(anttOwnerHasPendencies || routePointsHasPendencies)}
                  isDisabled={vehicleHasPendencies}
                  _disabled={disabled}
                >
                  {!anttOwnerHasPendencies && !routePointsHasPendencies && (
                    <Icon mr="1" as={RiCheckboxCircleFill} />
                  )}
                  Antt
                </Tab>
              )}

              <Tab color={colorTab(has_pendencies)}>
                {!has_pendencies && <Icon mr="1" as={RiCheckboxCircleFill} />}
                {integration === 'buonny' || !integration ? 'Buonny' : 'Datamex'}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p="0" pt="6">
                <DriverLicenseUploadForm
                  motorist={motorist}
                  refetch={() => {
                    queryClient.invalidateQueries('motorist')
                    queryClient.invalidateQueries('vehicle')
                    queryClient.invalidateQueries('check-pendencies')
                  }}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>
              <TabPanel p="0" pt="6">
                <MotoristForm
                  motorist={motorist}
                  tabIndex={tabIndex}
                  refetch={() => {
                    queryClient.invalidateQueries('motorist')
                    queryClient.invalidateQueries('vehicle')
                    queryClient.invalidateQueries('check-pendencies')
                    dispatch(setDriverLicenseHasPendencies(false))
                    dispatch(setMotoristHasPendencies(false))
                  }}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>
              <TabPanel p="0" pt="6">
                <VehicleCompleteForm
                  freight_id={freight_id}
                  vehicle={vehicle}
                  trailer={vehicle?.trailers[0]}
                  refetch={() => {
                    queryClient.invalidateQueries('motorist')
                    queryClient.invalidateQueries('vehicle')
                    queryClient.invalidateQueries('check-pendencies')
                    dispatch(setVehicleHasPendencies(false))
                  }}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>

              {vehicle?.vehicle_category?.has_trailer && vehicle.trailers.length > 0
                ? vehicle.trailers.map(trailer => (
                    <TabPanel p="0" pt="6">
                      <TrailerCompleteForm
                        vehicle={vehicle}
                        trailer={trailer}
                        refetch={() => {
                          queryClient.invalidateQueries('motorist')
                          queryClient.invalidateQueries('vehicle')
                          queryClient.invalidateQueries('check-pendencies')
                          dispatch(setTrailerHasPendencies(false))
                        }}
                        setTabIndex={setTabIndex}
                      />
                    </TabPanel>
                  ))
                : vehicle?.vehicle_category?.has_trailer && (
                    <TabPanel p="0" pt="6">
                      <TrailerCompleteForm
                        vehicle={vehicle}
                        refetch={() => {
                          queryClient.invalidateQueries('motorist')
                          queryClient.invalidateQueries('vehicle')
                          queryClient.invalidateQueries('check-pendencies')
                          dispatch(setTrailerHasPendencies(false))
                        }}
                        setTabIndex={setTabIndex}
                      />
                    </TabPanel>
                  )}

              {motorist && (
                <TabPanel p="0" pt="6">
                  <VehicleOwnerForm
                    vehicle={vehicle}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    refetch={() => {
                      queryClient.invalidateQueries('motorist')
                      queryClient.invalidateQueries('vehicle')
                      queryClient.invalidateQueries('check-pendencies')
                      dispatch(setVehicleOwnerHasPendencies(false))
                    }}
                    freight={freight}
                  />
                </TabPanel>
              )}

              {vehicle &&
                vehicle.vehicle_category?.has_trailer &&
                vehicle.trailers.length > 0 &&
                vehicle.trailers.map(trailer => (
                  <TabPanel p="0" pt="6">
                    <TrailerOwnerForm
                      trailer={trailer}
                      vehicle={vehicle}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      refetch={() => {
                        queryClient.invalidateQueries('freight')
                        queryClient.invalidateQueries('motorist')
                        queryClient.invalidateQueries('vehicle')
                        queryClient.invalidateQueries('check-pendencies')
                        dispatch(setTrailerOwnerHasPendencies(false))
                      }}
                      freight={freight}
                    />
                  </TabPanel>
                ))}

              {vehicle && motorist && (
                <TabPanel p="0" pt="6">
                  <AnttOwnerForm
                    vehicle={vehicle}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    refetch={() => {
                      dispatch(setAnttOwnerHasPendencies(false))
                      queryClient.invalidateQueries('motorist')
                      queryClient.invalidateQueries('vehicle')
                      queryClient.invalidateQueries('check-pendencies')
                    }}
                    freight={freight}
                  />
                </TabPanel>
              )}

              <TabPanel p="0" pt="6">
                {has_pendencies && !isFetchingPendencies ? (
                  <FreightPendenciesAlert pendencies={checkPendencies} />
                ) : isLoadingFreight ||
                  isLoadingPendencies ||
                  isLoadingPendenciesRedux ||
                  isLoadingVehicle ||
                  isLoadingMotorist ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : (
                  !has_pendencies && (
                    <>
                      {integration === 'datamex' && <SendToDatamex freight={freight} />}
                      {integration === 'buonny' && motorist && vehicle && (
                        <SendToBuonny
                          tabIndex={tabIndex}
                          freight={freight}
                          motorist={motorist}
                          vehicle={vehicle}
                        />
                      )}
                    </>
                  )
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          isErrorPendencies && (
            <Alert variant="subtle" status="error" borderRadius="md">
              <AlertIcon />
              <AlertTitle mr={2} fontSize="lg">
                Erro ao carregar pendências!
              </AlertTitle>
            </Alert>
          )
        )}
      </Flex>
    </Layout>
  )
}
