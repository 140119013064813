import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { RiAlertFill } from 'react-icons/ri'
import { CACHE_VERSION_KEY, useGetVersion } from '../../services/endpoints/get-version/getVersion'

const ModalVersion = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const updateButton = useDisclosure()
  const [version, setVersion] = useState<string>('')

  const localVersion = window.localStorage.getItem(CACHE_VERSION_KEY) || '0'

  useGetVersion({
    onSuccess: currentVersion => {
      setVersion(currentVersion)
      if (currentVersion !== localVersion) {
        onOpen()
      }
    },
  })

  const handleUpdate = () => {
    window.localStorage.setItem(CACHE_VERSION_KEY, version.toString())
    window.location.reload()
  }

  const handleClose = () => {
    onClose()
    updateButton.onOpen()
  }

  return (
    <>
      {version !== localVersion && updateButton.isOpen && (
        <Box
          cursor="pointer"
          bg="red.200"
          p="2"
          onClick={onOpen}
          color="red.500"
          position="fixed"
          top="0"
          zIndex="1000"
        >
          ATUALIZAR VERSÃO
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p="6" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <RiAlertFill color="red.500" fontSize="64" />
            <Heading size="lg" textAlign="center" mt="4">
              Parece que esta versão esta desatualizada
            </Heading>
            <Text textAlign="center" mt="4">
              Por favor, atualize para a versão mais recente
            </Text>

            <Flex>
              <Button colorScheme="red" size="lg" mt="4" onClick={handleUpdate}>
                Atualizar
              </Button>
              <Button colorScheme="blue" size="lg" mt="4" ml="4" onClick={handleClose}>
                Cancelar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalVersion
