export const displayAdaptable = [
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'table',
]

export const boxWidth = ['auto', 'auto', 'auto', 'auto', 1070, 1100, 'auto']
