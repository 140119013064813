import {
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCheckLine } from 'react-icons/ri'
import { Input } from '../../../../components/form/Input'
import { Select } from '../../../../components/form/Select'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../../config/error/toastError'
import { usePendenciesOCRContext } from '../../../../contexts/PendenciesOCRContext'
import { apiServer } from '../../../../services/api'
import { FormattedMotorist } from '../../../../services/endpoints/motorists/getMotorist'
import { useCreateAttachmentToMotorist } from '../../../../services/endpoints/motorists/motorist-attachments/createAttachment'
import { MotoristType, ResponseApiType } from '../../../../services/types'
import BlackListType from '../../../../services/types/BlackListType'
import { formatDateForInitialValue } from '../../../../services/utils/dates/formatDateForInitialValue'
import { addMoreOneParamToUrl } from '../../../../services/utils/format-url/addMoreOneParamToUrl'
import { ufOptions } from '../../../../services/utils/ufOptions'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { setMotoristId } from '../../../../store/slices/motoristSlice'
import { setIsNewImage } from '../../../../store/slices/ocrCnhSlice'
import { handleCpfIsBlackListed } from '../../../black-list/check-document-exists/handleCpfIsBlackListed'
import { ShowInfosBlackListModal } from '../../../black-list/modals/ShowInfosBlackListModal'
import { MotoristCompleteForm } from '../../../motorists/MotoristCompleteForm'
import { motoristFormValidation } from '../validations'

interface MotoristCompleteFormProps {
  motorist?: FormattedMotorist
  refetch?: () => void
  setTabIndex?: React.Dispatch<React.SetStateAction<number>>
  tabIndex: number
}

export interface MotoristContacts {
  motorist_id?: string
  name?: string
  phone?: string
  type?: string
}

interface UpdateMotoristFormData {
  phone: string
  address_city_id: AutocompleteOption
  complement: string
  district: string
  address: string
  zipcode: string
  birth: string
  birth_city: AutocompleteOption
  rg_dispatch_date: string
  rg_uf: string
  rg: string
  mother_name: string
  father_name: string
  name: string
  cpf: string
  address_number: number
  is_completed?: number
  gender?: string
  rntrc: string
  birth_state: string
  marital_status?: string
  school_degree?: string
  rg_dispatcher?: string
  pis?: string
  state?: string
  collects_annual_iss?: boolean
  bank?: AutocompleteOption
  account_type?: string
  agency?: string
  account?: string
  motorist_contact_id_ref_person_1: string
  motorist_contact_id_ref_person_2: string
  motorist_contact_id_ref_professional_1: string
  motorist_contact_id_ref_professional_2: string
  name_contacts_ref_person_1: string
  name_contacts_ref_person_2: string
  name_contacts_ref_professional_1: string
  name_contacts_ref_professional_2: string
  phone_ref_person_1: string
  phone_ref_person_2: string
  phone_ref_professional_1: string
  phone_ref_professional_2: string
  type_contacts_ref_person_1: string
  type_contacts_ref_person_2: string
  type_contacts_ref_professional_1: string
  type_contacts_ref_professional_2: string
  driver_license: {
    insurance_number: string
    protocol_number: string
    validity: string
    first_dispatch_date: string
    dispatch_date: string
    dispatcher: string
    category: string
    register_number: string
    dispatcher_uf: string
  }
}

export function MotoristForm({
  motorist,
  refetch,
  setTabIndex,
  tabIndex,
}: MotoristCompleteFormProps): JSX.Element {
  const createAttachment = useCreateAttachmentToMotorist({ onSuccess: () => refetch && refetch() })
  const { driverLicenseFile } = useAppSelector(state => state.ocrCnhSlice)
  const dispatch = useAppDispatch()
  const { resultOCR } = usePendenciesOCRContext()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [cpfInBlackList, setCpfInBlackList] = useState<BlackListType>()
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(motoristFormValidation),
  })
  const { errors } = formState

  const handleIncludeMotorist = useCallback<SubmitHandler<UpdateMotoristFormData>>(
    async data => {
      let motorist_id
      let attachmentId
      const { driver_license, bank, ...motoristData } = data

      if (motorist) {
        addMoreOneParamToUrl('motorist_id', motorist.id)
        motorist_id = motorist.id
        try {
          await apiServer.put(`/update-motorist/${motorist_id}`, {
            ...motoristData,
            bank: bank?.label || undefined,
            address_city_id: motoristData.address_city_id.value,
            name_contacts_ref_person_1: undefined,
            name_contacts_ref_person_2: undefined,
            name_contacts_ref_professional_1: undefined,
            name_contacts_ref_professional_2: undefined,
            phone_ref_person_1: undefined,
            phone_ref_person_2: undefined,
            phone_ref_professional_1: undefined,
            phone_ref_professional_2: undefined,
            type_contacts_ref_person_1: undefined,
            type_contacts_ref_person_2: undefined,
            type_contacts_ref_professional_1: undefined,
            type_contacts_ref_professional_2: undefined,
            driver_license: {
              ...driver_license,
            },
            motorist_contacts: [
              {
                id: data.motorist_contact_id_ref_person_1,
                motorist_id,
                name: data.name_contacts_ref_person_1,
                phone: data.phone_ref_person_1,
                type: data.type_contacts_ref_person_1,
              },
              {
                id: data.motorist_contact_id_ref_person_2,
                motorist_id,
                name: data.name_contacts_ref_person_2,
                phone: data.phone_ref_person_2,
                type: data.type_contacts_ref_person_2,
              },
              {
                id: data.motorist_contact_id_ref_professional_1,
                motorist_id,
                name: data.name_contacts_ref_professional_1,
                phone: data.phone_ref_professional_1,
                type: data.type_contacts_ref_professional_1,
              },
              {
                id: data.motorist_contact_id_ref_professional_2,
                motorist_id,
                name: data.name_contacts_ref_professional_2,
                phone: data.phone_ref_professional_2,
                type: data.type_contacts_ref_professional_2,
              },
            ],
          })

          toast({
            status: 'success',
            title: 'Motorista atualizado com sucesso!',
            position: 'top-right',
            duration: 1000 * 8,
            isClosable: true,
          })

          if (motorist.attachments && motorist.attachments.length > 0) {
            const attach = motorist.attachments.find(item => item.type === 'cnh')
            if (attach) {
              attachmentId = attach.id
            }
          }

          if (!attachmentId) {
            const { data: response, status } = await createAttachment.mutateAsync({
              attachment_file: driverLicenseFile,
              motorist_id,
              name: 'CNH do motorista',
              type: 'cnh',
            })

            if (status === 200) {
              toast({
                status: 'success',
                title: 'CNH anexada ao motorista com sucesso!',
                position: 'top-right',
                duration: 1000 * 8,
                isClosable: true,
              })
            }

            const newAttach = response.data
            attachmentId = newAttach.id
          }

          if (refetch) refetch()
          if (setTabIndex) setTabIndex(2)
          dispatch(setIsNewImage(false))
        } catch (error) {
          toastError({ toast, error })
        }
      } else
        try {
          const { data: response } = await apiServer.post<ResponseApiType<MotoristType>>(`/create-motorist`, {
            ...motoristData,
            bank: bank?.label || undefined,
            address_city_id: motoristData.address_city_id.value,
            driver_license: {
              ...driver_license,
            },
            motorist_contacts: [
              {
                name: data.name_contacts_ref_person_1,
                phone: data.phone_ref_person_1,
                type: data.type_contacts_ref_person_1,
              },
              {
                name: data.name_contacts_ref_person_2,
                phone: data.phone_ref_person_2,
                type: data.type_contacts_ref_person_2,
              },
              {
                name: data.name_contacts_ref_professional_1,
                phone: data.phone_ref_professional_1,
                type: data.type_contacts_ref_professional_1,
              },
              {
                name: data.name_contacts_ref_professional_2,
                phone: data.phone_ref_professional_2,
                type: data.type_contacts_ref_professional_2,
              },
            ],
          })
          const newMotorist = response.data
          addMoreOneParamToUrl('motorist_id', newMotorist.id)
          dispatch(setMotoristId(newMotorist.id))
          motorist_id = newMotorist.id

          toast({
            status: 'success',
            title: 'Motorista cadastrado com sucesso!',
            position: 'top-right',
            duration: 1000 * 8,
            isClosable: true,
          })

          if (motorist_id) {
            const { data: responseAttach, status } = await createAttachment.mutateAsync({
              attachment_file: driverLicenseFile,
              motorist_id,
              name: 'CNH do motorista',
              type: 'cnh',
            })
            if (status === 200) {
              toast({
                status: 'success',
                title: 'CNH anexada ao motorista com sucesso!',
                position: 'top-right',
                duration: 1000 * 8,
                isClosable: true,
              })
            }
            const attach = responseAttach.data
            attachmentId = attach.id
            dispatch(setMotoristId(motorist_id))
            addMoreOneParamToUrl('motorist_id', motorist_id)
            if (refetch) refetch()
            if (setTabIndex) setTabIndex(2)
          }
        } catch (error) {
          toastError({
            toast,
            error,
          })
        }
    },
    [createAttachment, dispatch, driverLicenseFile, motorist, refetch, setTabIndex, toast],
  )

  const CpfRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (tabIndex === 1) {
      const handleCpfIsBlackList = async () => {
        try {
          if (CpfRef.current) {
            const { value } = CpfRef.current
            try {
              const data = await handleCpfIsBlackListed(value || motorist?.cpf || resultOCR?.cpf)
              setCpfInBlackList(data)
              onOpen()
            } catch {
              setCpfInBlackList(undefined)
            }
          }
        } catch (error) {
          toastError({ toast, error })
        }
      }
      handleCpfIsBlackList()
    }
  }, [motorist?.cpf, onOpen, resultOCR?.cpf, tabIndex, toast])

  return (
    <form onSubmit={handleSubmit(handleIncludeMotorist)} noValidate>
      <MotoristCompleteForm
        CpfRef={CpfRef}
        formState={formState}
        setValue={setValue}
        initialData={motorist}
        isEdit
        onOpen={onOpen}
        setCpfInBlackList={setCpfInBlackList}
      />

      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={12} my="6">
          <Divider />
        </GridItem>

        <GridItem colSpan={12}>
          <Heading size="md" fontWeight="bold">
            CNH
          </Heading>
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="driver_license.register_number"
            label="Número CNH"
            setValue={setValue}
            initialValue={resultOCR?.registration || motorist?.driver_license?.register_number}
            error={errors.driver_license?.register_number}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 1]}>
          <Select
            name="driver_license.category"
            label="Categoria"
            setValue={setValue}
            options={[
              { label: '', value: '' },
              { label: 'A', value: 'A' },
              { label: 'AB', value: 'AB' },
              { label: 'AC', value: 'AC' },
              { label: 'AD', value: 'AD' },
              { label: 'AE', value: 'AE' },
              { label: 'B', value: 'B' },
              { label: 'C', value: 'C' },
              { label: 'D', value: 'D' },
              { label: 'E', value: 'E' },
            ]}
            error={errors.driver_license?.category}
            initialValue={resultOCR?.category || motorist?.driver_license?.category}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="driver_license.dispatch_date"
            type="date"
            label="Data de expedição"
            setValue={setValue}
            initialValue={
              formatDateForInitialValue(motorist?.driver_license?.dispatch_date) ||
              formatDateForInitialValue(resultOCR?.emissionDate)
            }
            error={errors.driver_license?.dispatch_date}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="driver_license.first_dispatch_date"
            type="date"
            label="Data Primeira CNH"
            setValue={setValue}
            initialValue={
              formatDateForInitialValue(motorist?.driver_license?.first_dispatch_date) ||
              formatDateForInitialValue(resultOCR?.firstLicenseDate)
            }
            error={errors.driver_license?.first_dispatch_date}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Input
            name="driver_license.dispatcher"
            label="Órgão expeditor"
            setValue={setValue}
            initialValue={resultOCR?.dispatcher || motorist?.driver_license?.dispatcher}
            error={errors.driver_license?.dispatcher}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Select
            name="driver_license.dispatcher_uf"
            label="UF"
            setValue={setValue}
            options={ufOptions}
            initialValue={resultOCR?.dispatcherUf || motorist?.driver_license?.dispatcher_uf}
            error={errors.driver_license?.dispatcher_uf}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="driver_license.validity"
            type="date"
            label="Validade"
            setValue={setValue}
            initialValue={
              motorist?.driver_license?.validity
                ? formatDateForInitialValue(motorist.driver_license.validity)
                : formatDateForInitialValue(resultOCR?.validity)
            }
            error={errors.driver_license?.validity}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="driver_license.insurance_number"
            label="Código de Segurança"
            setValue={setValue}
            initialValue={motorist?.driver_license?.insurance_number || resultOCR?.insuranceNumber}
            error={errors.driver_license?.insurance_number}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="driver_license.protocol_number"
            label="Número do protocolo"
            setValue={setValue}
            initialValue={resultOCR?.mirror || motorist?.driver_license?.protocol_number}
            error={errors.driver_license?.protocol_number}
            isRequired
          />
        </GridItem>
      </Grid>

      {cpfInBlackList && (
        <ShowInfosBlackListModal isOpen={isOpen} onClose={onClose} black_list={cpfInBlackList} />
      )}

      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button
          type="submit"
          rightIcon={<Icon as={RiCheckLine} />}
          colorScheme="green"
          isDisabled={!!cpfInBlackList}
          isLoading={formState.isSubmitting}
        >
          Salvar e avançar
        </Button>
      </HStack>
    </form>
  )
}
