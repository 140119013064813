import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  SelectProps,
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'

type SelectOption = {
  label: string
  value: string
  disabled?: boolean
  [key: string]: any
}

type UISelectProps = SelectProps & {
  label?: string
  isError?: boolean
  errorMessage?: string
  helperText?: string
  isRequired?: boolean
  options: SelectOption[]
}

export const UISelect = forwardRef<HTMLSelectElement, UISelectProps>(
  ({ helperText, isRequired, isError, errorMessage, options, ...props }, ref) => {
    return (
      <FormControl isInvalid={isError} isRequired={isRequired}>
        <FormLabel fontSize="14" mb="1.5">
          {props.label}
        </FormLabel>
        <Select ref={ref} {...props}>
          {options.map(option => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    )
  },
)
