import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react'
import { TfiTruck } from 'react-icons/tfi'
import { BiFileFind } from 'react-icons/bi'
import { BsCurrencyDollar, BsReceipt } from 'react-icons/bs'
import { FaClipboardList, FaFileContract, FaRegMoneyBillAlt, FaTrailer } from 'react-icons/fa'
import { FaTruckArrowRight } from 'react-icons/fa6'
import { HiDocumentCheck, HiUserGroup } from 'react-icons/hi2'
import { IoPush } from 'react-icons/io5'
import { MdGpsFixed, MdOutlineAttachMoney } from 'react-icons/md'
import {
  RiBarChartFill,
  RiBriefcase2Fill,
  RiBriefcaseLine,
  RiCalculatorLine,
  RiContactsLine,
  RiCurrencyLine,
  RiDashboardLine,
  RiDoorLockBoxLine,
  RiFileList3Line,
  RiGroupFill,
  RiGroupLine,
  RiLineChartLine,
  RiMapPinAddLine,
  RiPinDistanceLine,
  RiSearchLine,
  RiTruckLine,
  RiUser2Fill,
  RiUserAddLine,
  RiUserStarLine,
} from 'react-icons/ri'
import { TbMapSearch, TbRoute2 } from 'react-icons/tb'
import { GiReceiveMoney } from 'react-icons/gi'
import { Ability } from '../../components/ability'
import { useAppDispatch, useAppSelector } from '../../store'
import { setIndex } from '../../store/slices/sideBarSlice'
import { NavLink } from './NavLink'

export function SidebarNav(): JSX.Element {
  const { index } = useAppSelector(s => s.sideBarSlice)
  const dispatch = useAppDispatch()

  return (
    <Stack spacing="12" align="flex-start" flex="1">
      <Accordion defaultIndex={[1, index]} allowMultiple allowToggle minWidth={230}>
        {/* GERAL 0 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(0))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              GERAL
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel as={Flex} flexDirection="column" gridGap={1} pl={5}>
            <NavLink to="/dashboard" icon={RiDashboardLine}>
              Dashboard
            </NavLink>

            <Ability module="account" action="list-user">
              <NavLink to="/users" icon={RiContactsLine}>
                Usuários
              </NavLink>
            </Ability>

            <Ability module="authorization" action="update-permissions-by-roles">
              <NavLink to="/permissions" icon={RiDoorLockBoxLine}>
                Permissões
              </NavLink>
            </Ability>
          </AccordionPanel>
        </AccordionItem>

        {/* FRETES 1 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(1))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              FRETES
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel as={Flex} flexDirection="column" gridGap={1} pl={5}>
            <Ability module="operation" action="list-freight">
              <NavLink to="/freights" icon={RiPinDistanceLine} shouldMatchExactPath>
                Lista de fretes
              </NavLink>
            </Ability>

            <Ability module="operation" action="list-freight-in-progress">
              <NavLink to="/freights/freights-in-progress" icon={MdGpsFixed} shouldMatchExactPath>
                Monitoramento
              </NavLink>
            </Ability>

            <Ability module="operation" action="create-freight">
              <NavLink to="/freights/create" icon={RiMapPinAddLine}>
                Cadastrar frete
              </NavLink>
            </Ability>

            <Ability module="operation" action="calc-cte-value">
              <NavLink to="/freights/invoicing" icon={BsCurrencyDollar}>
                Monitor
              </NavLink>
            </Ability>

            <Ability module="operation" action="list-freight-to-operation">
              <NavLink to="/freights/operation" icon={RiBarChartFill}>
                Status Operação
              </NavLink>
            </Ability>
            <Ability module="operation" action="list-freight-to-operation">
              <NavLink to="/freights/metrics" icon={RiBarChartFill}>
                Métricas
              </NavLink>
            </Ability>
          </AccordionPanel>
        </AccordionItem>

        {/* PRICING 2 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(2))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              PRICING
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel as={Flex} flexDirection="column" gridGap={1} pl={5}>
            <Ability module="operation" action="list-quotation">
              <NavLink to="/freights/kanban-quotations" shouldMatchExactPath icon={RiCurrencyLine}>
                Lista de cotações
              </NavLink>
            </Ability>

            <Ability module="operation" action="create-quotation">
              <NavLink to="/quotations/create" icon={RiFileList3Line}>
                Cadastrar cotação
              </NavLink>
            </Ability>

            <Ability module="operation" action="list-route-prices">
              <NavLink to="/freights/history-prices-quotations" icon={RiFileList3Line}>
                Histórico de cotações
              </NavLink>
            </Ability>

            <Ability module="operation" action="route-analysys">
              <NavLink to="/pricing/route-analysis" icon={TbMapSearch} shouldMatchExactPath>
                Análise de Rota
              </NavLink>
            </Ability>

            <NavLink icon={RiCalculatorLine} to="/freights/quotation">
              Calculadora
            </NavLink>
            <NavLink to="/pricing/route-planner" icon={TbRoute2} shouldMatchExactPath>
              Roteirizador (Qualp)
            </NavLink>
          </AccordionPanel>
        </AccordionItem>

        {/* MOTORISTAS 3 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(3))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              MOTORISTAS
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel as={Flex} flexDirection="column" gridGap={1} pl={5}>
            <Ability module="people" action="list-motorists">
              <NavLink to="/motorists" icon={RiGroupLine} shouldMatchExactPath>
                Lista de motoristas
              </NavLink>
            </Ability>

            <Ability module="people" action="list-motorists">
              <NavLink to="/motorists/spotx" icon={RiUserStarLine} shouldMatchExactPath>
                Motoristas SpotX
              </NavLink>
            </Ability>

            <Ability module="people" action="create-motorist">
              <NavLink to="/motorists/create" icon={RiUserAddLine}>
                Cadastrar motorista
              </NavLink>
            </Ability>
          </AccordionPanel>
        </AccordionItem>

        {/* VEÍCULOS 4 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(4))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              VEÍCULOS
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel as={Flex} flexDirection="column" gridGap={1} pl={5}>
            <Ability module="people" action="list-owner">
              <NavLink to="/owners" icon={RiGroupFill} shouldMatchExactPath>
                Lista de proprietários
              </NavLink>
            </Ability>

            <Ability module="people" action="create-owner">
              <NavLink to="/owners/create" icon={RiUser2Fill} shouldMatchExactPath>
                Cadastrar proprietário
              </NavLink>
            </Ability>

            <Ability module="operation" action="invert-vehicle-registrations">
              <NavLink to="/vehicles/invert-registrations" icon={TfiTruck} shouldMatchExactPath>
                Inverter cadastro
              </NavLink>
            </Ability>

            <Ability module="operation" action="list-vehicle">
              <NavLink to="/vehicles" icon={RiTruckLine} shouldMatchExactPath>
                Lista de veículos
              </NavLink>
            </Ability>

            <Ability module="operation" action="list-trailer">
              <NavLink to="/trailers" icon={FaTrailer} shouldMatchExactPath>
                Lista de reboques
              </NavLink>
            </Ability>
          </AccordionPanel>
        </AccordionItem>

        {/* CLIENTES 5 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(5))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              CLIENTES
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel as={Flex} flexDirection="column" gridGap={1} pl={5}>
            <Ability module="people" action="list-client">
              <NavLink to="/clients" icon={RiBriefcaseLine} shouldMatchExactPath>
                Lista de clientes
              </NavLink>
            </Ability>

            <Ability module="people" action="create-client">
              <NavLink to="/clients/create" icon={RiBriefcase2Fill}>
                Cadastrar cliente
              </NavLink>
            </Ability>

            <Ability module="people" action="list-client-contacts">
              <NavLink to="/clients/requesters" icon={RiGroupFill} shouldMatchExactPath>
                Lista de solicitantes
              </NavLink>
            </Ability>

            <Ability module="people" action="list-client-business-group">
              <NavLink to="/clients/business-group" icon={HiUserGroup} shouldMatchExactPath>
                Grupos empresariais
              </NavLink>
            </Ability>

            <Ability module="people" action="list-client-monthly-nps">
              <NavLink to="/clients/client-monthly-nps" icon={RiLineChartLine}>
                Relatório de NPS
              </NavLink>
            </Ability>
          </AccordionPanel>
        </AccordionItem>

        {/* DOCUMENTS 6 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(6))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              DOCUMENTOS
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel as={Flex} flexDirection="column" gridGap={1} pl={5}>
            <Ability module="operation" action="calc-cte-value">
              <NavLink to="/documents-cte-list" icon={FaClipboardList} shouldMatchExactPath>
                CTE
              </NavLink>
            </Ability>

            <Ability module="financial" action="list-ciots">
              <NavLink to="/documents-ciot-list" icon={HiDocumentCheck} shouldMatchExactPath>
                CIOT
              </NavLink>
            </Ability>

            <Ability module="operation" action="list-formatted-stubs-by-freight">
              <NavLink to="/stubs" icon={BsReceipt} shouldMatchExactPath>
                Canhotos
              </NavLink>
            </Ability>

            <Ability module="operation" action="list-ctes">
              <NavLink to="/documents-cte-reports" icon={RiLineChartLine} shouldMatchExactPath>
                Relatório de vendas
              </NavLink>
            </Ability>

            <NavLink to="/documents/freight-contracts" icon={FaFileContract} shouldMatchExactPath>
              Contratos de frete
            </NavLink>

            <Ability module="financial" action="get-freights-tolls">
              <NavLink to="/documents/freights-toll-status" icon={FaTruckArrowRight} shouldMatchExactPath>
                Pedágio
              </NavLink>
            </Ability>

            <NavLink to="/documents/rntrc-consultation" icon={RiSearchLine} shouldMatchExactPath>
              Consulta RNTRC
            </NavLink>
            <NavLink to="/documents/pis-consultation" icon={RiSearchLine} shouldMatchExactPath>
              Consulta PIS
            </NavLink>
          </AccordionPanel>
        </AccordionItem>

        {/* FINANCEIRO 7 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(7))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              FINANCEIRO
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={5} pl={5} as={Flex} flexDirection="column" gridGap={2}>
            <Ability module="financial" action="list-freight-expense">
              <NavLink to="/financial-expenses-list" icon={FaRegMoneyBillAlt} shouldMatchExactPath>
                Despesas
              </NavLink>
            </Ability>

            <NavLink to="/advance-requests" icon={GiReceiveMoney} shouldMatchExactPath>
              Solicitação de adiantamento
            </NavLink>

            <NavLink to="/balance-requests" icon={MdOutlineAttachMoney} shouldMatchExactPath>
              Solicitação de saldo
            </NavLink>
            <NavLink to="/loading-requests" icon={IoPush} shouldMatchExactPath>
              Solic. de carregamento
            </NavLink>
          </AccordionPanel>
        </AccordionItem>

        {/* SEGURANÇA 8 */}
        <AccordionItem mt={2} borderTopWidth={0} borderBottomWidth={0} border={0}>
          <AccordionButton width={280} pl={5} onClick={() => dispatch(setIndex(8))}>
            <Heading as="h5" size="sm" flex="1" textAlign="left">
              SEGURANÇA
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel as={Flex} flexDirection="column" gridGap={1} pl={5}>
            <Ability module="operation" action="list-risk-analysis">
              <NavLink to="/list-risk-analysis" icon={BiFileFind} shouldMatchExactPath>
                Análise de risco
              </NavLink>
            </Ability>

            <Ability module="operation" action="list-black-list">
              <NavLink to="/black-list" icon={FaClipboardList} shouldMatchExactPath>
                Lista de restrição
              </NavLink>
            </Ability>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  )
}
