/* eslint-disable no-console */
import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Link,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAddLine, RiArrowRightLine } from 'react-icons/ri'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useApiCarsRequestContext } from '../../../contexts/ApiCarsRequestContext'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { apiServer } from '../../../services/api'
import { fetchVehicleByColumn } from '../../../services/endpoints/vehicles/getVehicleByColumn'
import { useCreateAttachmentToVehicle } from '../../../services/endpoints/vehicles/vehicle-attachments/createVehicleAttachment'
import { useUpdateAttachmentToVehicle } from '../../../services/endpoints/vehicles/vehicle-attachments/updateAttachment'
import { ResponseApiType, VehicleType } from '../../../services/types'
import BlackListType from '../../../services/types/BlackListType'
import { TrailerType } from '../../../services/types/TrailerType'
import { addMoreOneParamToUrl } from '../../../services/utils/format-url/addMoreOneParamToUrl'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setVehicleCreated, setVehicleId } from '../../../store/slices/vehicleSlice'
import { handleLicensePlateIsBlackList } from '../../black-list/check-document-exists/handleLicensePlateIsBlackList'
import { ShowInfosBlackListModal } from '../../black-list/modals/ShowInfosBlackListModal'
import { AttachTrailerToVehicleModal } from '../modals/AttachTrailerToVehicleModal'
import { vehicleFormValidation } from '../validations'
import { Form } from './Form'

interface VehicleCompleteFormProps {
  vehicle?: VehicleType | null
  trailer?: TrailerType | null
  owner_id?: string
  refetch: () => void
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
  freight_id?: string
}

type City = {
  label: string
  value: number
  stateName: string
  uf: string
  lat: number
  lng: number
  cityName: string
}
export interface VehicleCompleteForm {
  city_id: City
  has_insurance: boolean
  has_tracker: boolean
  color: string
  capacity_kg: number
  capacity_tara: number
  capacity_m3: number
  antt: string
  chassi: string
  model_year: number
  release_year: number
  model: string
  brand: string
  vehicle_body_id: string
  vehicle_category_id: string
  renavam: string
  axes: number
  license_uf: string
  license_plate: string
}

export function VehicleCompleteForm({
  vehicle,
  trailer,
  owner_id,
  refetch,
  setTabIndex,
  freight_id,
}: VehicleCompleteFormProps): JSX.Element {
  const toast = useToast()
  const { carsApi } = useApiCarsRequestContext()
  const dispatch = useAppDispatch()
  const vehicleIdParam = useQueryParams('vehicle_id')
  const { trailerId } = useAppSelector(state => state.vehicleSlice)
  const { motoristId: id } = useAppSelector(state => state.motoristSlice)
  const { crlvVehicleFile } = useAppSelector(state => state.ocrCrlvSlice)
  const { mutateAsync: createAttachmentVehicle } = useCreateAttachmentToVehicle({
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries('vehicle')
      toast({
        status: 'success',
        title: 'Anexo do CRLV do veículo criado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
    },
  })
  const { mutateAsync: updateAttachmentVehicle } = useUpdateAttachmentToVehicle({
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries('vehicle')
      toast({
        status: 'success',
        title: 'Anexo do CRLV do veículo atualizado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
    },
  })
  const motorist_id = useQueryParams('motorist_id')
  const { setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(vehicleFormValidation),
  })

  let motoristId = motorist_id
  if (id) motoristId = id

  const handleUpdateVehicle = useCallback<SubmitHandler<VehicleCompleteForm>>(
    async data => {
      if (!vehicle) {
        try {
          const findVehicle = await fetchVehicleByColumn({
            columnName: 'renavam',
            columnValue: data.renavam,
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps, no-param-reassign
          if (findVehicle) vehicle = findVehicle
        } catch (error) {
          console.log(error)
        }
      }
      if (vehicle) {
        if (data.renavam === trailer?.renavam) {
          toast({
            title: 'O renavam da tração não pode ser igual ao renavam do reboque!',
            isClosable: true,
            position: 'top-right',
            status: 'error',
          })
        }

        try {
          const { data: result } = await apiServer.put<ResponseApiType<VehicleType>>(
            `/update-vehicle/${vehicle.id}`,
            {
              ...data,
              license_plate: data.license_plate ? String(data.license_plate).replace('-', '') : undefined,
              city_id: data.city_id ? data.city_id.value : undefined,
            },
          )

          if (result.statusCode === 200 && crlvVehicleFile) {
            const attachment = vehicle?.attachments?.find(item => item.type === 'crlv')
            if (attachment?.id) {
              await updateAttachmentVehicle({
                id: attachment.id,
                attachment_file: crlvVehicleFile,
                vehicle_id: result.data.id,
              })
            } else {
              await createAttachmentVehicle({
                attachment_file: crlvVehicleFile,
                vehicle_id: result.data.id,
                name: 'Crlv do veículo',
                type: 'crlv',
              })
            }
          }

          if (!vehicleIdParam || vehicleIdParam !== vehicle.id) {
            addMoreOneParamToUrl('vehicle_id', vehicle.id)

            await apiServer.post('/motorist-attach-vehicle', {
              motorist_id: motoristId,
              vehicle_id: vehicle.id,
            })
            toast({
              title: 'Veículo atualizado e vinculado ao motorista com sucesso!',
              isClosable: true,
              position: 'top-right',
              status: 'success',
            })
          } else
            toast({
              title: 'Veículo atualizado com sucesso!',
              isClosable: true,
              position: 'top-right',
              status: 'success',
            })
          await queryClient.invalidateQueries('vehicle')
          await queryClient.invalidateQueries('check-pendencies')
          refetch()
          setTabIndex(state => state + 1)
        } catch (error) {
          toastError({ toast, error })
        }
      } else {
        try {
          const { data: response } = await apiServer.post<ResponseApiType<VehicleType>>(`/create-vehicle`, {
            ...data,
            license_plate: data.license_plate ? String(data.license_plate).replace('-', '') : undefined,
            city_id: data.city_id ? data.city_id.value : undefined,
          })

          const newVehicle = response.data
          if (newVehicle && crlvVehicleFile) {
            await createAttachmentVehicle({
              attachment_file: crlvVehicleFile,
              vehicle_id: newVehicle.id,
              name: 'Crlv do veículo',
              type: 'crlv',
            })
          }

          if (motoristId && newVehicle.id) {
            await apiServer.post('/motorist-attach-vehicle', {
              motorist_id: motoristId,
              vehicle_id: newVehicle.id,
            })

            toast({
              title: 'Veículo cadastrado e vinculado ao motorista com sucesso!',
              isClosable: true,
              position: 'top-right',
              status: 'success',
            })
          } else {
            toast({
              title: 'Veículo cadastrado com sucesso!',
              isClosable: true,
              position: 'top-right',
              status: 'success',
            })
          }

          if (owner_id && newVehicle.id) {
            await apiServer.put(`/update-vehicle/${newVehicle.id}`, {
              owner_id,
            })

            toast({
              title: 'Veículo cadastrado e vinculado ao proprietário com sucesso!',
              isClosable: true,
              position: 'top-right',
              status: 'success',
            })
          } else {
            toast({
              title: 'Veículo cadastrado com sucesso!',
              isClosable: true,
              position: 'top-right',
              status: 'success',
            })
          }

          if (newVehicle.id) {
            addMoreOneParamToUrl('vehicle_id', newVehicle.id)
            dispatch(setVehicleId(newVehicle.id))
            dispatch(setVehicleCreated(newVehicle))
          }

          await queryClient.invalidateQueries('vehicle')
          await queryClient.invalidateQueries('check-pendencies')
          refetch()
          setTabIndex(state => state + 1)
        } catch (error) {
          toastError({ toast, error })
        }
      }
    },
    [
      vehicle,
      trailer?.renavam,
      toast,
      vehicleIdParam,
      refetch,
      setTabIndex,
      motoristId,
      owner_id,
      createAttachmentVehicle,
      crlvVehicleFile,
      dispatch,
    ],
  )

  const renavamRef = useRef<HTMLInputElement>(null)
  const [licensePlateInBlackList, setIsLicensePlateInBlackList] = useState<BlackListType>()
  const { isOpen, onClose, onOpen } = useDisclosure()

  useEffect(() => {
    if (vehicle) {
      const handleLicensePlateInBlackList = async () => {
        if (vehicle?.license_plate) {
          try {
            const data = await handleLicensePlateIsBlackList(vehicle.license_plate)
            setIsLicensePlateInBlackList(data)
            onOpen()
          } catch (error) {
            setIsLicensePlateInBlackList(undefined)
            toastError({ toast, error })
          }
        }
      }
      handleLicensePlateInBlackList()
    }
  }, [onOpen, vehicle, toast])

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center" mb={2}>
        <GridItem colSpan={12}>
          <Stack direction="row" spacing={4}>
            {motoristId && freight_id && (
              <Button
                size="sm"
                colorScheme="blue"
                as={Link}
                href={`/vehicles/create?motorist_id=${motoristId}&freight_id=${freight_id}`}
                leftIcon={<Icon as={RiAddLine} />}
              >
                Vincular um novo veículo
              </Button>
            )}
            {vehicle &&
              vehicle.vehicle_category &&
              vehicle.vehicle_category.has_trailer &&
              vehicle.trailers &&
              vehicle.trailers.length <= 1 && (
                <AttachTrailerToVehicleModal
                  setTabIndex={setTabIndex}
                  size="sm"
                  buttonTitle="Vincular mais um reboque"
                  vehicle_id={vehicle.id}
                />
              )}
          </Stack>
        </GridItem>
      </Grid>
      <Divider my="4" />

      <form onSubmit={handleSubmit(handleUpdateVehicle)} noValidate>
        <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
          {(trailerId || carsApi?.vehicle_model_type?.toLowerCase().includes('reboque')) && (
            <GridItem colSpan={12}>
              <Alert status="error" my="4" borderRadius="4">
                <AlertIcon />

                <Flex>O renavam digitado é de um reboque!</Flex>
              </Alert>
            </GridItem>
          )}
          <Form
            initialData={vehicle}
            renavamRef={renavamRef}
            onOpen={onOpen}
            setValue={setValue}
            setIsLicensePlateInBlackList={setIsLicensePlateInBlackList}
            formState={formState}
            isTrailer={false}
          />
          {licensePlateInBlackList && (
            <ShowInfosBlackListModal isOpen={isOpen} onClose={onClose} black_list={licensePlateInBlackList} />
          )}
        </Grid>

        <HStack
          spacing="4"
          justifyContent="center"
          mt="6"
          pt="6"
          borderTopWidth="1px"
          borderTopColor="gray.200"
        >
          <Button
            type="submit"
            name="vehicle_submit"
            rightIcon={<Icon as={RiArrowRightLine} />}
            colorScheme="green"
            onClick={() => {
              console.log(formState.errors)
            }}
            isDisabled={
              !!licensePlateInBlackList ||
              !!trailerId ||
              carsApi?.vehicle_model_type?.toLowerCase().includes('reboque')
            }
            isLoading={formState.isSubmitting}
          >
            Salvar e avançar
          </Button>
        </HStack>
      </form>
    </>
  )
}
