import * as yup from 'yup'
import { validateDate } from '../../../../../services/utils/dates/isValidDate'
import { validateCpf } from '../../../../../services/utils/validateCpf'

export type AnttFormValues = {
  type: string
  document: string // cnpj
  name: string
  phone: string
  rg_ie: string
  email?: string
  birth: string
  rg_dispatcher: string // esse
  rg_dispatch_date: string
  rg_uf: string // esse
  mother_name: string // esse
  father_name: string // esse
  // address
  zipcode: string
  address: string
  district: string
  address_number: string
  city: {
    label: string
    value: string | number
  }
  city_uf: string

  pis?: string
  rntrc: string
  rntrc_type: string
  account: string
  account_type: string
  agency: string
  bank: {
    label: string
    value: string | number
  }
}

export const AnttOnwerSchema = yup.object().shape({
  type: yup.string().oneOf(['pf', 'pj'], 'Apenas pf ou pj').required('Campo obrigatório'),
  document: yup.string().when('type', {
    is: 'pf',
    then: yup
      .string()
      .test('validate-cpf', 'CPF inválido', value => {
        return validateCpf(value)
      })
      .required('Campo obrigatório'),
    otherwise: yup.string().required('Campo obrigatório'),
  }),
  name: yup.string().required('Nome é obrigatório'),
  phone: yup.string().required('Telefone é obrigatório'),

  rntrc: yup.string().min(8, 'RNTRC incompleto').required('Campo obrigatório'),
  rntrc_type: yup.string().required('Tipo de RNTRC é obrigatório'),
  account: yup.string().required('Conta é obrigatório'),
  account_type: yup.string().required('Tipo de conta é obrigatório'),
  agency: yup.string().required('Agência é obrigatório'),
  bank: yup.object().test('bank', 'Banco é obrigatório', value => !!value.value),
  email: yup.string().nullable(),
  // address
  zipcode: yup.string().required('CEP é obrigatório'),
  address: yup.string().required('Endereço é obrigatório'),
  district: yup.string().required('Bairro é obrigatório'),
  address_number: yup.string().required('Número do endereço é obrigatório'),
  city: yup.object().test('address_city', 'Cidade é obrigatório', value => !!value.value),
  city_uf: yup.string().required('Estado é obrigatório'),

  birth: yup.string().when('type', {
    is: 'pf',
    then: yup
      .string()
      .test('is-valid-date', 'Data inválida', validateDate)
      .required('Data de nascimento é obrigatório'),
    otherwise: yup.string().nullable(),
  }),

  mother_name: yup.string().when('type', {
    is: 'pf',
    then: yup.string().required('Nome da mãe é obrigatório'),
    otherwise: yup.string().nullable(),
  }),

  father_name: yup.string().when('type', {
    is: 'pf',
    then: yup.string().required('Nome do pai é obrigatório'),
    otherwise: yup.string().nullable(),
  }),

  pis: yup.string().when('type', {
    is: 'pf',
    then: yup.string().required('PIS é obrigatório'),
    otherwise: yup.string().nullable(),
  }),

  rg_ie: yup.string().when('type', {
    is: 'pf',
    then: yup.string().required('RG é obrigatório'),
    otherwise: yup.string().nullable(),
  }),

  rg_uf: yup.string().when('type', {
    is: 'pf',
    then: yup.string().required('UF é obrigatório'),
    otherwise: yup.string().nullable(),
  }),

  rg_dispatcher: yup.string().when('type', {
    is: 'pf',
    then: yup.string().required('Nome do responsável é obrigatório'),
    otherwise: yup.string().nullable(),
  }),

  rg_dispatch_date: yup.string().when('type', {
    is: 'pf',
    then: yup.string().test('is-valid-date', 'Data inválida', validateDate).required('campo obrigatório'),
    otherwise: yup.string().nullable(),
  }),
})
