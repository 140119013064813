import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  useToast,
} from '@chakra-ui/react'
import { forEach } from 'lodash'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import { toastError } from '../../../../../config/error/toastError'
import { usePutBalanceRequest } from '../../../../../services/endpoints/freights/putBalanceRequest'
import { useUploadBalanceRequestAttachments } from '../../../../../services/endpoints/freights/UploadBalanceRequestAttachments'
import {
  BalanceRequestAttachmentTypeEnum,
  BalanceRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'

type UploadBalanceRequestReceiptsModalProps = {
  isOpen: boolean
  onClose: () => void
}

type RequestParams = {
  id: string
}

type UploadReceiptsBalanceRequest = {
  attachments: File[]
}

export function UploadBalanceRequestReceiptsModal({
  isOpen,
  onClose,
}: UploadBalanceRequestReceiptsModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue } = useForm({})
  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateBalanceRequestStatus, isLoading } = usePutBalanceRequest({
    onSuccess: () => {
      toast({
        title: 'Solicitação de saldo atualizada com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const {
    mutateAsync: uploadBalanceRequestAttachments,
    isLoading: isLoadingUploadBalanceRequestAttachments,
  } = useUploadBalanceRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUploadReceiptsBalanceRequest: SubmitHandler<UploadReceiptsBalanceRequest> = async data => {
    const uploadFormData = {
      status: BalanceRequestStatusEnum.PAID,
    }

    await updateBalanceRequestStatus({ id, data: uploadFormData })

    forEach(data.attachments, async attachment => {
      if (attachment) {
        const formData = new FormData()

        formData.append('balance_request_id', id)
        formData.append('type', BalanceRequestAttachmentTypeEnum.RECEIPT)
        formData.append('attachment', attachment)

        await uploadBalanceRequestAttachments({ data: formData })
      }
    })

    onClose()
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Anexar comprovantes de pagamento</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUploadReceiptsBalanceRequest)} noValidate>
          <ModalBody>
            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="attachments"
              label="Anexo de recibos de saldo"
              multiple
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="red"
              mr={3}
              onClick={() => {
                onClose()
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoading || isLoadingUploadBalanceRequestAttachments}
              isDisabled={isLoading || isLoadingUploadBalanceRequestAttachments}
            >
              Avançar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
