import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiArrowRightLine, RiCaravanLine } from 'react-icons/ri'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../../services/api'
import { useCreateAttachmentToTrailer } from '../../../services/endpoints/trailers/trailer-attachments/createTrailerAttachment'
import { useUpdateAttachmentToTrailer } from '../../../services/endpoints/trailers/trailer-attachments/updateTrailerAttachment'
import { attachTrailerToVehicle } from '../../../services/endpoints/vehicles/attachTrailerToVehicle'
import { detachTrailerFromVehicle } from '../../../services/endpoints/vehicles/detachTrailerFromVehicle'
import { fetchVehicleByColumn } from '../../../services/endpoints/vehicles/getVehicleByColumn'
import { ResponseApiType } from '../../../services/types'
import BlackListType from '../../../services/types/BlackListType'
import { TrailerType } from '../../../services/types/TrailerType'
import VehicleType from '../../../services/types/VehicleType'
import { useAppSelector } from '../../../store'
import { ShowInfosBlackListModal } from '../../black-list/modals/ShowInfosBlackListModal'
import { AttachTrailerToVehicleModal } from '../modals/AttachTrailerToVehicleModal'
import { trailerFormValidation } from '../validations'
import { Form } from './Form'

interface TrailerCompleteFormProps {
  trailer?: TrailerType
  vehicle: VehicleType | null
  refetch: () => void
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
}

export interface City {
  label: string
  value: string
}
export interface TrailerCompleteForm {
  city_id: City
  has_insurance: boolean
  has_tracker: boolean
  capacity_kg: number
  capacity_tara: number
  capacity_m3: number
  antt: string
  model_year: string
  release_year: string
  chassi: string
  model: string
  brand: string
  vehicle_body_id: string
  color: string
  renavam: string
  axes: number
  license_uf: string
  license_plate: string
}

export function TrailerCompleteForm({
  trailer,
  vehicle,
  refetch,
  setTabIndex,
}: TrailerCompleteFormProps): JSX.Element {
  const toast = useToast()
  const [Trailer, setTrailer] = useState<TrailerType>()
  const { crlvTrailerFile } = useAppSelector(state => state.ocrCrlvSlice)
  const { trailerFound } = useAppSelector(state => state.vehicleSlice)
  const { mutateAsync: createAttachmentTrailer } = useCreateAttachmentToTrailer({
    onSuccess: () => {
      refetch()
      toast({
        status: 'success',
        title: 'Anexo do CRLV do reboque criado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
    },
  })
  const { mutateAsync: updateAttachmentTrailer } = useUpdateAttachmentToTrailer({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do reboque atualizado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
    },
  })
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(trailerFormValidation),
  })

  useEffect(() => {
    if (trailerFound) {
      setTrailer(trailerFound)
    }
    if (trailer && !trailerFound) {
      setTrailer(trailer)
    }
  }, [trailer, trailerFound])

  const handleUpdateTrailer = useCallback<SubmitHandler<TrailerCompleteForm>>(
    async data => {
      let trailer_id = ''
      if (data.renavam === vehicle?.renavam) {
        toast({
          title: 'O renavam do reboque não pode ser igual ao renavam da tração!',
          isClosable: true,
          position: 'top-right',
          status: 'error',
        })
      }

      if (Trailer?.id) {
        trailer_id = Trailer.id
        try {
          const { data: result } = await apiServer.put<ResponseApiType<TrailerType>>(
            `/update-trailer/${Trailer.id}`,
            {
              ...data,
              license_plate: data.license_plate ? String(data.license_plate).replace('-', '') : undefined,
              city_id: data.city_id ? data.city_id.value : undefined,
            },
          )
          toast({
            title: 'Reboque atualizado com sucesso!',
            isClosable: true,
            position: 'top-right',
            status: 'success',
          })

          if (result.statusCode === 200 && crlvTrailerFile) {
            const attachment = Trailer?.attachments?.find(item => item.type === 'crlv')
            if (attachment?.id) {
              await updateAttachmentTrailer({
                id: attachment.id,
                attachment_file: crlvTrailerFile,
                trailer_id: result.data.id,
              })
            } else {
              await createAttachmentTrailer({
                attachment_file: crlvTrailerFile,
                trailer_id: result.data.id,
                name: 'Crlv do veículo',
                type: 'crlv',
              })
            }
          }
          await queryClient.invalidateQueries('vehicle')
          await queryClient.invalidateQueries('check-pendencies')
          refetch()
          setTabIndex(state => state + 1)
        } catch (error) {
          toastError({ toast, error })
        }
      } else {
        try {
          const { data: response } = await apiServer.post<ResponseApiType<TrailerType>>(`/create-trailer`, {
            ...data,
            license_plate: data.license_plate ? String(data.license_plate).replace('-', '') : undefined,
            city_id: data.city_id ? data.city_id.value : undefined,
          })
          const { data: newTrailer } = response
          trailer_id = newTrailer.id
          toast({
            title: 'Reboque cadastrado com sucesso!',
            isClosable: true,
            position: 'top-right',
            status: 'success',
          })

          if (newTrailer && crlvTrailerFile) {
            await createAttachmentTrailer({
              attachment_file: crlvTrailerFile,
              trailer_id: newTrailer.id,
              name: 'Crlv do veículo',
              type: 'crlv',
            })
          }

          if (trailer_id && vehicle && vehicle.trailers.every(t => t.id !== trailer_id)) {
            try {
              await detachTrailerFromVehicle(vehicle.id, vehicle.trailers[0].id)
              await attachTrailerToVehicle(vehicle?.id, trailer_id)
              toast({
                title: 'Reboque vinculado ao veículo com sucesso!',
                isClosable: true,
                position: 'top-right',
                status: 'success',
              })
            } catch (error) {
              toastError({ toast, error })
            }
          }

          await queryClient.invalidateQueries('vehicle')
          await queryClient.invalidateQueries('check-pendencies')
          refetch()
          setTabIndex(state => state + 1)
        } catch (error) {
          toastError({ toast, error })
        }
      }
    },
    [
      Trailer?.attachments,
      Trailer?.id,
      createAttachmentTrailer,
      crlvTrailerFile,
      refetch,
      setTabIndex,
      toast,
      updateAttachmentTrailer,
      vehicle,
    ],
  )

  const renavamRef = useRef<HTMLInputElement>(null)
  const [licensePlateInBlackList, setIsLicensePlateInBlackList] = useState<BlackListType>()

  const [isLoading, setIsLoading] = useState(false)

  const { isOpen, onClose, onOpen } = useDisclosure()

  const handleUnlinkTrailerFromVehicle = async () => {
    if (vehicle) {
      try {
        setIsLoading(true)

        await detachTrailerFromVehicle(vehicle.id, vehicle.trailers[0].id)

        await queryClient.invalidateQueries('vehicle')

        toast({
          title: 'Reboque desvinculado com sucesso!',
          isClosable: true,
          position: 'top-right',
          status: 'success',
        })
        await queryClient.invalidateQueries('check-pendencies')
      } catch (error) {
        toastError({ toast, error: 'Erro ao desvincular o reboque' })
      } finally {
        setIsLoading(false)
      }
    }
  }

  const [vehicleExists, setVehicleExists] = useState<VehicleType>()
  async function handleCheckVehicleExists() {
    if (renavamRef.current) {
      const { value } = renavamRef.current
      try {
        const vehicleFound = await fetchVehicleByColumn({ columnName: 'renavam', columnValue: value })
        if (vehicleFound) {
          setVehicleExists(vehicleFound)
        }
      } catch {
        setVehicleExists(undefined)
      }
    }
  }

  return (
    <>
      {vehicle && Trailer && (
        <Alert
          mb={3}
          status="info"
          borderRadius="4"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={['column', 'column', 'row']}
          gridGap={2}
        >
          <Flex>
            <AlertDescription>
              Caso este reboque não esteja correto ou deseja vincular outro reboque clique no botão:
            </AlertDescription>
          </Flex>

          <Flex justifyContent="flex-end">
            <Button
              size="sm"
              leftIcon={<RiCaravanLine />}
              colorScheme="red"
              isLoading={isLoading}
              onClick={handleUnlinkTrailerFromVehicle}
            >
              Desvincular reboque do veículo
            </Button>
          </Flex>
        </Alert>
      )}

      <form onSubmit={handleSubmit(handleUpdateTrailer)} noValidate>
        {vehicle && (
          <>
            {vehicleExists && (
              <Flex mx={6}>
                <Alert status="error" my="4" borderRadius="4">
                  <AlertIcon />

                  <Flex>O renavam digitado pertence a um veiculo de tração!</Flex>
                </Alert>
              </Flex>
            )}
            <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
              <Form
                initialData={
                  Trailer
                    ? { ...Trailer, vehicle_category: { ...Trailer.vehicle_category, has_trailer: true } }
                    : undefined
                }
                vehicle={vehicle}
                setIsLicensePlateInBlackList={setIsLicensePlateInBlackList}
                handleCheckVehicleExists={handleCheckVehicleExists}
                renavamRef={renavamRef}
                onOpen={onOpen}
                setValue={setValue}
                formState={formState}
                isTrailer
              />
              {licensePlateInBlackList && (
                <ShowInfosBlackListModal
                  isOpen={isOpen}
                  onClose={onClose}
                  black_list={licensePlateInBlackList}
                />
              )}
            </Grid>
          </>
        )}

        <HStack
          spacing="4"
          justifyContent="center"
          mt="6"
          pt="6"
          borderTopWidth="1px"
          borderTopColor="gray.200"
        >
          {vehicle && vehicle.trailers && vehicle.trailers.length > 0 && (
            <Button
              type="submit"
              name="trailer_submit"
              rightIcon={<Icon as={RiArrowRightLine} />}
              colorScheme="green"
              isDisabled={!!licensePlateInBlackList || !!vehicleExists}
              isLoading={formState.isSubmitting}
            >
              Salvar e avançar
            </Button>
          )}
        </HStack>
      </form>

      {vehicle &&
        vehicle.vehicle_category.has_trailer &&
        vehicle.trailers &&
        vehicle.trailers.length === 0 && (
          <AttachTrailerToVehicleModal
            setTabIndex={setTabIndex}
            buttonTitle="Vincular reboque"
            vehicle_id={vehicle.id}
          />
        )}
    </>
  )
}
