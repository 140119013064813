import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { AdvanceRequestType } from '../../types/AdvanceRequestType'
import { AdvanceRequestStatusEnum } from '../../types/EnumTypes'
import { formatAdvanceRequestStatus } from '../../utils/formatAdvanceRequestStatus'

type AdvanceRequestTypeFormatted = AdvanceRequestType & {
  formatted_status: string
}

type ListAdvanceRequestResponse = {
  total: number
  data: AdvanceRequestTypeFormatted[]
}

export type FilterAdvanceRequestsType = {
  freight_number?: string
  status?: AdvanceRequestStatusEnum
  creator_id?: string
  initial_date?: string
  final_date?: string
  page?: number
}

async function listAdvanceRequest(params: FilterAdvanceRequestsType) {
  const { data: response } = await apiServer.get(`/advance-requests`, {
    params,
  })

  const { data } = response
  const { total } = data

  const formattedAdvanceRequests = data.data.map((advanceRequest: AdvanceRequestType) => {
    return {
      ...advanceRequest,
      formatted_status: formatAdvanceRequestStatus(advanceRequest.status),
      created_at: format(new Date(advanceRequest.created_at), 'dd/MM/yy HH:mm'),
    }
  })

  return { total, data: formattedAdvanceRequests }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGetAllAdvanceRequest(
  params: FilterAdvanceRequestsType,
): UseQueryResult<ListAdvanceRequestResponse> {
  return useQuery(['advance-requests', params], () => listAdvanceRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
