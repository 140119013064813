import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Grid,
  GridItem,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ChangePersonModal } from '../../../../../components/ChangePersonModal/ChangePersonModal'
import useSearchAddress from '../../../../../hooks/useSearchAddress'
import { useBlackListReturn } from '../../../../../hooks/useSearchMotorist'
import useSearchOwner from '../../../../../hooks/useSearchOwner'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'
import OwnerType from '../../../../../services/types/OwnerType'
import { ufOptions } from '../../../../../services/utils/ufOptions'
import { ShowInfosBlackListModal } from '../../../../black-list/modals/ShowInfosBlackListModal'
import { UIInput } from '../../components/UI/UIInput'
import { UISelect } from '../../components/UI/UISelect'
import { UISelectAsync } from '../../components/UI/UISelectAsync'
import { OwnerFormValues } from './type'

type OwnerFormProps = {
  onOwnerAlreadyFound?: (owner: OwnerType | null) => void
  type?: 'trailer' | 'vehicle'
  disabled?: boolean
  hasOwner?: boolean
}

export const OwnerForm = ({ onOwnerAlreadyFound, type, disabled, hasOwner }: OwnerFormProps): JSX.Element => {
  const [showInfosBlackListModal, setShowInfosBlackListModal] = useState<useBlackListReturn | null>(null)
  const toast = useToast()
  const { control, watch, setValue, clearErrors } = useFormContext<OwnerFormValues>()
  const ownerType = watch('type')
  const ownerFoundModal = useDisclosure()
  const [ownerAlreadyFound, setOwnerAlreadyFound] = useState<OwnerType | null>(null)

  const { loadingAddress, searchAddress } = useSearchAddress(
    data => {
      setValue('address', data?.address || '')
      setValue('district', data?.district || '')
      setValue('city', {
        label: data?.city?.label || '',
        value: data?.city?.value || '',
      })
      setValue('city_uf', data?.uf || '')

      toast({
        title: 'Endereço encontrado',
        description: 'Endereço encontrado com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    error => {
      toast({
        title: 'Endereço não encontrado',
        description: error?.message || 'Endereço não encontrado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  )

  const { loading: loadingOwner, search: searchOwner } = useSearchOwner({
    onSuccess: onOwnerFound => {
      if (onOwnerFound.owner) {
        ownerFoundModal.onOpen()
        setOwnerAlreadyFound(onOwnerFound.owner)
        return
      }

      if (!onOwnerFound.owner) {
        ownerFoundModal.onOpen()
      }
      if (onOwnerFound.cpnj_person) {
        setValue('document', onOwnerFound.cpnj_person.cnpj)
        setValue('name', onOwnerFound.cpnj_person.name)
        setValue('type', 'pj')
        setValue('phone', onOwnerFound.cpnj_person.phone)
        setValue('zipcode', onOwnerFound.cpnj_person.zipcode)
        setValue('address', onOwnerFound.cpnj_person.address)
        setValue('district', onOwnerFound.cpnj_person.district)
        setValue('address_number', onOwnerFound.cpnj_person.address_number.toString())
        setValue('city', {
          label: onOwnerFound.cpnj_person.city?.label || '',
          value: onOwnerFound.cpnj_person.city?.value || '',
        })
        setValue('city_uf', onOwnerFound.cpnj_person.address_uf)
      }
    },
    onBlackList: data => {
      if (data) {
        setShowInfosBlackListModal(data)
        toast({
          title: 'Proprietário encontrado na lista negra',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },
  })

  const resetFilledForm = () => {
    setValue('name', '')
    setValue('phone', '')
    setValue('zipcode', '')
    setValue('address', '')
    setValue('district', '')
    setValue('address_number', '')
    setValue('birth', '')
    setValue('city', {
      label: '',
      value: '',
    })
    setValue('city_uf', '')
    setValue('father_name', '')
    setValue('mother_name', '')
    setValue('email', '')
    setValue('rntrc', '')

    setValue('email', '')
    setValue('rg_ie', '')
    setValue('rg_dispatcher', '')
    setValue('rg_dispatch_date', '')
    setValue('rg_uf', '')
  }

  return (
    <Box pointerEvents={disabled ? 'none' : 'auto'} opacity={disabled ? 0.5 : 1}>
      {showInfosBlackListModal && (
        <ShowInfosBlackListModal
          isOpen={showInfosBlackListModal !== null}
          onClose={() => setShowInfosBlackListModal(null)}
          black_list={showInfosBlackListModal}
        />
      )}
      {hasOwner && (
        <ChangePersonModal
          isOpen={ownerFoundModal.isOpen}
          onClose={ownerFoundModal.onClose}
          onConfirm={() => {
            if (ownerAlreadyFound) {
              onOwnerAlreadyFound?.(ownerAlreadyFound)
              setOwnerAlreadyFound(null)
              ownerFoundModal.onClose()
              return
            }

            onOwnerAlreadyFound?.(null)
            setOwnerAlreadyFound(null)
            resetFilledForm()
            ownerFoundModal.onClose()
          }}
          title="Mudanças no proprietário"
          cancelLabel="Estou editando o documento"
          confirmLabel="Quero mudar o proprietário"
          description={
            <>
              <Text>
                {ownerAlreadyFound?.name
                  ? `Realmente deseja alterar os dados do proprietário`
                  : 'Realmente deseja alterar os dados do proprietário?'}
              </Text>
              {ownerAlreadyFound && (
                <Text mt="2" color="green.500">
                  Novo proprietário: {ownerAlreadyFound?.name}
                </Text>
              )}
              <Text mt="2" fontSize="sm" opacity={0.5}>
                Para que possa ser feito o processo de mudança de proprietário, selecione umas das seguintes
                opções:
              </Text>
            </>
          }
        />
      )}
      {type && (
        <Box>
          <Alert status="warning" mb="2">
            <AlertIcon />
            <AlertTitle mr={2}>
              Preencha o campo RNTRC correspondente quando donos de ANTT forem diferentes
            </AlertTitle>
          </Alert>
          <Controller
            control={control}
            name="rntrc"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                mask="9999999999999"
                label="RNTRC"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                isSearchable
                value={value}
              />
            )}
          />
        </Box>
      )}
      <Text fontSize="xs" opacity={0.5} mb="2">
        Informações de proprietário:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                options={[
                  { label: '', value: '' },
                  { label: 'Pessoa física', value: 'pf' },
                  { label: 'Pessoa Jurídica', value: 'pj' },
                ]}
                isRequired
                label="Tipo de proprietário"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="document"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                mask={ownerType === 'pj' ? '99.999.999/9999-99' : '999.999.999-99'}
                label={ownerType === 'pj' ? 'CNPJ' : 'CPF'}
                isError={!!error?.message}
                errorMessage={error?.message}
                isLoading={loadingOwner}
                isSearchable
                value={value}
                onChange={({ target }) => {
                  onChange(target.value)
                }}
                onSearch={() => searchOwner(value, ownerType === 'pj' ? 'pj' : 'pf')}
                onBlur={() => {
                  if (value) {
                    searchOwner(value, ownerType === 'pj' ? 'pj' : 'pf')
                  }
                }}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 5]}>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label={ownerType === 'pj' ? 'Razão Social' : 'Nome'}
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="rg_ie"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label={ownerType === 'pj' ? 'Inscrição Estadual' : 'RG'}
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
                isRequired={ownerType === 'pf'}
              />
            )}
          />
        </GridItem>

        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 4, 3]}>
            <Controller
              control={control}
              name="rg_dispatcher"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  label="Órgão Expeditor RG"
                  isRequired
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 4, 2]}>
            <Controller
              control={control}
              name="rg_uf"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UISelect
                  options={ufOptions}
                  isRequired
                  label="RG UF"
                  onChange={onChange}
                  value={value}
                  isError={!!error?.message}
                  errorMessage={error?.message}
                />
              )}
            />
          </GridItem>
        )}
        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 4]}>
            <Controller
              control={control}
              name="rg_dispatch_date"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  type="date"
                  isRequired
                  label="Data da Expedição"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 3]}>
            <Controller
              control={control}
              name="birth"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  type="date"
                  isRequired
                  label="Data de Nascimento"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="(99) 99999-9999"
                label="Telefone Principal"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                isRequired
                label="Email"
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>

        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 6]}>
            <Controller
              control={control}
              name="mother_name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  isRequired
                  label="Nome da Mãe"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}

        {ownerType === 'pf' && (
          <GridItem colSpan={[12, 6, 6]}>
            <Controller
              control={control}
              name="father_name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  isRequired
                  label="Nome do Pai"
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
        )}
      </Grid>
      <Text fontSize="xs" opacity={0.5} mb="2" mt="4">
        Informações de Endereço:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 3]}>
          <Controller
            control={control}
            name="zipcode"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <UIInput
                mask="99999-999"
                label="CEP"
                onChange={({ target }) => {
                  onChange(target.value)
                  searchAddress(target.value)
                }}
                value={value}
                isError={!!error?.message}
                errorMessage={error?.message}
                isLoading={loadingAddress}
                isSearchable
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 8]}>
          <Controller
            control={control}
            name="address"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Endereço"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 1]}>
          <Controller
            control={control}
            name="address_number"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Numero"
                type="text"
                isError={!!error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Controller
            control={control}
            name="district"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Bairro"
                type="text"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 6]}>
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelectAsync
                label="Cidade"
                placeholder="Cidade"
                loadOptions={searchCitiesByName}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={option => {
                  onChange(option)
                  setValue('city_uf', option?.uf || '')
                  clearErrors('city_uf')
                }}
                value={value}
                isRequired
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 2]}>
          <Controller
            control={control}
            name="city_uf"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="UF"
                placeholder="UF"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
                isRequired
                isDisabled
              />
            )}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export const ownerFormInitialValues = {
  type: '',
  phone: '',
  document: '',
  zipcode: '',
  address: '',
  district: '',
  address_number: '',
  city: { label: '', value: '' },
  city_uf: '',
  mother_name: '',
  father_name: '',
  name: '',
  rg_ie: '',
  rg_uf: '',
  rg_dispatcher: '',
  birth: '',
  email: '',
  rntrc: '',
}
