import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiAttachmentLine,
  RiErrorWarningLine,
  RiThumbUpLine,
} from 'react-icons/ri'
import { useEffect } from 'react'
import { DateStatus } from '.'
import { useAttachments } from '../../../services/endpoints/freights/getAttachments'
import { useGetStubs } from '../../../services/endpoints/freights/getStubs'
import { toastError } from '../../../config/error/toastError'
import { FreightAttachmentsDocumentEnum, FreightAttachmentsTypeEnum } from '../../../services/types/EnumTypes'
import { useAppSelector } from '../../../store'
import { AccordionTables } from './accordionTables/AccordionTables'

interface DeliveredPhaseProps {
  onFinishFreightOpen: () => void
}
const phaseStatus = 'delivered'

export function DeliveredPhase({ onFinishFreightOpen }: DeliveredPhaseProps): JSX.Element {
  const toast = useToast()
  const { isOpen: showMoreInfo, onToggle: toggleMoreInfo } = useDisclosure()
  const { freight, statusHistory, isLoading } = useAppSelector(state => state.freightSlice)

  const filteredAttachments = freight?.attachments?.filter(
    attach => attach?.freight_status === phaseStatus && attach?.type === FreightAttachmentsTypeEnum.DEFAULT,
  )
  const filteredOccurences = freight?.attachments?.filter(
    attach =>
      attach?.freight_status === phaseStatus && attach?.type === FreightAttachmentsTypeEnum.OCCURRENCE,
  )
  const history = statusHistory?.find(statusOfPhase => statusOfPhase.status === 'finished')

  const {
    data: leftHandedAttachments,
    error: errorListAttachments,
    isError: isErrorListAttachments,
  } = useAttachments({
    freight_id: freight?.id,
    document: FreightAttachmentsDocumentEnum.STUB,
  })

  const { data: stubs } = useGetStubs({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    freight_id: freight!.id,
    status: 'approved',
  })

  useEffect(() => {
    if (isErrorListAttachments) {
      toastError({ toast, error: errorListAttachments })
    }
  }, [isErrorListAttachments, errorListAttachments, toast])

  return (
    <Flex justifyContent="space-between" direction="column" mb="4">
      <Flex direction="row" gridGap="2">
        <Text
          onClick={toggleMoreInfo}
          cursor="pointer"
          size="sm"
          color={['finished'].includes(String(freight?.status)) ? 'green.500' : 'gray.400'}
          fontWeight="bold"
          fontSize="sm"
        >
          <Button
            color="white"
            backgroundColor={['finished'].includes(String(freight?.status)) ? 'green.500' : 'gray.400'}
            borderRadius="100%"
            size="xs"
            mr="4"
          >
            6
          </Button>
          {['finished'].includes(String(freight?.status)) ? 'Frete finalizado' : 'Finalizar o frete'}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          direction={['column', 'row']}
          gridGap="1"
          mb={['-5', '0']}
          ml={['6', '0']}
        >
          {isLoading
            ? freight?.status === 'delivered' && <Spinner size="md" color="orange" />
            : filteredAttachments &&
              filteredAttachments.length > 0 && (
                <Tag size="sm" backgroundColor="orange" borderRadius="full">
                  <TagLeftIcon as={RiAttachmentLine} />
                  <TagLabel>{filteredAttachments.length}</TagLabel>
                </Tag>
              )}
          {isLoading
            ? freight?.status === 'delivered' && <Spinner size="md" color="red.400" />
            : filteredOccurences &&
              filteredOccurences.length > 0 && (
                <Tag size="sm" mx="1" borderRadius="full" backgroundColor="red.400">
                  <TagLeftIcon as={RiErrorWarningLine} />
                  <TagLabel>{filteredOccurences.length}</TagLabel>
                </Tag>
              )}
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="-3" mt="-2">
        <Flex direction={['column', 'row']} alignItems="center" gridGap="1" ml="10" my="2">
          {['delivered', 'finished'].includes(String(freight?.status)) && (
            <>
              {['finished'].includes(String(freight?.status)) && (
                <>
                  {isLoading || isLoading ? (
                    freight?.status === 'delivered' && <Spinner size="md" />
                  ) : (
                    <DateStatus freight={freight} history={history} />
                  )}
                </>
              )}
            </>
          )}

          {(leftHandedAttachments && leftHandedAttachments.length > 0) || (stubs && stubs.length) ? (
            <Flex>
              {freight?.status === 'delivered' && (
                <Button
                  size="xs"
                  h="6"
                  colorScheme="red"
                  leftIcon={<Icon as={RiThumbUpLine} />}
                  onClick={onFinishFreightOpen}
                >
                  Finalizar frete
                </Button>
              )}
            </Flex>
          ) : (
            freight?.status === 'delivered' && (
              <Alert h={10} borderRadius="full" status="warning">
                <AlertIcon />
                <AlertTitle mr={2}>Adicione um canhoto antes de finalizar o frete!</AlertTitle>
              </Alert>
            )
          )}
        </Flex>

        <Flex mt="-7">
          <IconButton
            onClick={toggleMoreInfo}
            variant="ghost"
            size="sm"
            aria-label="More info"
            icon={!showMoreInfo ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
          />
        </Flex>
      </Flex>

      <Collapse in={showMoreInfo} animateOpacity>
        <AccordionTables showMoreInfo={showMoreInfo} toggleMoreInfo={toggleMoreInfo} status={phaseStatus} />
      </Collapse>
    </Flex>
  )
}
