import { Box, Divider, Grid, GridItem, Text } from '@chakra-ui/react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import useSearchVehicle from '../../../../../hooks/useSearchVehicle'
import { useGetVehicleCategoriesCombox } from '../../../../../hooks/useVehicleCategoriesCombox'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'
import { VehicleResponseFormData } from '../../../../../services/endpoints/vehicles/getVehicle'
import { vehicleColorsDatamex } from '../../../../../services/utils/vehicleColorsDatamex'
import { UIInput } from '../../components/UI/UIInput'
import { UISelect } from '../../components/UI/UISelect'
import { UISelectAsync } from '../../components/UI/UISelectAsync'
import { UISwitch } from '../../components/UI/UISwitch'
import { TrailerFormValues } from './types'

type TrailerFormProps = {
  onVehicleFound?: (vehicle: VehicleResponseFormData) => void
  setValue: UseFormSetValue<TrailerFormValues>
  control: Control<TrailerFormValues>
  disabled?: boolean
}
// eslint-disable-next-line prettier/prettier
export const TrailerForm = ({ onVehicleFound, setValue, control, disabled }: TrailerFormProps): JSX.Element => {
  const { vehicleBodies } = useGetVehicleCategoriesCombox()
  const { loading, search } = useSearchVehicle({
    onSuccess: data => {
      if (data.trailer) {
        onVehicleFound?.(data.trailer as VehicleResponseFormData)
      }
    },
    type: 'trailer',
  })
  // const { isLoading: carsApiLoading, setLicensePlateTrailer, trailerApi } = useApiCarsRequestContext()

  // useEffect(() => {
  //   if (trailerApi) {
  //     reset({
  //       license_plate: trailerApi?.license_plate,
  //       renavam: trailerApi?.renavam,
  //       brand: trailerApi?.brand,
  //       color: trailerApi?.color,
  //       model: trailerApi?.model,
  //       model_year: trailerApi?.model_year,
  //       chassi: trailerApi?.chassi,
  //       release_year: trailerApi?.release_year,
  //       antt: trailerApi?.license_plate,
  //       license_uf: trailerApi?.license_uf,
  //       city_id: trailerApi?.cityComplete,
  //     })
  //   }
  // }, [reset, trailerApi])

  return (
    <Box pointerEvents={disabled ? 'none' : 'auto'} opacity={disabled ? 0.5 : 1}>
      <Text fontSize="xs" opacity={0.5} mb="2">
        Informações do veículo:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="renavam"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                type="number"
                label="Renavam"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e)
                  search(e.target.value)
                }}
                onSearch={search}
                isLoading={loading}
                isSearchable
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="license_plate"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="aaa9*99"
                label="Placa"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
                isSearchable
                // onSearch={setLicensePlateTrailer}
                // isLoading={carsApiLoading}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 6]}>
          <Controller
            control={control}
            name="vehicle_body_id"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                label="Reboque"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                options={[{ label: '', value: '' }, ...(vehicleBodies || [])]}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="release_year"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="9999"
                label="Ano fábrica"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="model_year"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="9999"
                label="Ano do Modelo"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 1]}>
          <Controller
            control={control}
            name="axes"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Eixos"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
                type="number"
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 5]}>
          <Controller
            control={control}
            name="brand"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Marca"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="model"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Modelo"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="chassi"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Chassi"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="capacity_m3"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                type="number"
                label="Capacidade (m3)"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="capacity_tara"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                type="number"
                label="Capacidade (TARA)"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="capacity_kg"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                type="number"
                label="Capacidade (kg)"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="antt"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="aaa9*99"
                label="Placa ANTT"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 6]}>
          <Controller
            control={control}
            name="color"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                label="Cor"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                options={vehicleColorsDatamex}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="has_insurance"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISwitch
                label="Tem seguro?"
                name="has_insurance"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                isChecked={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="has_tracker"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISwitch
                label="Tem rastreador?"
                name="has_tracker"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                isChecked={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
      </Grid>
      <Divider />
      <Text fontSize="xs" opacity={0.5} my="2">
        Informações de Endereço:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 6]}>
          <Controller
            control={control}
            name="city_id"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelectAsync
                label="Cidade"
                placeholder="Cidade"
                loadOptions={searchCitiesByName}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={option => {
                  setValue('license_uf', option?.uf || '')
                  onChange(option)
                }}
                value={value}
                isRequired
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 1]}>
          <Controller
            control={control}
            name="license_uf"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="UF"
                placeholder="UF"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
                isRequired
                isDisabled
              />
            )}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export const trailerInitialValues = {
  antt: '',
  model_year: '',
  release_year: '',
  chassi: '',
  model: '',
  brand: '',
  vehicle_body_id: '',
  color: '',
  renavam: '',
  axes: undefined,
  license_uf: '',
  license_plate: '',
  capacity_kg: undefined,
  capacity_m3: undefined,
  capacity_tara: undefined,
  city_id: undefined,
  has_insurance: false,
  has_tracker: false,
}
