import { CSSObject } from '@chakra-ui/react'

// eslint-disable-next-line consistent-return
export const colorTab = (hasPendencies: boolean): string | undefined => {
  if (!hasPendencies) {
    return 'green'
  }
  return undefined
}

export const disabled: CSSObject = { opacity: 0.5, cursor: 'not-allowed', color: 'gray' }
