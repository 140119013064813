import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  useToast,
} from '@chakra-ui/react'
import { forEach } from 'lodash'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import { toastError } from '../../../../../config/error/toastError'
import { usePutLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/PutLoadingRequest'
import { useUploadLoadingRequestAttachments } from '../../../../../services/endpoints/freights/loading-request/UploadLoadingRequestAttachments'
import {
  LoadingRequestAttachmentTypeEnum,
  LoadingRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'

type UploadLoadingRequestReceiptsModalProps = {
  isOpen: boolean
  onClose: () => void
}

type RequestParams = {
  id: string
}

type UploadReceiptsLoadingRequest = {
  loadingRequestReceipt: File
  advanceRequestReceipt?: File
  balanceRequestReceipt?: File
}

export function UploadLoadingRequestReceiptsModal({
  isOpen,
  onClose,
}: UploadLoadingRequestReceiptsModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue } = useForm({})
  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateLoadingRequestStatus, isLoading } = usePutLoadingRequest({
    onSuccess: () => {
      toast({
        title: 'Solicitação de carregamento atualizada com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const {
    mutateAsync: uploadLoadingRequestAttachments,
    isLoading: isLoadingUploadLoadingRequestAttachments,
  } = useUploadLoadingRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUploadReceiptsLoadingRequest: SubmitHandler<UploadReceiptsLoadingRequest> = async data => {
    const uploadFormData = {
      status: LoadingRequestStatusEnum.PAID,
    }
    const receipts = [
      {
        attachment: data.loadingRequestReceipt,
        type: LoadingRequestAttachmentTypeEnum.RECEIPT,
      },
      {
        attachment: data.advanceRequestReceipt,
        type: LoadingRequestAttachmentTypeEnum.ADVANCE_RECEIPT,
      },
      {
        attachment: data.balanceRequestReceipt,
        type: LoadingRequestAttachmentTypeEnum.BALANCE_RECEIPT,
      },
    ]

    await updateLoadingRequestStatus({ id, data: uploadFormData })

    forEach(receipts, async receipt => {
      if (receipt && receipt.attachment) {
        const formData = new FormData()

        formData.append('loading_request_id', id)
        formData.append('type', receipt.type)
        formData.append('attachment', receipt.attachment)

        await uploadLoadingRequestAttachments({ data: formData })
      }
    })

    onClose()
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Anexar comprovantes de pagamento</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUploadReceiptsLoadingRequest)} noValidate>
          <ModalBody>
            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="loadingRequestReceipt"
              label="Solicitação de carregamento"
            />

            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="balanceRequestReceipt"
              label="Solicitação de saldo"
            />

            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="advanceRequestReceipt"
              label="Solicitação de adiantamento"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="red"
              mr={3}
              onClick={() => {
                onClose()
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoading || isLoadingUploadLoadingRequestAttachments}
              isDisabled={isLoading || isLoadingUploadLoadingRequestAttachments}
            >
              Avançar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
