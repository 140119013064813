import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  Flex,
  FormLabel,
  GridItem,
  Heading,
  Icon,
  Image,
  Input as ChakraInput,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiDownload2Fill, RiSearch2Line, RiSendPlane2Line, RiUploadCloudLine } from 'react-icons/ri'
import CrlvPlaceholderImg from '../../../assets/crlv-placeholder.png'
import { AutocompleteAsync } from '../../../components/form/AutocompleteAsync'
import { Input } from '../../../components/form/Input'
import { InputMask } from '../../../components/form/InputMask'
import { Select } from '../../../components/form/Select'
import { Switch } from '../../../components/form/Switch'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useApiCarsRequestContext } from '../../../contexts/ApiCarsRequestContext'
import { searchCitiesByName } from '../../../services/endpoints/cities/searchCities'
import { usePostCrlvInformations } from '../../../services/endpoints/documents/ocr/post-crl-informations'
import { usePostConvertPdfToImage } from '../../../services/endpoints/documents/pdf/post-convert-pdf-to-image'
import {
  fetchTrailerByColumn,
  TrailerResponseFormData,
} from '../../../services/endpoints/trailers/getTrailerByColumn'
import { VehicleResponseFormData } from '../../../services/endpoints/vehicles/getVehicle'
import { getVehicleBodies } from '../../../services/endpoints/vehicles/getVehicleBodies'
import { fetchVehicleByColumn } from '../../../services/endpoints/vehicles/getVehicleByColumn'
import { getVehicleCategories } from '../../../services/endpoints/vehicles/getVehicleCategories'
import { VehicleType } from '../../../services/types'
import BlackListType from '../../../services/types/BlackListType'
import { TrailerType } from '../../../services/types/TrailerType'
import { capitalizeWord } from '../../../services/utils/capitalizeWord'
import { addMoreOneParamToUrl } from '../../../services/utils/format-url/addMoreOneParamToUrl'
import { parseBase64ToImage } from '../../../services/utils/parseImageToBase64'
import { isValidateWidthImage } from '../../../services/utils/validateWidthImage'
import { alterColorsGenderDatamex, vehicleColorsDatamex } from '../../../services/utils/vehicleColorsDatamex'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setCrlvTrailerFile, setCrlvVehicleFile, setCrlvVehicleImg } from '../../../store/slices/ocrCrlvSlice'
import {
  setHasTrailer,
  setTrailerFound,
  setTrailerId,
  setVehicleFound,
  setVehicleId,
} from '../../../store/slices/vehicleSlice'
import { handleLicensePlateIsBlackList } from '../../black-list/check-document-exists/handleLicensePlateIsBlackList'

type InitialData = VehicleType & TrailerType

interface FormProps {
  initialData?: Partial<InitialData | null>
  vehicle?: VehicleType
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  isTrailer: boolean
  renavamRef: React.RefObject<HTMLInputElement>
  refetch?: () => void
  handleCheckVehicleExists?: () => Promise<void>
  setIsLicensePlateInBlackList: (data: BlackListType | undefined) => void
  onOpen: () => void
}

export type crlvStateType = {
  releaseYear?: string
  axes?: string
  brandModelVersion?: string
  licensePlate?: string
  predominantColor?: string
  modelYear?: string
  renavam?: string
  brandName?: string
  chassis?: string
  vehicleCategory?: string
  capacity?: string
}
/// e
export function Form({
  initialData,
  vehicle,
  setValue,
  formState,
  isTrailer,
  renavamRef,
  handleCheckVehicleExists,
  setIsLicensePlateInBlackList,
  onOpen,
  refetch,
}: FormProps): JSX.Element {
  const convertImgToPdf = usePostConvertPdfToImage()
  const toast = useToast()
  const { hasTrailer } = useAppSelector(state => state.vehicleSlice)
  const dispatch = useAppDispatch()
  const { errors } = formState
  const { isLoading, setLicensePlateVehicle, carsApi, setLicensePlateTrailer, trailerApi } =
    useApiCarsRequestContext()
  const [vehicleCategories, setVehicleCategories] = useState<AutocompleteOption[]>()
  const [vehicleBodies, setVehicleBodies] = useState<AutocompleteOption[]>()
  const [licensePlateComplete, setLicensePlateComplete] = useState<boolean>(!!initialData?.license_plate)
  const [license_plate_vehicle, set_license_plate_vehicle] = useState(initialData?.license_plate || '')
  const [license_plate_trailer, set_license_plate_trailer] = useState(initialData?.license_plate || '')
  const { crlvVehicleImg, crlvVehicleFile, crlvTrailerFile } = useAppSelector(state => state.ocrCrlvSlice)
  const [ufAddress, setUfAddress] = useState(initialData?.license_uf || '')
  const [isOpenImageModal, setIsOpenImageModal] = useState(false)
  const [crlvInfo, setCrlvInfo] = useState<crlvStateType>({
    releaseYear: undefined,
    axes: undefined,
    brandModelVersion: undefined,
    licensePlate: undefined,
    predominantColor: undefined,
    modelYear: undefined,
    renavam: undefined,
    brandName: undefined,
    chassis: undefined,
    vehicleCategory: undefined,
    capacity: undefined,
  })
  const onOpenImageModal = () => {
    setIsOpenImageModal(true)
  }

  const onCloseImageModal = () => {
    setIsOpenImageModal(false)
  }

  const {
    mutate: postCrlvInformations,
    data: crlvInformations,
    isLoading: isCrlvInformationsLoading,
  } = usePostCrlvInformations({
    onSuccess: () => {
      toast({
        title: 'Dados de OCR carregados com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleSubmit = async () => {
    try {
      postCrlvInformations(crlvVehicleImg)
    } catch (errorSubmit) {
      toast({
        title: 'Erro ao enviar informações',
        description: errorSubmit.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
    }
  }

  useEffect(() => {
    getVehicleCategories().then(categories => setVehicleCategories(categories))
    getVehicleBodies().then(bodies => setVehicleBodies(bodies))
  }, [])

  useEffect(() => {
    if (crlvInformations) {
      setCrlvInfo({
        releaseYear: crlvInformations.manufactureYear,
        axes: crlvInformations.axes,
        brandModelVersion: crlvInformations.brandModelVersion,
        licensePlate: crlvInformations.licensePlate,
        predominantColor: crlvInformations.predominantColor,
        modelYear: crlvInformations.modelYear,
        renavam: crlvInformations.renavam,
        brandName: crlvInformations.brandName,
        chassis: crlvInformations.chassis,
        vehicleCategory: crlvInformations.vehicleCategory,
        capacity: crlvInformations.capacity,
      })
    }
  }, [crlvInformations])

  useEffect(() => {
    const loadAddress = async () => {
      let findVehicle: VehicleResponseFormData | undefined | null
      let findTrailer: TrailerResponseFormData | undefined | null
      if (crlvInfo?.renavam) {
        if (handleCheckVehicleExists) await handleCheckVehicleExists()
        if (!isTrailer) {
          try {
            findVehicle = await fetchVehicleByColumn({
              columnName: 'renavam',
              columnValue: crlvInfo.renavam,
            })
            if (findVehicle) {
              dispatch(setVehicleId(findVehicle.id))
              dispatch(setVehicleFound(findVehicle))
              addMoreOneParamToUrl('vehicle_id', findVehicle.id)
            }
          } catch (error) {
            dispatch(setVehicleId(''))
          } finally {
            await queryClient.invalidateQueries('vehicle')
          }
        } else {
          try {
            findTrailer = await fetchTrailerByColumn({
              columnName: 'renavam',
              columnValue: crlvInfo.renavam,
            })
            if (findTrailer) dispatch(setTrailerId(findTrailer.id))
            if (findTrailer) dispatch(setTrailerFound(findTrailer))
          } catch (error) {
            dispatch(setTrailerId(''))
          } finally {
            await queryClient.invalidateQueries('trailer')
          }
        }
      }
      if (!isTrailer) {
        if (crlvInfo?.licensePlate && !findVehicle && !findTrailer) {
          try {
            findVehicle = await fetchVehicleByColumn({
              columnName: 'licensePlate',
              columnValue: crlvInfo.licensePlate,
            })
            if (findVehicle) {
              dispatch(setVehicleId(findVehicle.id))
              dispatch(setVehicleFound(findVehicle))
              addMoreOneParamToUrl('vehicle_id', findVehicle.id)
            }
          } catch (error) {
            dispatch(setVehicleId(''))
          } finally {
            await queryClient.invalidateQueries('vehicle')
          }
        } else if (crlvInfo?.licensePlate && !findVehicle && findTrailer) {
          try {
            findTrailer = await fetchTrailerByColumn({
              columnName: 'licensePlate',
              columnValue: crlvInfo.licensePlate,
            })
            if (findTrailer) dispatch(setTrailerId(findTrailer.id))
            if (findTrailer) dispatch(setTrailerFound(findTrailer))
          } catch (error) {
            dispatch(setTrailerId(''))
          } finally {
            await queryClient.invalidateQueries('trailer')
          }
        }
      }
    }
    loadAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crlvInfo?.renavam, crlvInfo?.licensePlate])

  useEffect(() => {
    if (initialData?.city?.state?.uf) {
      setUfAddress(initialData.city.state.uf)
    } else {
      setUfAddress('')
    }
  }, [initialData?.city?.state?.uf])

  useEffect(() => {
    if (crlvInfo?.licensePlate) {
      setLicensePlateComplete(true)
      if (isTrailer) {
        set_license_plate_trailer(crlvInfo?.licensePlate)
      } else {
        set_license_plate_vehicle(crlvInfo?.licensePlate)
      }
    } else {
      setLicensePlateComplete(false)
    }
  }, [crlvInfo?.licensePlate, isTrailer])

  return (
    <>
      <GridItem
        colSpan={[12, 12, 3]}
        rowSpan={[1, 1, 3]}
        gap={6}
        w="full"
        display={vehicle && vehicle.trailers && vehicle.trailers.length === 0 ? 'none' : 'block'}
      >
        <Flex direction="row" gap={6} w="full" wrap="wrap" height="auto" justify="center">
          <FormLabel
            htmlFor={isTrailer ? 'img-preview-crlv-trailer' : 'img-preview-crlv-vehicle'}
            cursor="pointer"
          >
            <Heading size="sm">CRLV</Heading>

            {convertImgToPdf.isLoading ? (
              <Flex justify="center" align="center" h="24vh" w="100%">
                <Heading size="md">Convertendo PDF...</Heading>
              </Flex>
            ) : (
              <Image
                src={
                  crlvVehicleImg ||
                  initialData?.attachments?.find(item => item.type === 'crlv')?.attachment_file_url ||
                  CrlvPlaceholderImg
                }
                alt="CRLV"
                maxH="150px"
                maxW="100%"
                objectFit="cover"
                borderRadius="md"
                p={2}
                onClick={() => onOpenImageModal()}
                cursor="pointer"
              />
            )}
          </FormLabel>
          <ChakraInput
            id={isTrailer ? 'img-preview-crlv-trailer' : 'img-preview-crlv-vehicle'}
            name={isTrailer ? 'img-preview-crlv-trailer' : 'img-preview-crlv-vehicle'}
            type="file"
            display="none"
            onChange={async e => {
              const { files } = e.target
              if (files && files.length > 0) {
                const file = files[0]
                const isValidExtension = /\.(png|jpg|jpeg)$/i.test(file.name)

                if (isValidExtension) {
                  const isValidImage = await isValidateWidthImage(file)
                  if (!isValidImage) {
                    toast({
                      title: 'Imagem inválida',
                      description:
                        'Imagem pequena, tente carregar uma imagem com pelo menos 800px de largura',
                      status: 'error',
                      duration: 8000,
                      isClosable: true,
                      position: 'top',
                    })
                    return
                  }

                  if (isTrailer) {
                    dispatch(setCrlvTrailerFile(file))
                  } else {
                    dispatch(setCrlvVehicleFile(file))
                  }
                  const fileReader = new FileReader()

                  fileReader.onload = ev => {
                    const { result } = ev.target as FileReader
                    if (result) {
                      const imgData = result.toString()
                      dispatch(setCrlvVehicleImg(imgData))

                      toast({
                        title: 'Imagem carregada com sucesso!',
                        status: 'success',
                        position: 'top-right',
                        isClosable: true,
                      })
                    } else {
                      toast({
                        title: 'Erro ao ler o arquivo!',
                        status: 'error',
                        position: 'top-right',
                        isClosable: true,
                      })
                    }
                  }
                  fileReader.readAsDataURL(file)
                } else {
                  const isPdf = /\.pdf$/i.test(file.name)
                  if (isPdf) {
                    const pdfConverted = await convertImgToPdf.mutateAsync({ file, slicePdf: false })
                    const imageConvertedToFile = await parseBase64ToImage(pdfConverted.imageConverted)

                    if (pdfConverted) {
                      const imgData = pdfConverted.imageConverted
                      if (isTrailer) {
                        dispatch(setCrlvTrailerFile(imageConvertedToFile))
                        dispatch(setCrlvVehicleImg(imgData))
                        toast({
                          title: 'PDF do reboque convertido com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      } else {
                        dispatch(setCrlvVehicleFile(imageConvertedToFile))
                        dispatch(setCrlvVehicleImg(imgData))
                        toast({
                          title: 'PDF do veículo convertido com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      }
                    } else {
                      toast({
                        title: 'Erro ao converter PDF!',
                        status: 'error',
                        position: 'top-right',
                        isClosable: true,
                      })
                    }
                  } else {
                    toast({
                      title: 'Formato inválido! Envie um arquivo em PNG, PDF ou JPEG!',
                      status: 'error',
                      position: 'top-right',
                      isClosable: true,
                    })
                  }
                }
              }
            }}
          />
          <Flex gap={4} direction="column" flex="1" justifyContent="flex-end">
            <Button
              bg="orange.500"
              color="white"
              onClick={() =>
                document
                  .getElementById(isTrailer ? 'img-preview-crlv-trailer' : 'img-preview-crlv-vehicle')
                  ?.click()
              }
              leftIcon={<Icon as={RiUploadCloudLine} />}
            >
              Carregar arquivo
            </Button>
            <Button
              colorScheme="green"
              onClick={handleSubmit}
              mt={2}
              leftIcon={<Icon as={RiSendPlane2Line} />}
              isLoading={isCrlvInformationsLoading}
              isDisabled={!crlvVehicleFile && !crlvTrailerFile}
            >
              Buscar dados via OCR
            </Button>
          </Flex>
        </Flex>
      </GridItem>

      {vehicle && vehicle.trailers && vehicle.trailers.length === 0 ? (
        <GridItem colSpan={12}>
          <Alert status="warning" my="4" borderRadius="4">
            <AlertIcon />

            <Flex>Vincule um reboque para continuar!</Flex>
          </Alert>
        </GridItem>
      ) : (
        <>
          <GridItem colSpan={[12, 3, 3]}>
            <Input
              ref={renavamRef}
              autoFocus
              name="renavam"
              label="Renavam"
              error={errors.renavam}
              setValue={setValue}
              onBlur={async () => {
                if (handleCheckVehicleExists) await handleCheckVehicleExists()
              }}
              onChange={async e => {
                if (handleCheckVehicleExists) await handleCheckVehicleExists()
                if (e.target.value.length > 9) {
                  if (isTrailer) {
                    try {
                      const findTrailer = await fetchTrailerByColumn({
                        columnName: 'renavam',
                        columnValue: e.target.value,
                      })
                      if (findTrailer) {
                        dispatch(setTrailerFound(findTrailer))
                        if (refetch) refetch()
                      }
                    } catch (error) {
                      dispatch(setTrailerId(''))
                    }
                  } else {
                    set_license_plate_vehicle(e.target.value)
                    try {
                      const findVehicle = await fetchVehicleByColumn({
                        columnName: 'renavam',
                        columnValue: e.target.value,
                      })
                      if (findVehicle) {
                        dispatch(setVehicleId(findVehicle.id))
                        dispatch(setVehicleFound(findVehicle))
                        addMoreOneParamToUrl('vehicle_id', findVehicle.id)
                      }
                    } catch (error) {
                      dispatch(setTrailerId(''))
                    } finally {
                      queryClient.invalidateQueries('vehicle')
                    }
                    try {
                      const findTrailer = await fetchTrailerByColumn({
                        columnName: 'renavam',
                        columnValue: e.target.value,
                      })
                      if (findTrailer) dispatch(setTrailerId(findTrailer.id))
                    } catch (error) {
                      dispatch(setTrailerId(''))
                    }
                  }
                }
              }}
              initialValue={
                crlvInfo?.renavam || carsApi?.renavam || trailerApi?.renavam || initialData?.renavam
              }
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 3, 3]} minW={200} gridGap={2} display="flex" alignItems="center">
            <InputMask
              name="license_plate"
              mask="aaa9*99"
              maskPlaceholder=""
              label="Placa"
              error={errors.license_plate}
              setValue={setValue}
              initialValue={crlvInfo.licensePlate || initialData?.license_plate}
              onChange={async e => {
                if (e.target.value.length === 7) {
                  if (isTrailer) {
                    set_license_plate_trailer(e.target.value)
                  } else {
                    set_license_plate_vehicle(e.target.value)
                  }
                  setLicensePlateComplete(true)
                } else {
                  setLicensePlateComplete(false)
                }
              }}
              onBlur={async e => {
                try {
                  const data = await handleLicensePlateIsBlackList(e.target.value)
                  set_license_plate_vehicle(e.target.value)
                  if (data) {
                    setIsLicensePlateInBlackList(data)
                    onOpen()
                  } else {
                    setIsLicensePlateInBlackList(undefined)
                  }
                } catch (error) {
                  toastError({ toast, error })
                }
              }}
              uppercaseAll
              isRequired
            />

            {licensePlateComplete && (
              <Button
                mt={8}
                p={2}
                size="lg"
                colorScheme="green"
                isLoading={isLoading}
                onClick={() => {
                  if (isTrailer) {
                    setLicensePlateTrailer(license_plate_trailer)
                  } else {
                    setLicensePlateVehicle(license_plate_vehicle)
                  }
                }}
              >
                <Icon as={RiSearch2Line} />
              </Button>
            )}
          </GridItem>

          {vehicleCategories && vehicleBodies && (
            <>
              {!isTrailer && (
                <GridItem colSpan={[12, 6, 6, 3]} minW={200}>
                  <Select
                    minW={200}
                    name="vehicle_category_id"
                    label="Categoria"
                    error={errors.vehicle_category_id}
                    setValue={setValue}
                    initialValue={initialData?.vehicle_category?.id}
                    onSelectOption={option => {
                      dispatch(setHasTrailer(option.has_trailer))
                    }}
                    options={[{ label: '', value: '' }, ...(vehicleCategories as SelectOption[])]}
                    isRequired
                  />
                </GridItem>
              )}

              <GridItem colSpan={[12, 6, 3]} minW={200}>
                <Select
                  name="vehicle_body_id"
                  label={hasTrailer ? 'Reboque' : 'Carroceria'}
                  error={errors.vehicle_body_id}
                  setValue={setValue}
                  initialValue={initialData?.vehicle_body?.id}
                  options={[{ label: '', value: '' }, ...(vehicleBodies as SelectOption[])]}
                  isRequired
                />
              </GridItem>
            </>
          )}
          <GridItem colSpan={[12, 3, 3]} minW={200}>
            <InputMask
              mask="9999"
              name="release_year"
              label="Ano fábrica"
              maskPlaceholder=""
              error={errors.release_year}
              setValue={setValue}
              initialValue={
                crlvInfo?.releaseYear || isTrailer
                  ? trailerApi?.release_year || initialData?.release_year?.toString()
                  : carsApi?.release_year || initialData?.release_year?.toString()
              }
            />
          </GridItem>
          <GridItem colSpan={[12, 2, 2]} minW={150}>
            <InputMask
              mask="9999"
              name="model_year"
              label="Ano Modelo"
              maskPlaceholder=""
              error={errors.model_year}
              setValue={setValue}
              initialValue={
                crlvInfo.modelYear || isTrailer
                  ? trailerApi?.model_year || initialData?.model_year?.toString()
                  : carsApi?.model_year || initialData?.model_year?.toString()
              }
            />
          </GridItem>
          <GridItem colSpan={[12, 2, 1]}>
            <Input
              name="axes"
              label="Eixos"
              error={errors.axes}
              setValue={setValue}
              initialValue={(
                carsApi?.axes ||
                trailerApi?.axes ||
                crlvInfo.axes ||
                initialData?.axes
              )?.toString()}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 3, isTrailer ? 3 : 3]}>
            <Input
              name="brand"
              label="Marca"
              error={errors.brand}
              setValue={setValue}
              initialValue={carsApi?.brand || trailerApi?.brand || crlvInfo.brandName || initialData?.brand}
              uppercaseFirst
            />
          </GridItem>
          <GridItem colSpan={[12, 6, isTrailer ? 4 : 2]}>
            <Input
              name="model"
              label="Modelo"
              error={errors.model}
              setValue={setValue}
              initialValue={
                carsApi?.model || trailerApi?.model || crlvInfo.brandModelVersion || initialData?.model
              }
              uppercaseFirst
            />
          </GridItem>

          <GridItem colSpan={[12, 4, isTrailer ? 5 : 4]}>
            <Input
              name="chassi"
              label="Chassi"
              error={errors.chassi}
              setValue={setValue}
              initialValue={carsApi?.chassi || trailerApi?.chassi || crlvInfo.chassis || initialData?.chassi}
              uppercaseAll
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 3]}>
            <InputMask
              mask="99999999999999"
              maskPlaceholder=""
              registerOnlyNumbers
              name="capacity_m3"
              label="Capacidade (m3)"
              error={errors.capacity_m3}
              setValue={setValue}
              initialValue={initialData?.capacity_m3?.toString()}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 3]}>
            <InputMask
              mask="99999999999999"
              maskPlaceholder=""
              registerOnlyNumbers
              name="capacity_tara"
              label="Capacidade (TARA)"
              error={errors.capacity_tara}
              setValue={setValue}
              initialValue={initialData?.capacity_tara?.toString()}
              isRequired
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 2]}>
            <InputMask
              mask="99999999999999"
              maskPlaceholder=""
              registerOnlyNumbers
              name="capacity_kg"
              label="Capacidade (kg)"
              error={errors.capacity_kg}
              setValue={setValue}
              initialValue={
                !Number.isNaN(Number(crlvInfo.capacity))
                  ? (Number(crlvInfo.capacity) * 1000)?.toString()
                  : initialData?.capacity_kg?.toString()
              }
              isRequired
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 2]}>
            <InputMask
              name="antt"
              mask="aaa9*99"
              label="Placa ANTT"
              maskPlaceholder=""
              error={errors.antt}
              setValue={setValue}
              initialValue={
                license_plate_vehicle || license_plate_trailer || crlvInfo.licensePlate || initialData?.antt
              }
              uppercaseAll
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 2]}>
            <Select
              name="color"
              label="Cor"
              options={vehicleColorsDatamex}
              error={errors.color}
              setValue={setValue}
              initialValue={
                vehicleColorsDatamex.find(
                  item =>
                    capitalizeWord(alterColorsGenderDatamex(item.label)) ===
                    (capitalizeWord(alterColorsGenderDatamex(carsApi?.color)) ||
                      capitalizeWord(alterColorsGenderDatamex(trailerApi?.color)) ||
                      capitalizeWord(alterColorsGenderDatamex(crlvInfo.predominantColor)) ||
                      capitalizeWord(alterColorsGenderDatamex(initialData?.color))),
                )?.label ?? ''
              }
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 3]}>
            <Switch
              name="has_insurance"
              label="Tem seguro?"
              error={errors.has_insurance}
              setValue={setValue}
              initialValue={initialData?.has_insurance}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 3]}>
            <Switch
              name="has_tracker"
              label="Tem rastreador?"
              error={errors.has_tracker}
              setValue={setValue}
              initialValue={initialData?.has_tracker}
            />
          </GridItem>

          <GridItem colSpan={12}>
            <Divider my={4} />
          </GridItem>

          <GridItem colSpan={12}>
            <Heading size="md">Endereço dos documentos</Heading>
          </GridItem>

          <GridItem colSpan={[12, 6, 4]}>
            <AutocompleteAsync
              name="city_id"
              label="Município"
              error={errors.city_id}
              setValue={setValue}
              initialValue={
                isTrailer
                  ? trailerApi?.cityComplete ||
                    (initialData?.city && {
                      label: `${initialData?.city?.name} - ${initialData?.city?.state?.uf}`,
                      value: String(initialData?.city?.ibge_id),
                    })
                  : carsApi?.cityComplete ||
                    (initialData?.city && {
                      label: `${initialData?.city?.name} - ${initialData?.city?.state?.uf}`,
                      value: String(initialData?.city?.ibge_id),
                    })
              }
              loadOptions={searchCitiesByName}
              onSelectOption={option => {
                setUfAddress(option.uf)
              }}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 3, 1]}>
            <Input
              setValue={setValue}
              name="license_uf"
              label="UF"
              error={errors.license_uf}
              initialValue={ufAddress || carsApi?.cityComplete?.uf || trailerApi?.cityComplete?.uf}
              isRequired
              isDisabled={!!(ufAddress || carsApi?.cityComplete?.uf || trailerApi?.cityComplete?.uf)}
            />
          </GridItem>

          {initialData?.attachments &&
            initialData?.attachments.length > 0 &&
            initialData?.attachments.map(
              attachment =>
                attachment.attachment_file_url && (
                  <>
                    <GridItem colSpan={12}>
                      <Heading size="md">Anexos</Heading>
                    </GridItem>

                    <GridItem colSpan={12}>
                      <Link color="blue.200" href={attachment.attachment_file_url} target="_blank">
                        Baixar {attachment.name} &nbsp;
                        <Icon as={RiDownload2Fill} />
                      </Link>
                    </GridItem>
                  </>
                ),
            )}
        </>
      )}
      <Modal isOpen={isOpenImageModal} onClose={onCloseImageModal} size="full">
        <ModalOverlay />
        <Flex align="center" justify="center" height="100vh">
          <ModalContent margin="auto">
            <ModalHeader>CRLV Ampliado</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" alignItems="center" justifyContent="center" height="100%">
              <Image
                src={
                  crlvVehicleImg ||
                  initialData?.attachments?.find(attachment => attachment.type === 'crlv')
                    ?.attachment_file_url
                }
                alt="Amplified Image"
                maxW="100%"
                maxH="100vh"
                objectFit="contain"
              />
            </ModalBody>
          </ModalContent>
        </Flex>
      </Modal>
    </>
  )
}
