import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { format, isBefore } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { AiFillAlert } from 'react-icons/ai'
import { FaCopy, FaEdit, FaMap, FaMapMarkerAlt, FaMapPin } from 'react-icons/fa'
import { GiCommercialAirplane } from 'react-icons/gi'
import { RiMenuLine, RiRadarFill, RiSmartphoneLine, RiWhatsappLine } from 'react-icons/ri'
import { TbBrandGoogleMaps } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { CheckFreightIsExpired } from '../../components/CheckFreightIsExpired'
import DataGrid from '../../components/DataGrid'
import { IconHasMonitoringTrackerDevices } from '../../components/IconHasMonitoringTrackerDevices'
import { RemainDistanceResult } from '../../components/RemainDistanceResult'
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext'
import { Logo } from '../../layout/Header/Logo'
import { Profile } from '../../layout/Header/Profile'
import { Sidebar } from '../../layout/Sidebar'
import {
  FormattedAllFreightsInProgress,
  useGetFreightsInProgress,
} from '../../services/endpoints/freights/getFreightsInProgress'
import { useGetOneFreight } from '../../services/endpoints/freights/getOneFreight'
import { FreightType } from '../../services/types'
import { copyToClipboard } from '../../services/utils/copyToClipboard'
import formatPhoneNumber from '../../services/utils/formatPhone'
import { EditOrDuplicateFreightModal } from '../freights/edit/EditOrDuplicateFreightModal'
import { buildRoutePlannerUrl } from '../freights/show/cards/freight-info'
import MapFreight from './map'
import { AddInteractionModal } from './modals/AddInteractionModal'
import { AddTripObservationsModal } from './modals/AddTripObservationsModal'
import FreightStatusPopover from './modals/FreightStatusPopover'
import { ShowFreightInfoModal } from './modals/ShowFreightInfoModal'

export const formatToShortName = (analyst: string): string => {
  const name = analyst.split(' ')
  if (name.length < 2) return analyst

  return `${name[0]} ${name[1].charAt(0)}.`
}

export const formatDate = (date: string): string => {
  return format(new Date(date), "dd/MM/yy 'às' HH:mm")
}

const FreightsInProgress = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const bg = useColorModeValue('gray.100', 'gray.800')
  const { onOpen: openMenu } = useSidebarDrawer()
  const [oneFreightId, setOneFreightId] = useState('')
  const [freightIdToEdit, setFreightIdToEdit] = useState('')
  const [tableData, setTableData] = useState<'all' | 'monitoring' | 'unmonitoring' | 'aerials'>('all')
  const [freightToShow, setFreightToShow] = useState<FreightType>()

  const { data: oneFreight, isLoading: loadingOneFreight } = useGetOneFreight({
    freight_id: oneFreightId !== '' ? oneFreightId : '',
    relations: [
      'creator',
      'origin',
      'destination',
      'client',
      'client_contact',
      'client_feedback',
      'seller',
      'motorist',
      'attachments',
      'motorist.address_city',
      'motorist.address_city.state',
      'motorist.driver_license',
      'vehicle',
      'vehicle.owner',
      'vehicle.antt_owner',
      'vehicle.owner.city',
      'vehicle.vehicle_category',
      'vehicle.vehicle_body',
      'vehicle_categories',
      'vehicle_bodies',
      'trailer',
      'trailer.owner',
      'trailer.owner.city',
      'trailer.vehicle_body',
      'cargo',
      'rate',
      'nps_motorists',
      'ctes',
      'freight_expenses',
    ],
  })

  const {
    data: allFreightsData,
    isLoading,
    isFetching,
  } = useGetFreightsInProgress({
    page: 1,
    per_page: 100,
  })

  const {
    data: freightsNotMonitoringData,
    isLoading: isLoadingFreightsNotMonitoring,
    isFetching: isFetchingFreightsNotMonitoring,
  } = useGetFreightsInProgress({
    is_monitoring: false,
    page: 1,
    per_page: 100,
  })

  const {
    data: freightsMonitoringData,
    isLoading: isLoadingFreightsMonitoring,
    isFetching: isFetchingFreightsMonitoring,
  } = useGetFreightsInProgress({
    is_monitoring: true,
    page: 1,
    per_page: 100,
  })

  const freightsAerialsData = useMemo(() => {
    if (allFreightsData) {
      const filteredData = allFreightsData.data.filter(f => f.type === 'aerial')

      return {
        data: filteredData,
        total: filteredData.length,
      }
    }
    return { data: [], total: 0 }
  }, [allFreightsData])

  const messages = {
    noData: 'Nenhum dado encontrado',
    noLocations: 'Nenhuma localização encontrada',

    viewAddress: 'Visualizar endereço de coleta e entrega',
    viewOnMap: 'Visualizar no mapa',
    viewGoogleMaps: 'Visualizar no Google Maps',
  }

  const columnsMemo = useMemo(
    () =>
      [
        {
          id: 'actions',
          header: 'Ações',
          size: 140,
          enableResizing: false,

          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, any>): JSX.Element => {
            const { last_motorist_waypoint } = row.original

            return (
              <Flex gridGap={2} p={2}>
                <Tooltip label={last_motorist_waypoint ? messages.viewOnMap : messages.noLocations}>
                  <IconButton
                    as="a"
                    aria-label="ver no mapa"
                    target="_blank"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation()
                      setOneFreightId(prev => (prev === row.original.id ? '' : row.original.id))
                    }}
                    disabled={!last_motorist_waypoint}
                    size="xs"
                    icon={<FaMap />}
                  />
                </Tooltip>
                <Tooltip label={messages.viewAddress}>
                  <IconButton
                    as="a"
                    aria-label="Visualizar endereço de coleta e entrega"
                    target="_blank"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation()
                      setFreightToShow(row.original)
                    }}
                    size="xs"
                    icon={<FaMapPin />}
                  />
                </Tooltip>
                <Tooltip label={last_motorist_waypoint ? messages.viewGoogleMaps : messages.noLocations}>
                  <IconButton
                    as="a"
                    aria-label="Visualizar no google maps"
                    target="_blank"
                    disabled={!last_motorist_waypoint}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation()
                    }}
                    href={
                      last_motorist_waypoint &&
                      `https://www.google.com/maps/search/?api=1&query=${last_motorist_waypoint.lat},${last_motorist_waypoint.lng}`
                    }
                    size="xs"
                    icon={<TbBrandGoogleMaps />}
                  />
                </Tooltip>
                <Tooltip label="Editar informações do frete">
                  <IconButton
                    as="a"
                    aria-label="Editar informações do frete"
                    target="_blank"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation()
                      setFreightIdToEdit(prev => (prev === row.original.id ? '' : row.original.id))
                      onOpen()
                    }}
                    size="xs"
                    icon={<FaEdit />}
                  />
                </Tooltip>
              </Flex>
            )
          },
        },
        {
          header: 'Status',
          id: 'status',
          accessorKey: 'status',

          meta: {
            filterVariant: 'select',
            options: [
              {
                value: 'pending',
                label: 'Aberto',
              },
              {
                value: 'in_origin',
                label: 'Contratado',
              },
              {
                value: 'collecting',
                label: 'Saiu para coleta',
              },
              {
                value: 'ready',
                label: 'Chegou para carregar',
              },
              {
                value: 'on_road',
                label: 'Começou a viagem',
              },
              {
                value: 'in_destination',
                label: 'Chegou para descarregar',
              },
              {
                value: 'delivered',
                label: 'Entrega Finalizada',
              },
              {
                value: 'finished',
                label: 'Finalizado',
              },
            ],
          },
          cell: ({ getValue, row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return (
              <Box p={2} key={row.original.id}>
                <FreightStatusPopover
                  status={getValue()}
                  freight_id={row.original.id}
                  next_status={row.original.next_status?.value || ''}
                />
              </Box>
            )
          },
        },
        {
          header: 'Frete',
          id: 'freight_number',
          accessorKey: 'freight_number',
          meta: {
            type: 'number',
          },
          filterFn: (row, columnId, filterValue) => {
            const freight = row.getValue(columnId) as number
            return freight.toString().includes(filterValue.toString())
          },

          size: 160,
          cell: ({ getValue, row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            const id = getValue()
            const { client, type } = row.original
            return (
              <Flex p={2}>
                <Tooltip placement="auto" label={`Ver frete de ${client?.nickname}`}>
                  <Text
                    as={Link}
                    fontWeight="bold"
                    target="_blank"
                    to={`/freights/show/${row.original.id}`}
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {id} / {client.nickname}
                    {type === 'aerial' && (
                      <Flex fontSize="xs" pb="0.5" mb="0.5">
                        <Text mr={2} fontWeight="bold">
                          Frete Aéreo
                        </Text>
                        <Flex mt="0.5">
                          <GiCommercialAirplane />
                        </Flex>
                      </Flex>
                    )}
                  </Text>
                </Tooltip>
              </Flex>
            )
          },
        },
        {
          header: 'Data de coleta',
          accessorKey: 'collect_cargo_at',
          id: 'collect_cargo_at',
          meta: {
            filterVariant: 'date',
          },
          cell: ({ getValue }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return <Box p={2}>{formatDate(getValue())}</Box>
          },
        },
        {
          header: 'Data de entrega',
          accessorKey: 'delivered_cargo_at',
          id: 'delivered_cargo_at',
          meta: {
            filterVariant: 'date',
          },
          cell: ({ getValue }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return <Box p={2}> {formatDate(getValue())} </Box>
          },
        },
        {
          header: 'Tempo restante',
          id: 'remaining_time',
          size: 170,
          accessorKey: 'remaining_time',
          meta: {
            filterVariant: 'datetime-local',
          },
          filterFn: (row, columnId, filterValue) => {
            const date = new Date(
              String(
                ['ready', 'on_road', 'in_destination'].includes(row.original.status)
                  ? row.original.delivered_cargo_at
                  : row.original.collect_cargo_at,
              ),
            )
            return isBefore(date, new Date(filterValue))
          },
          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return (
              <Box p={2}>
                {!['in_destination', 'delivered', 'finished', 'disabled'].includes(row.original.status) && (
                  <Tag bg="whiteAlpha.800" rounded="full" key={row.original.id}>
                    <CheckFreightIsExpired freight={row.original} />
                  </Tag>
                )}
              </Box>
            )
          },
        },

        {
          header: 'Analista',
          accessorKey: 'creator.name',
          id: 'analyst',
          cell: ({ getValue }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return <Box p={2}>{formatToShortName(getValue())}</Box>
          },
        },
        {
          header: 'Origem',
          id: 'origin',
          accessorKey: 'origin.name',
          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return (
              <Box p={2}>
                {row.original.origin?.name} - {row.original.origin?.state?.uf}
              </Box>
            )
          },
        },
        {
          header: 'Destino',
          id: 'destination',
          accessorKey: 'destination.name',
          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return (
              <Box p={2}>
                {row.original.destination?.name} - {row.original.destination?.state?.uf}
              </Box>
            )
          },
        },
        {
          header: 'Motorista',
          id: 'motorist',
          accessorKey: 'motorist.name',
          cell: ({ getValue, row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            const { motorist_id } = row.original

            return (
              <Box p={2}>
                <Tooltip label="Ir para pagina do motorista">
                  <Text as={Link} target="_blank" to={`/motorists/show/${motorist_id}`} size="sm">
                    {formatToShortName(getValue())}
                  </Text>
                </Tooltip>
              </Box>
            )
          },
        },
        {
          header: 'Tel. Motorista',
          id: 'motorist_phone',
          accessorKey: 'motorist.phone',
          cell: ({ getValue }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return (
              <Flex alignItems="center" gridGap={2} p={2}>
                <IconButton
                  aria-label="copiar"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    copyToClipboard(getValue())
                  }}
                  size="xs"
                  icon={<FaCopy />}
                />
                {formatPhoneNumber(getValue())}
              </Flex>
            )
          },
        },
        {
          header: 'Últ. Localização',
          id: 'lastLocation',
          accessorKey: 'lastLocation',
          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            const { last_waypoint_date, last_motorist_waypoint } = row.original
            if (!last_waypoint_date) return <Box p={2}> N/A</Box>
            return (
              <Flex p={2} gridGap={2}>
                <Tooltip label={last_motorist_waypoint ? messages.viewGoogleMaps : messages.noLocations}>
                  <IconButton
                    as="a"
                    aria-label="Visualizar no google maps"
                    target="_blank"
                    disabled={!last_motorist_waypoint}
                    href={
                      last_motorist_waypoint &&
                      `https://www.google.com/maps/search/?api=1&query=${last_motorist_waypoint.lat},${last_motorist_waypoint.lng}`
                    }
                    size="xs"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation()
                    }}
                    icon={<TbBrandGoogleMaps />}
                  />
                </Tooltip>

                {last_waypoint_date ? (
                  <Flex alignItems="center" gridGap={2}>
                    {row.original.lastWaypointInteraction && <AiFillAlert color="yellow" />}

                    <Tooltip label="ver no mapa">
                      <Text
                        onClick={() => {
                          setOneFreightId(prev => (prev === row.original.id ? '' : row.original.id))
                        }}
                        cursor="pointer"
                      >
                        {last_waypoint_date}{' '}
                      </Text>
                    </Tooltip>
                  </Flex>
                ) : (
                  <Icon as={FaMapMarkerAlt} fontSize={20} />
                )}
              </Flex>
            )
          },
        },
        {
          header: 'Fonte',
          accessorKey: 'source',
          meta: {
            filterVariant: 'select',
            options: [
              {
                value: 'app',
                label: 'App',
              },
              {
                value: 'buonny',
                label: 'Buonny',
              },
              {
                value: 'whatsapp',
                label: 'Whatsapp',
              },
              {
                value: 'tracker_devices',
                label: 'Dispositivos de rastreamento',
              },
            ],
          },
          filterFn: (row, columnId, filterValue) => {
            const {
              has_monitoring_app,
              has_monitoring_buonny,
              has_monitoring_whatsapp,
              has_monitoring_tracker_devices,
            } = row.original
            if (filterValue === 'app' && has_monitoring_app) return true
            if (filterValue === 'buonny' && has_monitoring_buonny) return true
            if (filterValue === 'whatsapp' && has_monitoring_whatsapp) return true
            if (filterValue === 'tracker_devices' && has_monitoring_tracker_devices) return true
            return false
          },
          id: 'source',
          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            const {
              has_monitoring_app,
              has_monitoring_buonny,
              has_monitoring_whatsapp,
              has_monitoring_tracker_devices,
            } = row.original
            return (
              <Flex gridGap={2} p={2}>
                {has_monitoring_app && (
                  <Tooltip hasArrow placement="top" label="Rastreado pelo aplicativo">
                    <Box>
                      <Icon as={RiSmartphoneLine} fontSize={20} />
                    </Box>
                  </Tooltip>
                )}
                {has_monitoring_buonny && (
                  <Tooltip hasArrow placement="top" label="Rastreado pela Buonny">
                    <Box>
                      <Icon as={RiRadarFill} fontSize={20} />
                    </Box>
                  </Tooltip>
                )}
                {has_monitoring_whatsapp && (
                  <Tooltip hasArrow placement="top" label="Rastreado pelo WhatsApp">
                    <Box>
                      <Icon as={RiWhatsappLine} fontSize={20} />
                    </Box>
                  </Tooltip>
                )}
                {has_monitoring_tracker_devices && (
                  <IconHasMonitoringTrackerDevices
                    has_monitoring_tracker_devices={has_monitoring_tracker_devices}
                    color="black.500"
                  />
                )}
              </Flex>
            )
          },
        },
        {
          header: 'KM percorridos',
          accessorKey: 'total_distance',
          id: 'total_distance',
          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            const distance = Number(row.original?.distance || 0)
            const distanceToRoute = row.original?.distance_to_route

            if (!distanceToRoute) {
              return (
                <Text
                  size="sm"
                  px={2}
                  target="_blank"
                  onClick={() => {
                    window.open(buildRoutePlannerUrl(row.original, row.original.vehicle), '_blank')
                  }}
                  _hover={{ textDecoration: 'underline' }}
                >
                  Certifique-se que a rota está preenchida
                </Text>
              )
            }
            return <Box p={2}>{`${Number(distance - distanceToRoute).toFixed(2)}km`}</Box>
          },
        },
        {
          header: 'KM restantes',
          accessorKey: 'remaining_distance',
          id: 'remaining_distance',
          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return (
              <Box p={2}>
                <RemainDistanceResult freight={row.original} />
              </Box>
            )
          },
        },
        {
          header: 'localização',
          accessorKey: 'location',
          id: 'location',
          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return (
              <Box p={2}>
                <AddTripObservationsModal freight={row.original} />
              </Box>
            )
          },
        },
        {
          header: 'Observações',
          accessorKey: 'observations',
          id: 'observations',

          cell: ({ row }: CellContext<FormattedAllFreightsInProgress, string>): JSX.Element => {
            return (
              <Flex p={2}>
                {row.original?.lastObservationInteraction && <AiFillAlert color="yellow" />}

                <AddInteractionModal freight={row.original} />
              </Flex>
            )
          },
        },
      ] as ColumnDef<FormattedAllFreightsInProgress>[],
    [messages.noLocations, messages.viewAddress, messages.viewGoogleMaps, messages.viewOnMap, onOpen],
  )

  return (
    <Flex direction="column" gridGap={4} p="4" bg={bg} minHeight="100vh">
      <Sidebar SidebarMobile />

      <Flex align="center" gridGap={3} justifyContent="space-between">
        <Flex align="center">
          <IconButton
            mt={2}
            aria-label="Open navigation"
            icon={<Icon as={RiMenuLine} />}
            fontSize="24"
            variant="link"
            onClick={openMenu}
          />

          <Logo />
        </Flex>
        <Profile />
      </Flex>
      <Flex flex={1} direction="column">
        <MapFreight
          loadingOneFreight={loadingOneFreight}
          oneFreight={oneFreight}
          setOneFreightId={setOneFreightId}
        />

        <DataGrid
          data={
            {
              all: allFreightsData?.data,
              monitoring: freightsMonitoringData?.data || [],
              unmonitoring: freightsNotMonitoringData?.data || [],
              aerials: freightsAerialsData?.data || [],
            }[tableData] || []
          }
          columns={columnsMemo || []}
          isLoading={isLoading || isLoadingFreightsNotMonitoring || isLoadingFreightsMonitoring}
          isFetching={isFetching || isFetchingFreightsNotMonitoring || isFetchingFreightsMonitoring}
          tableHeight="calc(100vh - 140px)"
          showPagination={false}
          rowStyles={row => {
            return {
              background: row.original?.lastWaypointInteraction
                ? '#ff8c8c'
                : row.original?.lastObservationInteraction
                ? '#ff8c8c'
                : '',
            }
          }}
          renderLeftControls={() => (
            <ButtonGroup size="md" isAttached variant="outline" flexDirection={{ base: 'column', md: 'row' }}>
              <Button
                fontSize="sm"
                colorScheme={tableData === 'all' ? 'blue' : 'gray'}
                bg={tableData === 'all' ? 'blackAlpha.100' : '.200'}
                onClick={() => {
                  setTableData('all')
                }}
              >
                Todos os fretes {allFreightsData?.total}
              </Button>
              <Button
                fontSize="sm"
                bg={tableData === 'monitoring' ? 'blackAlpha.100' : 'transparent'}
                colorScheme={tableData === 'monitoring' ? 'blue' : 'gray'}
                onClick={() => {
                  setTableData('monitoring')
                }}
              >
                Rastreados {freightsMonitoringData?.total}
              </Button>
              <Button
                fontSize="sm"
                bg={tableData === 'unmonitoring' ? 'blackAlpha.100' : 'transparent'}
                colorScheme={tableData === 'unmonitoring' ? 'blue' : 'gray'}
                onClick={() => {
                  setTableData('unmonitoring')
                }}
              >
                Não rastreados {freightsNotMonitoringData?.total}
              </Button>
              <Button
                fontSize="sm"
                bg={tableData === 'aerials' ? 'blackAlpha.100' : 'transparent'}
                colorScheme={tableData === 'aerials' ? 'blue' : 'gray'}
                onClick={() => {
                  setTableData('aerials')
                }}
              >
                Aéreos {freightsAerialsData?.total}
              </Button>
            </ButtonGroup>
          )}
        />
      </Flex>
      {freightIdToEdit && isOpen && (
        <EditOrDuplicateFreightModal
          onClose={() => {
            onClose()
            setFreightIdToEdit('')
          }}
          isOpen={isOpen}
          type="edit"
          freight_id={freightIdToEdit}
        />
      )}
      {freightToShow && (
        <ShowFreightInfoModal
          isOpen={!!freightToShow}
          onClose={() => setFreightToShow(undefined)}
          freight={freightToShow}
        />
      )}
    </Flex>
  )
}

export default FreightsInProgress
