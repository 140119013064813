import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../api'

type ResponseApi = {
  data: number
}

export async function fetchDailyClosureExpectation(): Promise<number> {
  const { data } = await apiServer.get<ResponseApi>(`/daily-closure-expectation`, {
    params: {
      date: new Date(),
    },
  })

  return data.data
}

export function useDailyClosureExpectation(): UseQueryResult<number> {
  return useQuery(['get_daily_closure_expectation'], () => fetchDailyClosureExpectation())
}
