/* eslint-disable consistent-return */
import { format } from 'date-fns'
import { apiServer } from '../../../services/api'
import { ResponseApiType } from '../../../services/types'
import BlackListType from '../../../services/types/BlackListType'

export const handleLicensePlateIsBlackList = async (
  licensePlate: string,
): Promise<BlackListType | undefined> => {
  if (licensePlate) {
    try {
      const { data } = await apiServer.get<ResponseApiType<BlackListType>>('/find-black-list', {
        params: { license_plate: licensePlate },
      })
      const formattedData = {
        ...data.data,
        created_at: format(new Date(data.data.created_at), 'dd/MM/yyy'),
      } as BlackListType
      return formattedData
    } catch {
      return undefined
    }
  }
}
