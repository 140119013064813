/* eslint-disable no-shadow */
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image as ChakraImage,
  Input as ChakraInput,
  Input,
  List,
  ListIcon,
  ListItem,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { RiCheckboxCircleLine, RiCheckLine, RiUploadCloudLine } from 'react-icons/ri'
import driverLicensePlaceholderImg from '../../../../assets/driver-license-placeholder.png'
import { toastError } from '../../../../config/error/toastError'
import { usePendenciesOCRContext } from '../../../../contexts/PendenciesOCRContext'
import { usePostDriversLicenseInformations } from '../../../../services/endpoints/documents/ocr/get-drivers-license-informations'
import { convertPdfToImage } from '../../../../services/endpoints/documents/pdf/post-convert-pdf-to-image'
import { useUpdateAttachmentToMotorist } from '../../../../services/endpoints/motorists/motorist-attachments/updateAttachment'
import { MotoristType } from '../../../../services/types'
import { parseBase64ToImage } from '../../../../services/utils/parseImageToBase64'
import { isValidateWidthImage } from '../../../../services/utils/validateWidthImage'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  setDriverLicenseFile,
  setDriverLicenseImg,
  setIsNewImage,
} from '../../../../store/slices/ocrCnhSlice'

interface DriverLicenseUploadFormProps {
  motorist?: MotoristType
  refetch: () => void
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
}

export function DriverLicenseUploadForm({
  motorist,
  refetch,
  setTabIndex,
}: DriverLicenseUploadFormProps): JSX.Element {
  const updateAttachment = useUpdateAttachmentToMotorist({ onSuccess: () => refetch() })
  const dispatch = useAppDispatch()
  const { driverLicenseImg, driverLicenseFile, isNewImage } = useAppSelector(state => state.ocrCnhSlice)
  const toast = useToast()
  const { setResultOCR } = usePendenciesOCRContext()
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)

  const {
    mutate: postDriverLicenseInformations,
    data: driverLicenseInformations,
    isLoading: isDriverLicenseInformationsLoading,
  } = usePostDriversLicenseInformations({
    onSuccess: data => {
      setResultOCR(data)
      setTabIndex(1)

      const MessageNonValidFields: JSX.Element = (
        <>
          {data?.nonValidFields?.map(field => (
            <li>{field}</li>
          ))}
        </>
      )
      toast({
        title: data?.nonValidFields
          ? 'Campos que podem não estar preenchidos corretamente:'
          : 'Documento processado com sucesso!',
        description: data?.nonValidFields ? MessageNonValidFields : 'Documento processado com sucesso!',
        status: 'warning',
        duration: 15000,
        isClosable: true,
        position: 'top',
      })
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const imgEncoded = useMemo(() => {
    const splits = driverLicenseImg.split(',')
    return splits[1]
  }, [driverLicenseImg])

  const handleSubmit = async () => {
    try {
      postDriverLicenseInformations(driverLicenseImg)
    } catch (errorSubmit) {
      toast({
        title: 'Erro ao enviar informações',
        description: errorSubmit.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
    }
  }

  useEffect(() => {
    async function getAttachmentMotorist() {
      if (motorist) {
        if (motorist.attachments && motorist.attachments.length > 0) {
          const attach = motorist.attachments.find(item => item.type === 'cnh')

          if (isNewImage && attach) {
            dispatch(setIsNewImage(false))
            const { status } = await updateAttachment.mutateAsync({
              id: attach.id,
              attachment_file: driverLicenseFile,
            })
            if (status === 200) {
              toast({
                status: 'success',
                title: 'CNH atualizada e anexada ao motorista com sucesso!',
                position: 'top-right',
                duration: 1000 * 8,
                isClosable: true,
              })
            }
          }

          if (attach && attach.attachment_file_url && !isNewImage) {
            dispatch(setDriverLicenseImg(attach.attachment_file_url))
          }
        }
      } else if (driverLicenseImg.includes('placeholder') || driverLicenseImg === '') {
        dispatch(setDriverLicenseImg(driverLicensePlaceholderImg))
      }
    }
    getAttachmentMotorist()
  }, [dispatch, driverLicenseFile, driverLicenseImg, isNewImage, motorist, toast, updateAttachment])

  return (
    <>
      <Flex justify="space-between" p="4">
        <Flex direction="column" mr="12" w="50%">
          <Flex justifyContent="center" alignItems="center" direction="column">
            <Heading size="sm">CNH do motorista</Heading>
            <ChakraImage src={driverLicenseImg} w="100%" />
          </Flex>
          <FormLabel htmlFor="img-preview">
            {updateAttachment.isLoading ? (
              <Center mt="4">
                Atualizando CNH...
                <Spinner />
              </Center>
            ) : (
              <Flex mt="4" cursor="pointer" bg="orange.500" py="2" px="4" borderRadius="8" color="white">
                <Icon as={RiUploadCloudLine} fontSize={24} mr="2" />
                Carregar arquivo
              </Flex>
            )}

            <ChakraInput
              id="img-preview"
              name="img-preview"
              type="file"
              d="none"
              onChange={async e => {
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0]

                  let isValidImage
                  let imageConvertedToFile
                  if (file.name.includes('.pdf')) {
                    setIsLoadingPdf(true)
                    const resultConvertPdf = await convertPdfToImage({ file, slicePdf: true })
                    setIsLoadingPdf(false)

                    if (resultConvertPdf) {
                      imageConvertedToFile = await parseBase64ToImage(resultConvertPdf.imageConverted)
                      dispatch(setDriverLicenseImg(resultConvertPdf.imageConverted))
                      dispatch(setDriverLicenseFile(imageConvertedToFile))

                      if (!driverLicenseImg.includes('placeholder')) {
                        dispatch(setIsNewImage(true))
                      }

                      toast({
                        title: 'Imagem carregada com sucesso!',
                        status: 'success',
                        position: 'top-right',
                        isClosable: true,
                      })
                    }
                  } else {
                    isValidImage = await isValidateWidthImage(file)
                    if (!isValidImage) {
                      toast({
                        title: 'Imagem inválida',
                        description:
                          'Imagem pequena, tente carregar uma imagem com pelo menos 800px de largura',
                        status: 'error',
                        duration: 8000,
                        isClosable: true,
                        position: 'top',
                      })
                      return
                    }

                    dispatch(setDriverLicenseFile(file))
                    const fileReader = new FileReader()
                    if (
                      e.target.files &&
                      e.target.files.length > 0 &&
                      (e.target.files[0].name.includes('.png') ||
                        e.target.files[0].name.includes('.jpg') ||
                        e.target.files[0].name.includes('.jpeg') ||
                        e.target.files[0].name.includes('.pdf'))
                    ) {
                      fileReader.onload = async ev => {
                        if (ev.target && ev.target.result) {
                          const imgData = ev.target.result.toString()
                          if (!driverLicenseImg.includes('placeholder')) {
                            dispatch(setIsNewImage(true))
                          }
                          dispatch(setDriverLicenseImg(imgData))
                        }
                      }
                      fileReader.readAsDataURL(file)

                      toast({
                        title: 'Imagem carregada com sucesso!',
                        status: 'success',
                        position: 'top-right',
                        isClosable: true,
                      })
                    } else {
                      toast({
                        title: 'Formato inválido! Envie um arquivo em PNG ou JPEG!',
                        status: 'error',
                        position: 'top-right',
                        isClosable: true,
                      })
                    }
                  }
                }
              }}
            />
          </FormLabel>
          <Input name="professional_motorist_img" defaultValue={imgEncoded} d="none" />
        </Flex>
        <Box>
          <Heading size="md">PARA A CATEGORIA CARRETEIRO, O ENVIO DA CNH É OBRIGATÓRIO.</Heading>
          <Heading size="md" fontWeight="normal" mt="2">
            Confira abaixo os cuidados para o envio correto do documento.
          </Heading>

          <List spacing={3} mt="6" p="4" border="1px solid #ccc" borderRadius="8">
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />
              Digitalize o documento em alta resolução ou faça uma foto com alta qualidade.
            </ListItem>
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />A imagem enviada pode ter
              apenas a frente, apenas o verso ou os dois lados do documento de identificação.{' '}
            </ListItem>

            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />
              Utilize imagens no formato PNG ou JPEG.
            </ListItem>
          </List>

          {isDriverLicenseInformationsLoading ||
            (isLoadingPdf && (
              <Alert status="info" my="4" borderRadius="4">
                <AlertIcon />
                <Box>
                  <AlertTitle mr={2}>
                    <Flex direction={['column', 'row']} gridGap="2">
                      Processando documento. Aguarde o processo.
                      <Spinner size="md" />
                    </Flex>
                  </AlertTitle>
                </Box>
              </Alert>
            ))}
        </Box>
      </Flex>

      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button
          name="sendOcrDriverLicense"
          rightIcon={<Icon as={RiCheckLine} />}
          colorScheme="green"
          isLoading={isDriverLicenseInformationsLoading}
          isDisabled={driverLicenseImg === driverLicensePlaceholderImg}
          onClick={() => {
            handleSubmit()
          }}
        >
          {driverLicenseInformations ? 'Enviar nova imagem' : 'Enviar documento'}
        </Button>
      </HStack>
    </>
  )
}
