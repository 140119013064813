import { Box } from '@chakra-ui/react'
import React from 'react'
import OwnerType from '../../../../services/types/OwnerType'
import { useAppSelector } from '../../../../store'
import { convertMotoristToOwner } from '../utils'
import { UISelect } from './UI/UISelect'

type ChooseOwnerProps = {
  onOwnerFound: (data: OwnerType) => void
  type?: 'owner' | 'antt' | 'ownerTrailer'
}

const ChooseOwner = ({ onOwnerFound, type = 'antt' }: ChooseOwnerProps): JSX.Element => {
  const { vehicle, motorist, trailers } = useAppSelector(state => state.freightPendenciesSlice)

  const trailer = trailers?.[0]

  return (
    <Box>
      <UISelect
        size="sm"
        label="Quem é proprietário? (Opcional)"
        defaultValue="none"
        onChange={e => {
          const { value } = e.target
          if (value === 'none') {
            if (vehicle?.antt_owner) {
              if (type === 'ownerTrailer') {
                if (trailer?.owner) onOwnerFound(trailer.owner)
              } else if (type === 'owner') {
                if (vehicle.owner) onOwnerFound(vehicle.owner)
              } else {
                onOwnerFound(vehicle.antt_owner)
              }
            } else {
              onOwnerFound({} as OwnerType)
            }
          }
          if (value === 'motorist') {
            if (motorist) {
              onOwnerFound(convertMotoristToOwner(motorist))
            }
          }
          if (value === 'vehicleOwner') {
            if (vehicle?.owner) {
              onOwnerFound(vehicle?.owner)
            }
          }
          if (value === 'trailerOwner') {
            if (trailer?.owner) {
              onOwnerFound(trailer?.owner)
            }
          }
        }}
        options={[
          {
            label: 'motorista',
            value: 'motorist',
            disabled: !motorist,
          },
          ...(type === 'ownerTrailer'
            ? [
                {
                  label: 'Proprietário do veículo',
                  value: 'vehicleOwner',
                  disabled: !vehicle?.owner,
                },
              ]
            : []),
          ...(type === 'antt'
            ? [
                {
                  label: 'Proprietário do veículo',
                  value: 'vehicleOwner',
                  disabled: !vehicle?.owner,
                },
                {
                  label: 'Proprietário do reboque',
                  value: 'trailerOwner',
                  disabled: !trailer?.owner,
                },
              ]
            : []),
          {
            label: 'nenhum',
            value: 'none',
          },
        ]}
      />
    </Box>
  )
}

export default ChooseOwner
