/* eslint-disable consistent-return */
import { apiServer } from '../../api'
import getOnlyNumbers from '../../utils/getOnlyNumbers'

export interface Motorist {
  id: string
  name: string
  cpf: string
  entity_type: string
}
export interface Owner {
  id: string
  name: string
  cpf: string
  entity_type: string
}

export interface FindPerson {
  motorist: Motorist
  owner: Owner
}

export async function findMotoristAndOwnerByCpf(cpf: string): Promise<FindPerson | undefined> {
  if (cpf && cpf !== 'undefined') {
    try {
      const { data: owner } = await apiServer.get(`/find-black-list`, {
        params: {
          cpf: getOnlyNumbers(cpf),
        },
      })
      return owner.data
    } catch (error) {
      return undefined
    }
  }
}
