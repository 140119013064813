import { Button, Divider, Flex, Heading, Icon, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { RiArrowLeftLine } from 'react-icons/ri'
import PisForm from '../../../../../documents/pis-consultation/Forms/PisForm'

interface PisConsultationModalProps {
  isOpen: boolean
  onClose: () => void
  motherName?: string
  cpf?: string
  birthDate?: string | Date
  name?: string
}

export function PisConsultationModal({
  isOpen,
  onClose,
  motherName,
  cpf,
  birthDate,
  name,
}: PisConsultationModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <Flex alignItems="baseline" m="6">
          <Button onClick={onClose} leftIcon={<Icon as={RiArrowLeftLine} fontSize="2xl" />}>
            Fechar
          </Button>
          <Heading size="lg" fontWeight="bold" ml="4">
            Consultar Pis
          </Heading>
        </Flex>

        <Divider my={3} />
        <Flex mx="8" my="4">
          <PisForm motherName={motherName} cpf={cpf} birthDate={birthDate} name={name} />
        </Flex>
      </ModalContent>
    </Modal>
  )
}
